import React, { useContext, useEffect } from 'react';
import { Layout, Menu } from 'antd';

//  Custom imports
import AppContext from '../../context/appContext';
import LoadPage from '../../common/loadPage';

const SideNavigation = () => {
  //  const [selectedKeys, setSelectedKeys] = useState([]);

  const { Sider } = Layout;
  const { SubMenu } = Menu;

  const [state, dispatch] = useContext(AppContext);

  useEffect(() => {
    console.log('Dispatch - ');
  }, []);

  return (
    <Sider theme="light" width="100%">
      <Menu
        style={{ width: '100%' }}
        defaultSelectedKeys={['dashboard']}
        defaultOpenKeys={['dashboard']}
        mode="inline"
        selectedKeys={state.page}
        //  onSelect={(info) => setSelectedKeys(info.selectedKeys)}
      >
        <Menu.Item
          key="dashboard"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Dashboard
        </Menu.Item>

        <SubMenu key="manageOrders" title="Manage Orders">
          <Menu.ItemGroup key="g22">
            <Menu.Item
              key="recurringOrders"
              onClick={() => LoadPage('recurringOrders', dispatch)}
            >
              Recurring Orders
            </Menu.Item>
            <Menu.Item
              key="newOrders"
              onClick={() => LoadPage('newOrders', dispatch)}
            >
              New Orders
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>

        <SubMenu key="products" title="Products">
          <Menu.ItemGroup key="g1">
            <Menu.Item
              key="allProducts"
              onClick={() => LoadPage('allProducts', dispatch)}
            >
              All Products
            </Menu.Item>
            <Menu.Item
              key="addProduct"
              onClick={() => LoadPage('addProduct', dispatch)}
            >
              Add Product
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>

        <SubMenu key="addons" title="Addons">
          <Menu.ItemGroup key="g2">
            <Menu.Item
              key="allAddons"
              onClick={() => LoadPage('allAddons', dispatch)}
            >
              All Addons
            </Menu.Item>
            <Menu.Item
              key="addAddons"
              onClick={() => LoadPage('addAddons', dispatch)}
            >
              Add Addon
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>

        <SubMenu key="customers" title="Customers">
          <Menu.ItemGroup key="g3">
            <Menu.Item
              key="allCustomers"
              onClick={() => LoadPage('allCustomers', dispatch)}
            >
              All Customers
            </Menu.Item>
            <Menu.Item
              key="addCustomer"
              onClick={() => LoadPage('addCustomer', dispatch)}
            >
              Add Customer
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>

        <SubMenu key="suppliers" title="Suppliers">
          <Menu.ItemGroup key="g4">
            <Menu.Item
              key="allSuppliers"
              onClick={() => LoadPage('allSuppliers', dispatch)}
            >
              All Suppliers
            </Menu.Item>
            <Menu.Item
              key="addSupplier"
              onClick={() => LoadPage('addSupplier', dispatch)}
            >
              Add Supplier
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>

        <SubMenu key="inventory" title="Inventory">
          <Menu.ItemGroup key="g5">
            <Menu.Item
              key="allInventory"
              onClick={() => LoadPage('allInventory', dispatch)}
            >
              All Inventory
            </Menu.Item>
            <Menu.Item
              key="addStock"
              onClick={() => LoadPage('addStock', dispatch)}
            >
              Add Stock Items
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>

        <SubMenu key="billing" title="Billing">
          <Menu.ItemGroup key="g6">
            <Menu.Item
              key="allInvoices"
              onClick={() => LoadPage('allInvoices', dispatch)}
            >
              All Invoices
            </Menu.Item>
            <Menu.Item
              key="addInvoice"
              onClick={() => LoadPage('addInvoice', dispatch)}
            >
              Add Invoice
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
        <SubMenu key="discounts" title="Discounts">
          <Menu.ItemGroup key="g22">
            <Menu.Item
              key="allDiscounts"
              onClick={() => LoadPage('allDiscounts', dispatch)}
            >
              All Discounts
            </Menu.Item>
            <Menu.Item
              key="addDiscount"
              onClick={() => LoadPage('addDiscount', dispatch)}
            >
              Add Discount
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>

        <SubMenu key="masterData" title="Master Data">
          {/* <SubMenu key="currencies" title="Currencies">
            <Menu.ItemGroup key="g72">
              <Menu.Item
                key="allCurrencies"
                onClick={() => LoadPage('allCurrencies', dispatch)}
              >
                All Currencies
              </Menu.Item>
              <Menu.Item
                key="addCurrency"
                onClick={() => LoadPage('addCurrency', dispatch)}
              >
                Add Currency
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu> */}

          <SubMenu key="locations" title="Locations">
            <Menu.ItemGroup key="g71">
              <Menu.Item
                key="allLocations"
                onClick={() => LoadPage('allLocations', dispatch, dispatch)}
              >
                All Locations
              </Menu.Item>
              <Menu.Item
                key="addLocation"
                onClick={() => LoadPage('addLocation', dispatch, dispatch)}
              >
                Add Location
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>

          <SubMenu key="ramTypes" title="RAM Types">
            <Menu.ItemGroup key="g73">
              <Menu.Item
                key="allRam"
                onClick={() => LoadPage('allRams', dispatch)}
              >
                All RAM Types
              </Menu.Item>
              <Menu.Item
                key="addRam"
                onClick={() => LoadPage('addRam', dispatch)}
              >
                Add RAM Types
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>

          <SubMenu key="processors" title="Processors">
            <Menu.ItemGroup key="g74">
              <Menu.Item
                key="allProcessors"
                onClick={() => LoadPage('allProcessors', dispatch)}
              >
                All Processors
              </Menu.Item>
              <Menu.Item
                key="addProcessor"
                onClick={() => LoadPage('addProcessor', dispatch)}
              >
                Add Processor
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>

          <SubMenu key="lanCards" title="LAN Cards">
            <Menu.ItemGroup key="g75">
              <Menu.Item
                key="allLanCards"
                onClick={() => LoadPage('allLanCards', dispatch)}
              >
                All LAN Cards
              </Menu.Item>
              <Menu.Item
                key="addLanCard"
                onClick={() => LoadPage('addLanCard', dispatch)}
              >
                Add LAN Card
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>

          <SubMenu key="hdds" title="HDD Disks">
            <Menu.ItemGroup key="g76">
              <Menu.Item
                key="allHdd"
                onClick={() => LoadPage('allDisks', dispatch)}
              >
                All Disks
              </Menu.Item>
              <Menu.Item
                key="addDisk"
                onClick={() => LoadPage('addDisk', dispatch)}
              >
                Add Disk
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>

          <SubMenu key="raidCards" title="RAID Cards">
            <Menu.ItemGroup key="g77">
              <Menu.Item
                key="allRaidCards"
                onClick={() => LoadPage('allRaidCards', dispatch)}
              >
                All RAID Cards
              </Menu.Item>
              <Menu.Item
                key="addRaidCard"
                onClick={() => LoadPage('addRaidCard', dispatch)}
              >
                Add RAID Card
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
          <SubMenu key="gpu" title="GPU">
            <Menu.ItemGroup key="g8">
              <Menu.Item
                key="allGpu"
                onClick={() => LoadPage('allGpu', dispatch)}
              >
                All GPUs
              </Menu.Item>
              <Menu.Item
                key="addGpu"
                onClick={() => LoadPage('addGpu', dispatch)}
              >
                Add GPU
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
          <SubMenu key="ipAddress" title="IP Address">
            <Menu.ItemGroup key="g9">
              <Menu.Item
                key="allIpAddresses"
                onClick={() => LoadPage('allIpAddresses', dispatch)}
              >
                All IP Addresses
              </Menu.Item>
              <Menu.Item
                key="addIpAddress"
                onClick={() => LoadPage('addIpAddress', dispatch)}
              >
                Add IP Address
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
        </SubMenu>

        <SubMenu key="users" title="Users">
          <Menu.ItemGroup key="g9">
            <Menu.Item
              key="allUsers"
              onClick={() => LoadPage('allUsers', dispatch)}
            >
              All Users
            </Menu.Item>
            <Menu.Item
              key="addUser"
              onClick={() => LoadPage('addUser', dispatch)}
            >
              Add User
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
        <Menu.Item
          key="currencyExchangeRates"
          onClick={() => LoadPage('currencyExchangeRates', dispatch)}
        >
          Currency Exchange Rates
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

/* SideNavigation.defaultProps = {
  LoadPage: 'dashboard', dispatch,
};

SideNavigation.propTypes = {
  LoadPage: PropTypes., dispatchfunc,
};
 */
export default SideNavigation;
