import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Breadcrumb,
  Table,
  notification,
  Modal,
  Form,
  Input,
  Select,

} from 'antd';

import { IoAdd, IoPencil, IoTrash } from 'react-icons/io5';
import AppContext from '../../../context/appContext';
import { BREADCRUMB } from '../../../context/types';
import LoadPage from '../../../common/loadPage';
import { DELETE, GET, PUT } from '../../../common/crud';

const AllDisks = () => {
  const [state, dispatch] = useContext(AppContext);
  const [data, setData] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateData, setUpdateData] = useState([]);
  const [deleteData, setDeleteData] = useState([]);
  const [diskType, setDiskType] = useState('');

  const [UpdateForm] = Form.useForm();

  const { Option } = Select;

  const breadcrumb = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>All Disks</Breadcrumb.Item>
    </Breadcrumb>
  );

  //  Update HDD
  const updateRowData = (record) => {
    console.log('Update record - ', record);
    setUpdateModal(true);
    setUpdateData(record);
    setDiskType(record.disk_type);
    UpdateForm.setFieldsValue(record);
  };

  //  Delete HDD
  const deleteRowData = (record) => {
    console.log('Delete record - ', record);
    setDeleteModal(true);
    setDeleteData(record);
  };

  const tableColumns = [
    {
      title: 'Make',
      dataIndex: 'make',
      key: 'make',
      width: 300,
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
      width: 300,
    },
    {
      title: 'Protocol',
      dataIndex: 'protocol',
      key: 'protocol',
      width: 300,
    },
    {
      title: 'Capacity',
      dataIndex: 'capacity',
      key: 'capacity',
      width: 300,
    },
    {
      title: 'Form Factor',
      dataIndex: 'form_factor',
      key: 'form_factor',
      width: 300,
    },
    {
      title: 'Type',
      dataIndex: 'disk_type',
      key: 'disk_type',
      width: 300,
    },
    {
      title: 'Actions',
      width: 300,
      render: (_, record) => (
        <>
          <Button
            type="default"
            className="me-1 btn-outline-secondary"
            onClick={() => updateRowData(record)}
            icon={<IoPencil />}
          />
          <Button
            type="default"
            className="me-1 btn-outline-danger"
            onClick={() => deleteRowData(record)}
            icon={<IoTrash />}
          />
        </>
      ),
    },
  ];

  const fetchData = () => {
    GET('masterdata/disks', state.token).then((res) => {
      if (res.success) {
        setData(res.data.disks);
      } else {
        notification.error({
          message: 'Something went wrong!',
          description: `Got Error with status code ${res.status}`,
        });
      }
    });
  };

  //  Update Submit
  const submitForm = (values) => {
    console.log('Submit Form values - ', values);
    setUpdateModal(false);
    PUT('masterdata/disks', updateData._id, state.token, values).then((res) => {
      if (res.success === true) {
        UpdateForm.resetFields();
        setUpdateData(null);
        notification.success({
          message: 'Successfully Updated!',
          description: 'Data is Successfully Updated!',
        });
        fetchData();
      } else {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  //  Delete Cancel Modal
  const deleteModalCancel = () => {
    setDeleteData(null);
  };

  //  Update Cancel Modal
  const updateCancelModal = () => {
    UpdateForm.resetFields();
    setUpdateData(null);
    setUpdateModal(false);
  };

  //  Delete on Succes
  const deleteSuccess = () => {
    setDeleteModal(false);
    setUpdateModal(false);
    UpdateForm.resetFields();
    DELETE('masterdata/disks', deleteData._id, state.token).then((res) => {
      if (res.success === true) {
        setUpdateData({});
        setDeleteData(null);
        notification.success({
          message: 'Deleted Successfully!',
          description: 'Data is Successfully Deleted!',
        });
        fetchData();
      } else {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  useEffect(() => {
    fetchData();

    console.log('Update data - ', updateData);

    //  Breadcrumb
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, [dispatch]);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              All Disks
            </Col>
            <Col span="10" flex="row" align="end">
              <Button
                type="primary"
                size="large"
                onClick={() => LoadPage('addDisk', dispatch)}
              >
                <IoAdd />
                {' '}
                Add Disk
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter="20" className="m-2">
        <Col span="24">
          <Table
            pagination={false}
            columns={tableColumns}
            dataSource={data}
          />
        </Col>
      </Row>
      <Modal
        width={1100}
        footer={false}
        title="Update Disks"
        visible={updateModal}
        onCancel={updateCancelModal}
      >
        <Form
          layout="vertical"
          form={UpdateForm}
          onFinish={submitForm}
        >
          <Row gutter="30" className="flex-direction-column">
            <Col span="12">
              <Form.Item
                label="Disk Make"
                name="make"
                rules={[
                  { required: true, message: 'Enter disk make' },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select disk make"
                  optionLabelProp="children"
                >
                  <Option value="Kingston">Kingston</Option>
                  <Option value="Micron">Micron</Option>
                  <Option value="Samsung">Samsung</Option>
                  <Option value="Seagate">Seagate</Option>
                  <Option value="Toshiba">Toshiba</Option>
                  <Option value="WD">WD</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Disk Model"
                name="model"
                rules={[
                  { required: true, message: 'Enter disk model' },
                ]}
              >
                <Input placeholder="Enter disk model" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Disk Protocol"
                name="protocol"
                rules={[
                  { required: true, message: 'Enter disk protocol' },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select disk protocol"
                  optionLabelProp="children"
                >
                  <Option value="SATA">SATA</Option>
                  <Option value="SAS">SAS</Option>
                  <Option value="NVMe">NVMe</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Disk Capacity"
                name="capacity"
                rules={[
                  { required: true, message: 'Enter disk capacity' },
                ]}
              >
                <Input placeholder="Enter disk capacity in GB" addonAfter="GB" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Disk Form Factor"
                name="form_factor"
                rules={[
                  { required: true, message: 'Enter disk form factor' },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select disk form factor"
                  optionLabelProp="children"
                >
                  <Option value="2.5">2.5</Option>
                  <Option value="3.5">3.5</Option>
                  <Option value="M.2">M.2</Option>
                  <Option value="U.2">U.2</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span="12" />
            <Col span="12">
              <Form.Item
                label="Disk Type"
                name="disk_type"
                rules={[
                  { required: true, message: 'Enter disk type' },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select disk type"
                  optionLabelProp="children"
                  onChange={(value) => setDiskType(value)}
                >
                  <Option value="SSD">SSD</Option>
                  <Option value="HDD">HDD</Option>
                </Select>
              </Form.Item>
            </Col>
            {diskType === 'SSD' && (
            <Col span="12">
              <Form.Item
                label="Drive Writes Per Day"
                name="dwpd"
                rules={[
                  { required: true, message: 'Enter disk dwpd' },
                ]}
              >
                <Input placeholder="Enter DWPD" />
              </Form.Item>
            </Col>
            )}
            {diskType === 'HDD' && (
            <Col span="12">
              <Form.Item
                label="Disk Speed"
                name="disk_speed"
                rules={[
                  { required: true, message: 'Enter disk speed' },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select disk speed"
                  optionLabelProp="children"
                >
                  <Option value="7200 RPM">7200 RPM</Option>
                  <Option value="15000 RPM">15000 RPM</Option>
                </Select>
              </Form.Item>
            </Col>
            )}
            <Col span="24" className="my-1 py-2 border-top">
              <Button type="button" size="large" className="me-1 px-2" onClick={() => updateCancelModal()}>
                Cancel
              </Button>
              <Button
                type="primary"
                size="large"
                className="me-1 btn-success px-4"
                htmlType="submit"
              >
                Update Disk
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Confirm if you really want to delete?"
        visible={deleteModal}
        onCancel={deleteModalCancel}
        okText="Delete"
        okButtonProps={{ className: 'btn-danger' }}
        onOk={deleteSuccess}
      >
        <Row>
          <Col span="24">
            <p>
              <span style={{ fontSize: '20px', fontWeight: 500, color: 'var(--bs-danger)' }}>
                {deleteData ? `${deleteData.make} / ${deleteData.model} / ${deleteData.capacity} / ${deleteData.form_factor}` : ''}
              </span>
            </p>
            <p>
              Do you really want to
              delete above RAM?
            </p>
            <p />
          </Col>
        </Row>
      </Modal>

    </>
  );
};

export default AllDisks;
