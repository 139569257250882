import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Breadcrumb,
  Table,
  Button,
  Tabs,
  Tooltip,
  Select,
  Typography,
  Tag,
  Form,
  Input,
  //  Modal,
  /* Modal,
  notification, */
} from 'antd';
import { IoCheckmarkCircle } from 'react-icons/io5';
/* import { FaRupeeSign } from 'react-icons/fa'; */
import moment from 'moment';

// Custom imports
import './customers.scss';
import AppContext from '../../context/appContext';
import { BREADCRUMB } from '../../context/types';
import LoadPage from '../../common/loadPage';
import { GET, POST, PUT } from '../../common/crud';

const CustomerDetail = () => {
  const [tabActive, setTabActive] = useState('orders');
  const [customerDetails, setCustomerDetails] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [customerOrders, setCustomerOrders] = useState(null);
  const [gstVerifiedStatus, setGstVerifiedStatus] = useState(false);
  //  const [gstVerifyModal, setGstVerifyModal] = useState(false);
  //  const [gstData, setGstData] = useState(null);

  const [state, dispatch] = useContext(AppContext);

  const { Option } = Select;
  const { TabPane } = Tabs;
  const { Title } = Typography;

  const [gstVerification] = Form.useForm();

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('allCustomers', dispatch)}
        >
          All Customers
        </Button>
      </Breadcrumb.Item>

      <Breadcrumb.Item>Customer Detail</Breadcrumb.Item>
    </Breadcrumb>
  );

  //  Order Detail
  const goToOrderDetail = (record) => {
    console.log('Order detail value - ', record);
  };

  //  Order Status Text Display
  const porderStatusTitle = (value) => {
    switch (value) {
      case 'idle':
        return (
          <Tag color="orange">Idle</Tag>
        );
      case 'cart_abandoned':
        return (
          <Tag color="red">Cart Abandoned</Tag>
        );

      case 'received':
        return (
          <Tag color="green">Received</Tag>
        );

      default:
        return (
          <Tag color="grey">{value}</Tag>
        );
    }
  };

  //  Payment Status Text Display
  const paymentStatusTitle = (value) => {
    switch (value) {
      case 'partial':
        return (
          <Tag color="orange">Partial</Tag>
        );
      case 'pending':
        return (
          <Tag color="red">Pending</Tag>
        );

      case 'paid':
        return (
          <Tag color="green">Paid</Tag>
        );

      default:
        return (
          <Tag color="grey">{value}</Tag>
        );
    }
  };

  // All Orders Table Col
  const AllOrdersCol = [
    {
      title: 'Date',
      dataIndex: 'order_date',
      key: 'order_date',
      width: '150px',
      render: (_, record) => (
        moment(record.order_date).utc().format('DD-MM-YYYY')
      ),
    },
    {
      title: 'Orders#',
      dataIndex: 'orders',
      key: 'orders',
      render: (_, record) => (
        <Tooltip title={record._id}>
          <Button type="link" className="p-0" onClick={() => goToOrderDetail(record)}>
            {record._id.substring(record._id.length - 6)}
          </Button>
        </Tooltip>
      ),
    },
    {
      title: 'Last Invoice',
      dataIndex: 'last_invoice',
      key: 'last_invoice',
      width: '120px',
      render: (_, record) => (
        moment(record.order_date).utc().format('DD-MM-YYYY')
      ),
    },

    {
      title: 'Amount',
      dataIndex: 'cart_price',
      key: 'cart_price',
      className: 'text-end',
      width: '150px',
    },
    {
      title: 'Order Status',
      dataIndex: 'order_status',
      key: 'order_status',
      width: '150px',
      render: (_, record) => (
        porderStatusTitle(record.order_status)
      ),
    },

    {
      title: 'Payment Status',
      dataIndex: 'payment_status',
      key: 'payment_status',
      width: '150px',
      render: (_, record) => (
        paymentStatusTitle(record.payment_status)
      ),
    },
  ];

  //  Customer GST Treamtnet
  /* const customerGST = (value) => {
    if (value === 'Unregistered') {
      return (
        <>
          <IoAlertCircle color="orange" size="18" />
          {' '}
          Unregistered
        </>
      );
    }
    return (
      <IoCheckmarkCircle /> + { value }
    );
  }; */

  //  Tabs on change
  const tabOnChange = (value) => {
    setTabActive(value);
  };

  //  Get customer detials
  const getCustomerDetails = (id) => {
    GET(`customers/${id}`, state.token).then((res) => {
      console.log('Customer detail res - ', res);
      if (res.success === true) {
        setCustomerDetails(res.data);
        setGstVerifiedStatus(res.data.gst_verified);
        if (!res.data.gst_verified && res.data.gstin) {
          gstVerification.setFieldsValue({
            gstNumber: res.data.gstin,
          });
        }
      }
    });
  };

  //  Get customer Orders
  const getCustomerOrders = (id) => {
    GET(`orders?cust_id=${id}`, state.token).then((res) => {
      console.log('Customer orders res - ', res);
      if (res.success === true) {
        setCustomerOrders(res.data);
      }
    });
  };

  //  GST Verification
  const verifyGstForm = (values) => {
    console.log('Gst form values - ', values);
    POST(`gstin/${values.gstNumber}`, state.token, {}).then((res) => {
      console.log('GST Verification res - ', res);
      if (res.success === true) {
        const updateCustomerBody = {
          ...customerDetails,
          gstin: values.gstNumber,
          gstin_verified: true,
        };
        PUT('customers', customerDetails._id, state.token, updateCustomerBody).then((updateRes) => {
          if (updateRes.success === true) {
            getCustomerDetails();
          }
        });
      }
      //  setGstVerifiedStatus(true);
    });
  };

  //  Gst Output
  const gstOutput = () => {
    if (customerDetails && !customerDetails.gst_verified) {
      return (
        <Form
          layout="vertical"
          form={gstVerification}
          onFinish={verifyGstForm}
          style={{ width: '100%' }}
        >

          <Form.Item name="gstNumber" label="" rules={[{ required: true, message: 'Please enter GSTIN number' }]} className="mb-0">
            <Row style={{ display: 'flex', flexDirection: 'row' }}>
              <Col span="16">
                <Input style={{ width: '100%' }} defaultValue="" placeholder="Enter GSTIN number here" />
              </Col>
              <Col span="8">
                <Button type="primary" className="w-100" htmlType="submit">Verify</Button>
              </Col>
            </Row>
          </Form.Item>

        </Form>
      );
      /*  switch (gstVerifiedStatus) {
        case true:
          return (
            customerDetails && customerDetails.gst_number ? customerDetails.gst_number : 'N/A'
          );

        case false:
          return (
            <Form
              layout="vertical"
              form={gstVerification}
              onFinish={verifyGstForm}
              style={{ width: '100%' }}
            >

              <Form.Item
                name="gstNumber"
                label=""
                rules={[{ required: true, message: 'Please enter GSTIN number' }]} className="mb-0">
                <Row style={{ display: 'flex', flexDirection: 'row' }}>
                  <Col span="16">
                    <Input
                      style={{ width: '100%' }}
                      defaultValue=""
                      placeholder="Enter GSTIN number here" />
                  </Col>
                  <Col span="8">
                    <Button type="primary" className="w-100" htmlType="submit">Verify</Button>
                  </Col>
                </Row>
              </Form.Item>

            </Form>
          );

        default:
          return ('N/A');
      } */
    }
    return (
      customerDetails && customerDetails.gst_number ? customerDetails.gst_number : 'N/A'
    );
  };

  useEffect(() => {
    const gotCustomerId = sessionStorage.getItem('customerId');
    console.log('customerId - ', customerId);

    console.log('Customer details - ', gotCustomerId);

    if (gotCustomerId && gotCustomerId !== 'undefined' && gotCustomerId !== null && gotCustomerId !== '') {
      setCustomerId(setCustomerId);
      getCustomerDetails(JSON.parse(gotCustomerId));
      getCustomerOrders(JSON.parse(gotCustomerId));
    }

    // Bredcrumb
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      //  Empty Session Storage customer Id
      sessionStorage.removeItem('customerId');

      //  Empty Breadcrumb
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              All Customers
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        {
          customerId || ''
        }
        <Col span="24">
          <Tabs
            defaultActiveKey={tabActive}
            activeKey={tabActive}
            onChange={tabOnChange}
          >
            <TabPane tab="All Orders" key="orders">
              <Row gutter="40">
                {customerDetails ? (
                  <>
                    <Col span="12">

                      <div className="ant-table-content">
                        <table className="border account-summary-table order-customer-detials">
                          <tbody className="ant-table-tbody">
                            <tr className="ant-table-row">
                              <td className="ant-table-cell" width="150px">First/Last Name</td>
                              <td className="ant-table-cell">{customerDetails.first_name && customerDetails.last_name ? `${customerDetails.first_name} ${customerDetails.last_name}` : 'N/A'}</td>
                            </tr>
                            <tr className="ant-table-row">
                              <td className="ant-table-cell">Display Name</td>
                              <td className="ant-table-cell">
                                {customerDetails.display_name ? `${customerDetails.display_name}` : 'N/A'}
                              </td>
                            </tr>
                            <tr className="ant-table-row">
                              <td className="ant-table-cell">Email</td>
                              <td className="ant-table-cell">
                                {customerDetails.email ? `${customerDetails.email}` : 'N/A'}
                              </td>
                            </tr>
                            <tr className="ant-table-row">
                              <td className="ant-table-cell">Phone</td>
                              <td className="ant-table-cell">
                                {' '}
                                {customerDetails.phone ? `${customerDetails.phone}` : 'N/A'}
                              </td>
                            </tr>
                            <tr className="ant-table-row">
                              <td className="ant-table-cell">Address</td>
                              <td className="ant-table-cell">
                                <address className="addressBlock">
                                  {'billing_address' in customerDetails ? `${customerDetails.billing_address.line1}, ${customerDetails.billing_address.line2}, ${customerDetails.billing_address.city}, ${customerDetails.billing_address.state}, ${customerDetails.billing_address.country} - ${customerDetails.billing_address.postal_code} ` : 'N/A'}
                                </address>
                              </td>
                            </tr>
                            <tr className="ant-table-row">
                              <td className="ant-table-cell">GST#</td>
                              <td className="ant-table-cell">
                                {
                                  customerDetails && gstVerifiedStatus ? (
                                    <>
                                      <IoCheckmarkCircle className="text-success" />
                                      {customerDetails.gstin}
                                    </>
                                  ) : gstOutput()
                                }
                              </td>
                            </tr>
                            <tr className="ant-table-row">
                              <td className="ant-table-cell">KYC</td>
                              <td className="ant-table-cell">
                                {/* <Tooltip title="Rejected">
                                <IoAlertCircle
                                  color="var(--bs-danger)"
                                  size="18"
                                />
                              </Tooltip>
                              {' '}
                              Rejected */}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                    </Col>
                    <Col span="12">
                      <table
                        className="account-summary-table"
                        style={{ float: 'right' }}
                      >
                        <tr className="balanceRow">
                          <th colSpan="2" style={{ fontWeight: '500' }}>
                            Account Summary
                          </th>
                        </tr>
                        <tr>
                          <th width="150px">Opening Balance</th>
                          <td className="text-end">
                            {
                              customerDetails.currency_symbol
                                ? customerDetails.currency_symbol
                                : customerDetails.currency
                            }
                            {
                              customerDetails.opening_balance
                                ? customerDetails.opening_balance
                                : 'N/A'
                            }
                          </td>
                        </tr>
                        <tr>
                          <th width="200px">Invoiced Amount</th>
                          <td className="text-end">
                            N/A
                          </td>
                        </tr>
                        <tr>
                          <th width="200px">Amount Received</th>
                          <td className="text-end">
                            N/A
                          </td>
                        </tr>
                        <tr className="balanceRow">
                          <th>Balance Due</th>
                          <td className="text-end">
                            N/A
                          </td>
                        </tr>
                        <tr className="bg-white">
                          <td colSpan="2" className="p-2">
                            <Button
                              type="primary"
                              onClick={() => setTabActive('accountSummary')}
                              className="w-100"
                            >
                              View Details
                            </Button>
                          </td>
                        </tr>
                      </table>
                    </Col>
                  </>
                ) : ''}
              </Row>
              <Row className="mt-2">
                <Col span="24">
                  <Row
                    gutter="30"
                    className="p-1"
                    style={{ backgroundColor: '#ddd' }}
                  >
                    <Col>
                      <Select
                        placeholder="Select Location"
                        defaultValue="All Locations"
                        style={{ width: '200px' }}
                        disabled
                      >
                        <Option value="All Locations">All Locations</Option>
                        <Option value="Hyderabad,India">
                          Hyderabad, India
                        </Option>
                        <Option value="Mumbai,India">Mumbai, India</Option>
                      </Select>
                    </Col>
                    <Col>
                      <Select
                        placeholder="Select Category"
                        defaultValue="All Categories"
                        style={{ width: '200px' }}
                        disabled
                      >
                        <Option value="All Categories">All Categories</Option>
                        <Option value="BareMetal">BareMetal</Option>
                        <Option value="Cloud">Cloud</Option>
                        <Option value="ObjectStorage">ObjectStorage</Option>
                        <Option value="Firewall">Firewall</Option>
                      </Select>
                    </Col>
                    <Col>
                      <Select
                        placeholder="Select Status"
                        defaultValue="All"
                        style={{ width: '200px' }}
                        disabled
                      >
                        <Option value="paid">paid</Option>
                        <Option value="due">due</Option>
                      </Select>
                    </Col>
                  </Row>
                </Col>
                <Col span="24">
                  <Table columns={AllOrdersCol} dataSource={customerOrders} />
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Statement" key="accountSummary">
              <Row>
                <Col span="24">
                  <Row className="">
                    {customerDetails ? (
                      <Col span="12">
                        <Title level={4} className="">
                          {customerDetails.display_name ? customerDetails.display_name : ''}
                        </Title>
                        <div
                          className="full-width"
                          style={{ fontSize: '15px', lineHeight: '1.7' }}
                        >
                          <address className="addressBlock">
                            {'billing_address' in customerDetails ? `${customerDetails.billing_address.line1}, ${customerDetails.billing_address.line2}, ${customerDetails.billing_address.city}, ${customerDetails.billing_address.state}, ${customerDetails.billing_address.country} - ${customerDetails.billing_address.postal_code} ` : 'N/A'}
                          </address>
                        </div>
                      </Col>
                    ) : ''}

                    <Col span="12" className="text-end">
                      <div className="full-width">
                        {
                          customerDetails ? (

                            <table
                              className="account-summary-table"
                              style={{ float: 'right' }}
                            >
                              <tr className="balanceRow">
                                <th colSpan="2" style={{ fontWeight: '500' }}>
                                  Account Summary
                                </th>
                              </tr>
                              <tr>
                                <th width="150px">Opening Balance</th>
                                <td className="text-end">
                                  {
                              customerDetails.currency_symbol
                                ? customerDetails.currency_symbol
                                : customerDetails.currency
                            }
                                  {
                              customerDetails.opening_balance
                                ? customerDetails.opening_balance
                                : 'N/A'
                            }
                                </td>
                              </tr>
                              <tr>
                                <th width="200px">Invoiced Amount</th>
                                <td className="text-end">
                                  N/A
                                </td>
                              </tr>
                              <tr>
                                <th width="200px">Amount Received</th>
                                <td className="text-end">
                                  N/A
                                </td>
                              </tr>
                              <tr className="balanceRow">
                                <th>Balance Due</th>
                                <td className="text-end">
                                  N/A
                                </td>
                              </tr>
                            </table>
                          ) : 'N/A'
}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span="24" className="mt-2">
                  <Table
                    columns=""
                    dataSource=""
                  />
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Col>

      </Row>

    </>
  );
};

export default CustomerDetail;
