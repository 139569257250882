import React, { useContext, useEffect } from 'react';
import {
  Form,
  Select,
  Row,
  Col,
  Button,
  notification,
  Switch,
} from 'antd';

import AppContext from '../../../context/appContext';
import Breadcrumbs from '../../../common/breadcrumbs';
import { BREADCRUMB } from '../../../context/types';
import { POST } from '../../../common/crud';
import LoadPage from '../../../common/loadPage';

const AddRAIDCard = () => {
  const [state, dispatch] = useContext(AppContext);
  const [form] = Form.useForm();

  const { Option } = Select;

  const breadcrumb = <Breadcrumbs title="Add RAID Card" />;

  const initialValues = {
    make: 'Dell',
    model: '',
    battery: false,
    supported_levels: [],
  };

  useEffect(() => {
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, [dispatch]);

  const submitForm = (values) => {
    POST('masterdata/raid_cards', state.token, values).then((res) => {
      if (res.success) {
        LoadPage('allRaidCards', dispatch);
        notification.success({
          message: 'Success',
          description: 'Card added successfully',
        });
        form.resetFields();
      } else {
        notification.error({
          message: 'Something went wrong',
          description: `Got error with status code ${res.status}`,
        });
      }
    }).catch((err) => {
      console.error('Add RAID card err:', err);
    });
  };

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Add RAID Card
            </Col>
            <Col span="10" flex="row" align="end" />
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24">
          <Form
            layout="vertical"
            form={form}
            onFinish={submitForm}
            initialValues={initialValues}
          >
            <Row gutter="30" className="flex-direction-column">
              <Col span="12">
                <Form.Item
                  label="Make"
                  name="make"
                  rules={[
                    { required: true, message: 'Enter make' },
                  ]}
                >
                  <Select
                    showSearch
                    optionLabelProp="children"
                  >
                    <Option value="Dell">Dell</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Model"
                  name="model"
                  rules={[
                    { required: true, message: 'Enter model' },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select model"
                    optionLabelProp="children"
                  >
                    <Option value="H200">H200</Option>
                    <Option value="H330">H330</Option>
                    <Option value="H700">H700</Option>
                    <Option value="H710">H710</Option>
                    <Option value="H730">H730</Option>
                    <Option value="H730P">H730P</Option>
                    <Option value="H800">H800</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Battery?"
                  name="battery"
                  rules={[
                    { required: true, message: 'Enter model' },
                  ]}
                >
                  <Switch checkedChildren="Yes" unCheckedChildren="No" />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Supported Levels"
                  name="supported_levels"
                  rules={[
                    { required: true, message: 'Select levels' },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select levels"
                    optionLabelProp="children"
                    mode="tags"
                  >
                    <Option value="0">0</Option>
                    <Option value="1">1</Option>
                    <Option value="5">5</Option>
                    <Option value="6">6</Option>
                    <Option value="10">10</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span="24" className="my-1 py-2 border-top">
                <Button type="default" size="large" className="me-1 px-2">
                  Cancel
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="me-1 btn-success px-4"
                  htmlType="submit"
                >
                  Add Card
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default AddRAIDCard;
