import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Input,
  Button,
  Breadcrumb,
  Form,
  Select,
  Table,
  Modal,
  notification,
} from 'antd';

import { IoAdd, IoPencil, IoTrash } from 'react-icons/io5';

// Custom imports
import AppContext from '../../../context/appContext';
import { BREADCRUMB } from '../../../context/types';
import LoadPage from '../../../common/loadPage';
import { DELETE, GET, PUT } from '../../../common/crud';

const AllProcessors = () => {
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [data, setData] = useState([]);
  const [updateData, setUpdateData] = useState([]);

  const [state, dispatch] = useContext(AppContext);
  const { Option } = Select;

  const [UpdateForm] = Form.useForm();

  //  Update Row Data
  const updateRowData = async (id, record) => {
    /* let updateItem;
    await data.map((item) => {
      if (item.key === id) {
        updateItem = item;
      }
      console.log('Update item - ', updateItem);
      return setUpdateData(updateItem);
    });
    console.log('Update id - ', id); */
    setUpdateData(record);
    UpdateForm.setFieldsValue(record);
    setUpdateModal(true);
  };

  //  Delete Row Dta
  const deleteRow = (record) => {
    setDeleteModal(true);
    setDeleteId(record);
    console.log('Delete row Data - ', record);
  };

  // Table Columns
  const processorCols = [
    {
      title: 'Make',
      dataIndex: 'make',
      key: 'make',
      width: '100px',
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Pros. Cores',
      dataIndex: 'cores',
      key: 'cores',
    },
    {
      title: 'Base/Turbo Frequency',
      dataIndex: 'baseTuboFrequency',
      key: 'baseTuboFrequency',
    },
    {
      title: 'TDP',
      dataIndex: 'tdp',
      key: 'tdp',
      width: '120px',
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      width: 120,
      render: (id, record) => (
        <>
          <Button
            type="default"
            className="me-1 btn-outline-secondary"
            onClick={() => updateRowData(id, record)}
            icon={<IoPencil />}
          />
          <Button
            type="default"
            className="me-1 btn-outline-danger"
            onClick={() => deleteRow(record)}
            icon={<IoTrash />}
          />
        </>
      ),
    },
  ];

  //  Make onChange
  const makeOnChange = (value) => {
    console.log('Make on change value - ', value);
  };

  // Cancel Delete Modal
  const cancelModal = () => {
    setDeleteModal(false);
  };

  //  Cancel
  const handleCancel = () => {
    setUpdateModal(false);
    UpdateForm.resetFields();
  };

  const tableData = () => {
    GET('masterdata/processors', state.token).then((res) => {
      //  setLoading(false);
      console.log('Get res - ', res);
      const tableDataArray = [];
      if (res.success === true) {
        res.data.processors.map((mapData) => {
          const newData = {
            ...mapData,
            baseTuboFrequency: `${mapData.base_frequency} / ${mapData.turbo_frequency}`,
          };
          return tableDataArray.push(newData);
        });
        setData(tableDataArray);
      } else if (res.success === false) {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  };

  // Update Processor SUCCESS
  const updateProcessorSuccess = (values) => {
    console.log('Update process values - ', values);

    const updateFormBody = {
      make: values.make,
      model: values.model,
      name: values.name,
      cores: Number(values.cores),
      base_frequency: values.base_frequency,
      turbo_frequency: values.turbo_frequency,
      tdp: Number(values.tdp),
    };

    setUpdateModal(false);

    PUT('masterdata/processors', updateData._id, state.token, updateFormBody).then((res) => {
      if (res.success === true) {
        setUpdateData([]);
        setDeleteId(null);
        notification.success({
          message: 'Successfully Updated!',
          description: 'Data is Successfully Updated!',
        });
        tableData();
      } else if (res.success === false) {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  //  Delete Processors Row
  const deleteRowData = () => {
    setDeleteModal(false);
    DELETE('masterdata/processors', deleteId._id, state.token).then((res) => {
      if (res.success === true) {
        setDeleteId(null);
        setUpdateData([]);
        notification.success({
          message: 'Deleted Successfully!',
          description: 'Data is Successfully Deleted!',
        });
        tableData();
      } else if (res.success === false) {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>

      <Breadcrumb.Item>All Processors</Breadcrumb.Item>
    </Breadcrumb>
  );

  useEffect(() => {
    tableData();

    // Bredcrumb
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    //  Append Data to table
    tableData();

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              All Processors
            </Col>
            <Col span="10" flex="row" align="end" className="text-end">
              <Button
                type="primary"
                onClick={() => LoadPage('addProcessor', dispatch)}
              >
                <IoAdd />
                {' '}
                Add Processor
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24">
          <Table columns={processorCols} dataSource={data} />
        </Col>
      </Row>
      <Modal
        width={800}
        footer={false}
        title="Update Processor"
        visible={updateModal}
        onCancel={handleCancel}
      >
        <Form
          name="basic"
          form={UpdateForm}
          layout="vertical"
          onFinish={updateProcessorSuccess}
          autoComplete="off"
        >
          <Row gutter="30" className="flex-direction-column">
            <Col span="12">
              <Form.Item
                label="Processor Make"
                name="make"
                rules={[
                  {
                    required: true,
                    message: 'Select Processor Make',
                  },
                ]}
              >
                <Select
                  placeholder="Select Processor Make"
                  optionFilterProp="children"
                  onChange={makeOnChange}
                  filterOption={(input, option) => option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value="Intel">Intel</Option>
                  <Option value="AMD">AMD</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item
                label="Processor Model"
                name="model"
                rules={[
                  {
                    required: true,
                    message: 'Enter Processor Model',
                  },
                ]}
              >
                <Input placeholder="Enter Processor Model like (E5-2430v3)..." />
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item
                label="Processor Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Enter Processor Name',
                  },
                ]}
              >
                <Input placeholder="Enter Processor Name like (Intel Xeon E5-2430v3)..." />
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item
                label="Processor Cores"
                name="cores"
                rules={[
                  {
                    required: true,
                    message: 'Enter Processor Cores',
                  },
                ]}
              >
                <Select
                  placeholder="Select Processor Cores"
                  optionFilterProp="children"
                  onChange={makeOnChange}
                  filterOption={(input, option) => option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value="2">2</Option>
                  <Option value="4">4</Option>
                  <Option value="6">6</Option>
                  <Option value="8">8</Option>
                  <Option value="10">10</Option>
                  <Option value="12">12</Option>
                  <Option value="16">16</Option>
                  <Option value="18">18</Option>
                  <Option value="32">32</Option>
                  <Option value="64">64</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item
                label="Processor Base Frequency"
                name="base_frequency"
                rules={[
                  {
                    required: true,
                    message: 'Enter Processor Base Frequency',
                  },
                ]}
              >
                <Input
                  placeholder="Enter Processor Base Frequency..."
                  addonAfter="GHz"
                />
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item
                label="Processor Turbo Frequency"
                name="turbo_frequency"
                rules={[
                  {
                    required: true,
                    message: 'Enter Processor Turbo Frequency',
                  },
                ]}
              >
                <Input
                  placeholder="Enter Processor Turbo Frequency..."
                  addonAfter="GHz"
                />
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item
                label="Processor TDP"
                name="tdp"
                rules={[
                  {
                    required: true,
                    message: 'Enter Processor TDP',
                  },
                ]}
              >
                <Input
                  placeholder="Enter Processor TDP in Watts..."
                  addonAfter="Watts"
                />
              </Form.Item>
            </Col>

            <Col span="24" className="my-1 py-2 border-top">
              <Button type="default" size="large" className="me-1 px-2" onClick={() => setUpdateModal(false)}>
                Cancel
              </Button>
              <Button
                type="primary"
                size="large"
                className="me-1 btn-success px-4"
                htmlType="submit"
              >
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      {/* Delete confirmation */}
      <Modal
        title="Confirm if you really want to delete?"
        visible={deleteModal}
        onCancel={cancelModal}
        okText="Delete"
        okButtonProps={{ className: 'btn-danger' }}
        onOk={deleteRowData}
      >
        <Row>
          <Col span="24">
            <p>
              <span style={{ fontSize: '20px', fontWeight: 500, color: 'var(--bs-danger)' }}>{deleteId ? `${deleteId.make}/${deleteId.name}/${deleteId.model}` : ''}</span>
            </p>
            <p>
              Do you really want to
              delete above Processor?
            </p>
            <p />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default AllProcessors;
