import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Breadcrumb,
  Select,
  Table,
  Modal,
  notification,
  Form,
  Input,
  Tabs,
} from 'antd';

import { IoAdd, IoPencil, IoTrash } from 'react-icons/io5';

// Custom imports
import AppContext from '../../context/appContext';
import { BREADCRUMB, EDIT_FORM_DATA } from '../../context/types';
import LoadPage from '../../common/loadPage';
import { DELETE, GET, PUT } from '../../common/crud';

const AllProducts = () => {
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [bmData, setBmData] = useState([]);
  const [bmAllData, setBmAllData] = useState([]);
  const [cloudData, setCloudData] = useState([]);
  const [cloudAllData, setCloudAllData] = useState([]);
  const [storageData, setStorageData] = useState([]);
  const [storageAllData, setStorageAllData] = useState([]);
  const [firewallData, setFirewallData] = useState([]);
  const [firewallAllData, setFirewallAllData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [deleteDataId, setDeleteDataId] = useState({});
  const [category, setCategory] = useState();
  const [locationList, setLocationList] = useState();
  const [, setCurrencyList] = useState();

  const [state, dispatch] = useContext(AppContext);

  const { Option } = Select;

  const { TextArea } = Input;

  const { TabPane } = Tabs;

  //  const [BaremetalForm] = Form.useForm();

  const [CloudForm] = Form.useForm();

  const [StorageForm] = Form.useForm();

  // Confirm Modal Delete
  const deleteRowData = (id, record) => {
    console.log('delete id - ', id, record);
    setDeleteDataId(record);
    setDeleteModal(true);
  };

  //  Update Data
  const updateModalData = (record, categoryVal) => {
    console.log('Update data - ', categoryVal, record);
    setCategory(categoryVal);

    if (record.category === 'baremetal') {
      console.log('Baremeal update click');

      dispatch({
        type: EDIT_FORM_DATA,
        payload: {
          editFormData: record,
          editPageName: 'addProduct',
        },
      });
      LoadPage('addProduct', dispatch);
    } else if (categoryVal === 'cloud') {
      console.log('Cloud form edit values - ', record);
      CloudForm.setFieldsValue(record);
      setUpdateData(record);
      setUpdateModal(true);
    } else if (categoryVal === 'storage') {
      StorageForm.setFieldsValue(record);
      setUpdateData(record);
      setUpdateModal(true);
    }
  };

  // Table Columns
  const bmCols = [
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      width: 120,
    },
    {
      title: 'Product Name',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'HDD Slots/Size',
      dataIndex: 'hddSlotsSize',
      key: 'hddSlotsSize',
      render: (_, record) => (
        <>
          {
            'hdd' in record ? (
              `${record.hdd.slots}/${record.hdd.size}`
            ) : ''
          }
        </>
      ),
    },
    {
      title: 'RAM Slots/Size',
      dataIndex: 'hddSlotsSize',
      key: 'hddSlotsSize',
      render: (_, record) => (
        <>
          {
            'ram' in record ? `${record.ram.slots}/${record.ram.size}GB` : ''
          }
        </>
      ),
    },

    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      width: 120,
      className: 'text-end',
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      width: 150,
      render: (rowKey, record) => (
        <>
          <Button
            type="default"
            className="me-1 btn-outline-secondary"
            onClick={() => updateModalData(record, record.category)}
            icon={<IoPencil />}
          />
          <Button
            type="default"
            className="me-1 btn-outline-danger"
            onClick={() => deleteRowData(rowKey, record)}
            icon={<IoTrash />}
          />
        </>
      ),
    },
  ];

  //  Cloud Cols
  const cloudCols = [
    {
      title: 'Product Name',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      width: 120,
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'Product ID',
      dataIndex: 'product_id',
      key: 'product_id',
    },
    {
      title: 'Price / GB',
      dataIndex: 'price',
      key: 'price',
      width: 120,
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      width: 150,
      render: (rowKey, record) => (
        <>
          <Button
            type="default"
            className="me-1 btn-outline-secondary"
            onClick={() => updateModalData(record, record.category)}
            icon={<IoPencil />}
          />
          <Button
            type="default"
            className="me-1 btn-outline-danger"
            onClick={() => deleteRowData(rowKey, record)}
            icon={<IoTrash />}
          />
        </>
      ),
    },
  ];

  //  Object Storage Cols
  const storageCols = [];

  //  Firewalls Cols
  const firewallCols = [];

  const handleCancel = () => {
    setUpdateModal(false);
    //  UpdateForm.resetFields();
  };

  /*  // Cancel Delete Modal
  const cancelModal = () => {
    setDeleteModal(false);
  }; */

  const dataFilterByCat = (data) => {
    if (data.length > 0) {
      const bmCatData = data.filter((itm) => itm.category === 'baremetal');
      setBmData(bmCatData);
      setBmAllData(bmCatData);

      //  Filter Storage Data
      const storageCatData = data.filter((itm) => itm.category === 'storage');
      setStorageData(storageCatData);
      setStorageAllData(storageCatData);

      //  Filter Cloud Data
      const cloudCatData = data.filter((itm) => itm.category === 'cloud');
      setCloudData(cloudCatData);
      setCloudAllData(cloudCatData);

      //  Filter Firewall Data
      const firewallCatData = data.filter((itm) => itm.category === 'firewall');
      setFirewallData(firewallCatData);
      setFirewallAllData(firewallCatData);
    }
  };

  //  Table Data
  const tableData = () => {
    GET('products', state.token).then((res) => {
      //  setLoading(false);
      console.log('Get res - ', res);
      //  const tableDataArray = [];
      if (res.success === true) {
        //  Filter BM Data
        dataFilterByCat(res.data.products);
      } else if (res.success === false) {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  };

  //  Get Locations
  const getLocations = async () => {
    GET('masterdata/locations', state.token).then(async (res) => {
      //  setLoading(false);
      console.log('Get Locations res - ', res);
      if (res.success === true) {
        const locationListArray = (
          await res.data.locations.map((locationRes) => (
            <Option key={locationRes._id} value={`${locationRes.name}`}>{`${locationRes.name}, ${locationRes.code}`}</Option>
          ))
        );
        const currencyListArray = (await res.data.locations.map((locItm) => (
          <Option key={locItm._id} value={`${locItm.base_currency}`}>
            {`${locItm.base_currency_symbol} - ${locItm.base_currency}`}
          </Option>
        )));
        setCurrencyList(currencyListArray);
        console.log('Location list after adding - ', locationListArray);
        setLocationList(locationListArray);
      } else if (res.success === false) {
        notification.error({
          message: 'Something Went Wrong!',
          description: 'Sorry we did not got Locations list from master data!!!',
        });
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  };

  //  Get Currencies
  /* const getCurrency = async () => {
    GET('masterdata/currencies', state.token).then(async (res) => {
      //  setLoading(false);
      console.log('Get Currency res - ', res);
      if (res.success === true) {
        const currencyListArray = (
          await res.data.map((currencyVal) => (
            <Option key={currencyVal._id} value={`${currencyVal.symbol}-${currencyVal.name}`}>
            {`${currencyVal.symbol}-${currencyVal.name}`}</Option>
          ))
        );
        console.log('Currency list after adding - ', currencyListArray);
        setCurrencyList(currencyListArray);
      } else if (res.success === false) {
        notification.error({
          message: 'Something Went Wrong!',
          description: 'Sorry we did not got Locations list from master data!!!',
        });
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  }; */

  //  Update Cloud Success
  const updateCloudSuccess = (values) => {
    setUpdateModal(false);
    console.log('Update Cloud values - ', values);
    const cloudBody = {
      category: updateData.category,
      location: updateData.location,
      currency: updateData.currency,
      description: values.description,
      title: values.title,
      product_id: values.product_id,
      price: Number(values.price),
      ram: Number(values.ram),
      diskcapacity: values.diskcapacity,
      noofcores: values.noofcores,
      status: values.status,
    };
    console.log('Update Cloud Body - ', cloudBody);
    PUT('products/cloud', updateData._id, state.token, cloudBody).then((res) => {
      if (res.success === true) {
        LoadPage('allProducts', dispatch);
        notification.success({
          message: 'Successfully Added!',
          description: 'Cloud is Successfully Added!',
        });
        tableData();
      } else {
        console.log('Product Cloud err - ', res.errors);
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  //  Update Cloud Success
  const updateeStorageSuccess = (values) => {
    setUpdateModal(false);
    console.log('Update Storage values - ', values);
    const objectStorageBody = {
      category: updateData.category,
      location: updateData.location,
      currency: updateData.currency,
      title: values.title,
      product_id: values.product_id,
      price: Number(values.price),
      status: values.status,
    };
    console.log('Update Cloud Body - ', objectStorageBody);
    PUT('products/storage', updateData._id, state.token, objectStorageBody).then((res) => {
      if (res.success === true) {
        LoadPage('allProducts', dispatch);
        notification.success({
          message: 'Successfully Added!',
          description: 'Cloud is Successfully Added!',
        });
        tableData();
      } else {
        console.log('Product Cloud err - ', res.errors);
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  //  Switch Addon Form
  let AddProductForm;
  switch (category) {
    case 'cloud':
      AddProductForm = (
        <Form
          form={CloudForm}
          name="basic"
          layout="vertical"
          onFinish={updateCloudSuccess}
          autoComplete="off"
        >
          <Row gutter="30">
            <Col span="12">
              <Form.Item label="Location" name="location">
                <Input placeholder="Enter Product Name" disabled />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item label="Currency" name="currency">
                <Input placeholder="Enter Product Name" disabled />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Product Id"
                name="product_id"
                rules={[
                  {
                    required: true,
                    message: 'Enter Product ID',
                  },
                ]}
              >
                <Input placeholder="Enter Product Id" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Product Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: 'Enter  Product Title',
                  },
                ]}
              >
                <Input placeholder="Enter  Product Title" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Product Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'Enter  Product Desc',
                  },
                ]}
              >
                <TextArea
                  rows="3"
                  placeholder="Enter Product description..."
                />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Number of Cores"
                name="noofcores"
                rules={[
                  {
                    required: true,
                    message: 'Enter # of Cores',
                  },
                ]}
              >
                <Input placeholder="Enter # of Cores" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="RAM"
                name="ram"
                rules={[
                  {
                    required: true,
                    message: 'Enter RAM',
                  },
                ]}
              >
                <Input placeholder="Enter RAM" addonAfter="GB" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Disk Capacity"
                name="diskcapacity"
                rules={[
                  {
                    required: true,
                    message: 'Enter Disk Capacity',
                  },
                ]}
              >
                <Input
                  placeholder="Enter Disk Capacity"
                  addonAfter="GB"
                />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Product Price"
                name="price"
                rules={[
                  {
                    required: true,
                    message: 'Enter Product Price',
                  },
                ]}
              >
                <Input
                  placeholder="Enter Product Price"
                  addonBefore={updateData ? updateData.base_currency_symbol : ''}
                />
              </Form.Item>
            </Col>
            <Col span="24" className="mt-2">
              <Button type="default" htmlType="reset" className="me-2" size="large">Reset</Button>
              <Button
                type="primary"
                size="large"
                className="me-1 btn-success px-4"
                htmlType="submit"
              >
                Update Cloud
              </Button>
            </Col>
          </Row>
        </Form>
      );
      break;

    case 'storage':
      AddProductForm = (
        <Form
          form={StorageForm}
          name="basic"
          layout="vertical"
          onFinish={updateeStorageSuccess}
          autoComplete="off"
        >
          <Row gutter="30">
            <Col span="12">
              <Form.Item label="Location" name="location">
                <Input placeholder="Enter Product Name" disabled />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item label="Currency" name="currency">
                <Input placeholder="Enter Product Name" disabled />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Product Name"
                name="title"
                rules={[
                  {
                    required: true,
                    message: 'Enter Product Name',
                  },
                ]}
              >
                <Input placeholder="Enter Product Name" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Product ID"
                name="product_id"
                rules={[
                  {
                    required: true,
                    message: 'Enter Product ID',
                  },
                ]}
              >
                <Input placeholder="Enter Product ID" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Object Storage Price"
                name="price"
                rules={[
                  {
                    required: true,
                    message: 'Enter Storage Price',
                  },
                ]}
              >
                <Input
                  placeholder="Enter Storge Price"
                  addonBefore={
                    updateData ? updateData.base_currency_symbol : ''
                  }
                  addonAfter="/GB"
                />
              </Form.Item>
            </Col>
            <Col span="24" className="mt-2">
              <Button
                type="default"
                size="large"
                htmlType="reset"
                className="me-2"
              >
                Reset
              </Button>
              <Button
                type="primary"
                size="large"
                className="me-1 btn-success px-4"
                htmlType="submit"
              >
                Update Storage
              </Button>
            </Col>
          </Row>
        </Form>
      );
      break;

    case 'firewall':
      AddProductForm = (
        'Firewall Form Coming soon!!!'
      );
      break;

    default:
      break;
  }

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>

      <Breadcrumb.Item>All Processors</Breadcrumb.Item>
    </Breadcrumb>
  );

  // Cancel Delete Modal
  const cancelModal = () => {
    setDeleteModal(false);
    setUpdateData(null);
    setDeleteDataId(null);
    //  UpdateForm.resetFields();
    if (deleteDataId === 'baremetal') {
      console.log('Baremeal update click');
    } else if (deleteDataId === 'cloud') {
      CloudForm.resetFields();
    } else if (deleteDataId === 'objectstorage') {
      StorageForm.resetFields();
    }
  };

  //  On Successfully Delete
  const deleteRow = () => {
    setDeleteModal(false);
    DELETE('products', deleteDataId._id, state.token).then((res) => {
      console.log('Delete Prouclt res - ', res);
      if (res.success === true) {
        LoadPage('allProducts', dispatch);
        notification.success({
          message: 'Successfully Deleted!',
          description: 'Selected product is Successfully Deleted!',
        });
        tableData();
        setDeleteDataId();
      } else {
        console.log('Product Cloud err - ', res.errors);
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
        setDeleteDataId();
      }
    });
  };

  //  Location on change
  const locationOnChange = (value) => {
    console.log('location on change - ', value);
    if (value === 'all') {
      setBmData(bmAllData);
      setCloudData(cloudAllData);
      setStorageData(storageAllData);
      setFirewallAllData(firewallAllData);
    } else {
      GET(`products?location=${value}`, state.token).then((res) => {
        if (res.success === true) {
          dataFilterByCat(res.data.products);
        } else {
          notification.error({
            message: 'Something went wrong!!!',
            description: 'Something went wrong, did not get data!!!',
          });
        }
      });
    }
  };

  useEffect(() => {
    tableData();

    getLocations();

    // Bredcrumb
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              All Products
            </Col>
            <Col span="10" flex="row" align="end">
              <Button
                type="primary"
                onClick={() => LoadPage('addProduct', dispatch)}
              >
                <IoAdd />
                {' '}
                Add Products
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24" className="mb-1">
          <Row gutter="30">
            <Col span="12">
              <Form.Item label="Location:">

                <Select
                  placeholder="Select Location"
                  defaultValue="all"
                  className="w-100"
                  onChange={locationOnChange}
                >
                  <Option value="all">All</Option>
                  {locationList}
                </Select>
              </Form.Item>
            </Col>
          </Row>

        </Col>
        <Col span="24">
          <Tabs defaultActiveKey="baremetal">
            <TabPane tab="Baremetal" key="baremetal">
              <Table columns={bmCols} dataSource={bmData} />
            </TabPane>
            <TabPane tab="Cloud" key="cloud">
              <Table columns={cloudCols} dataSource={cloudData} />
            </TabPane>
            <TabPane tab="Object Storage" key="objectStorage">
              <Table columns={storageCols} dataSource={storageData} />
            </TabPane>
            <TabPane tab="Firewall" key="firewall">
              <Table columns={firewallCols} dataSource={firewallData} />
            </TabPane>
          </Tabs>
          {/* <Table columns={processorCols} dataSource={data} /> */}
        </Col>
      </Row>

      {/* Update Modal */}
      <Modal
        width={1100}
        footer={false}
        title="Update Product"
        visible={updateModal}
        onCancel={handleCancel}
      >
        <Col span="24">
          {AddProductForm}
        </Col>
      </Modal>

      {/* Delete confirmation */}
      <Modal
        title="Confirm if you really want to delete?"
        visible={deleteModal}
        onCancel={cancelModal}
        okText="Delete"
        okButtonProps={{ className: 'btn-danger' }}
        onOk={deleteRow}
      >
        <Row>
          <Col span="24">
            <p>
              <span style={{ fontSize: '20px', fontWeight: 500, color: 'var(--bs-danger)' }}>
                {deleteDataId ? `${deleteDataId.title} / ${deleteDataId.price}/GB` : ''}
              </span>
            </p>
            <p>
              Do you really want to
              delete above Currency?
            </p>
            <p />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default AllProducts;
