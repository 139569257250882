import React from 'react';

import { Row, Col, Card } from 'antd';

import {
  FcDataProtection,
  FcDatabase,
  FcConferenceCall,
} from 'react-icons/fc';

//  Custom imports
import './dashboard.scss';

const Dashboard = () => (
  <>
    <Row>
      <Col span="24" className="full-width page-header">
        <Row align="middle">
          <Col span="14" className="page-title ">
            Dashboard
          </Col>
          <Col span="10" flex="row" align="end" />
        </Row>
      </Col>
    </Row>

    <Row gutter="20" className="m-2">
      <Col span="8">
        <Card className="full-width">
          <Row>
            <Col span="7">
              <div className="dashboard-card-icon">
                <FcDatabase />
              </div>
            </Col>
            <Col span="17">
              <div className="dashboard-card-title">
                <div className="card-big-title">500</div>
                <div className="card-small-title">Products</div>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span="8">
        <Card className="full-width">
          <Row>
            <Col span="7">
              <div className="dashboard-card-icon">
                <FcConferenceCall />
              </div>
            </Col>
            <Col span="17">
              <div className="dashboard-card-title">
                <div className="card-big-title">50,000</div>
                <div className="card-small-title">Customers</div>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span="8">
        <Card className="full-width">
          <Row>
            <Col span="7">
              <div className="dashboard-card-icon">
                <FcDataProtection />
              </div>
            </Col>
            <Col span="17">
              <div className="dashboard-card-title">
                <div className="card-big-title">100</div>
                <div className="card-small-title">Servers in Service</div>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  </>
);

export default Dashboard;
