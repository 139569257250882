import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Input,
  Button,
  Breadcrumb,
  Radio,
  Form,
  Select,
  Table,
  Modal,
  notification,
} from 'antd';

import { IoAdd, IoPencil, IoTrash } from 'react-icons/io5';

// Custom imports
import AppContext from '../../../context/appContext';
import { BREADCRUMB } from '../../../context/types';
import LoadPage from '../../../common/loadPage';
import { DELETE, GET, PUT } from '../../../common/crud';

const AllLanCards = () => {
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [deleteDataId, setDeleteDataId] = useState({});

  const [state, dispatch] = useContext(AppContext);
  const { Option } = Select;
  const [UpdateForm] = Form.useForm();

  // Confirm Modal Delete
  const deleteRowData = (id, record) => {
    console.log('delete id - ', id, record);
    setDeleteDataId(record);
    setDeleteModal(true);
  };

  //  Update Data
  const updateModalData = async (record) => {
    console.log('Update data - ', record);
    const tableContent = {
      ...record,
      ports: record.ports.toString(),
      pcie_version: record.pcie_version.toString(),
    };
    setUpdateData(tableContent);
    UpdateForm.setFieldsValue(tableContent);
    setUpdateModal(true);
  };

  // Table Columns
  const processorCols = [
    {
      title: 'Make',
      dataIndex: 'make',
      key: 'make',
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: 'Ports',
      dataIndex: 'ports',
      key: 'ports',
    },
    {
      title: 'Speed',
      dataIndex: 'speed',
      key: 'speed',
    },
    {
      title: 'Connector',
      dataIndex: 'connector',
      key: 'connector',
    },
    {
      title: 'PCIe Version',
      dataIndex: 'pcie_version',
      key: 'pcie_version',
    },
    {
      title: 'PCIe Lanes',
      dataIndex: 'pcie_lanes',
      key: 'pcie_lanes',
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      render: (rowKey, record) => (
        <>
          <Button
            type="default"
            className="me-1 btn-outline-secondary"
            onClick={() => updateModalData(record)}
            icon={<IoPencil />}
          />
          <Button
            type="default"
            className="me-1 btn-outline-danger"
            onClick={() => deleteRowData(rowKey, record)}
            icon={<IoTrash />}
          />
        </>
      ),
    },
  ];

  const handleCancel = () => {
    setUpdateModal(false);
    UpdateForm.resetFields();
  };

  // Cancel Delete Modal
  const cancelModal = () => {
    setDeleteModal(false);
  };

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>

      <Breadcrumb.Item>All Lan Cards</Breadcrumb.Item>
    </Breadcrumb>
  );

  //  Table Data
  const tableData = () => {
    GET('masterdata/lan_cards', state.token).then((res) => {
      //  setLoading(false);
      console.log('Get res - ', res);
      //  const tableDataArray = [];
      if (res.success === true) {
        setData(res.data.lan_cards);
      } else if (res.success === false) {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  };

  //  Delet data
  const deleteData = () => {
    setDeleteModal(false);
    DELETE('masterdata/lan_cards', deleteDataId._id, state.token).then((res) => {
      if (res.success === true) {
        setUpdateData([]);
        setDeleteDataId(null);
        notification.success({
          message: 'Deleted Successfully!',
          description: 'Data is Successfully Deleted!',
        });
        tableData();
      } else if (res.success === false) {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  // Create Lan Cards` SUCCESS
  const UpdateLanCardsSuccess = (values) => {
    console.log('Update form success values - ', values);

    const updateLancardBody = {
      make: values.make,
      model: values.model,
      ports: Number(values.ports),
      speed: values.speed,
      connector: values.connector,
      pcie_version: Number(values.pcie_version),
      pcie_lanes: values.pcie_lanes,
    };
    setUpdateModal(false);
    PUT('masterdata/lan_cards', updateData._id, state.token, updateLancardBody).then((res) => {
      if (res.success === true) {
        setUpdateData([]);
        setDeleteDataId(null);
        notification.success({
          message: 'Successfully Updated!',
          description: 'Data is Successfully Updated!',
        });
        tableData();
      } else {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  //  Card Speed onChang
  const speedOnChange = (value) => {
    //  setSpeedValue(value);
    console.log('Speed on change - ', value);
  };

  //  Card Make onChange
  const makeOnChange = (value) => {
    console.log('Make on change - ', value);
    //  setMakeValue(value);
  };

  useEffect(() => {
    tableData();

    console.log('Update initial values - ', updateData);

    // Bredcrumb
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              All LAN Cards
            </Col>
            <Col span="10" flex="row" align="end" className="text-end">
              <Button
                type="primary"
                onClick={() => LoadPage('addLanCard', dispatch)}
              >
                <IoAdd />
                {' '}
                Add LAN Card
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24">
          <Table columns={processorCols} dataSource={data} />
        </Col>
      </Row>
      <Modal
        width={800}
        footer={false}
        title="Update LAN Card"
        visible={updateModal}
        onCancel={handleCancel}
      >
        <Form
          name="basic"
          form={UpdateForm}
          layout="vertical"
          onFinish={UpdateLanCardsSuccess}
          autoComplete="off"
        >
          <Row gutter="30" className="flex-direction-column">
            <Col span="12">
              <Form.Item
                label="LAN Card Make"
                name="make"
                rules={[
                  {
                    required: true,
                    message: 'Enter LAN Card Make',
                  },
                ]}
              >
                <Select
                  placeholder="Select LAN Card Make"
                  onChange={makeOnChange}
                >
                  <Option value="Mellanxo">Mellanxo</Option>
                  <Option value="Chelsio">Chelsio</Option>
                  <Option value="Intel">Intel</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="LAN Card Model"
                name="model"
                rules={[
                  {
                    required: true,
                    message: 'Enter Lan Card Model',
                  },
                ]}
              >
                <Input placeholder="Enter LAN Card Model..." />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="LAN Card Ports"
                name="ports"
                rules={[
                  {
                    required: true,
                    message: 'Select Lan Card Ports',
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value="1">1</Radio>
                  <Radio value="2">2</Radio>
                  <Radio value="4">4</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item
                label="LAN Card Speed"
                name="speed"
                rules={[
                  {
                    required: true,
                    message: 'Select LAN Card Speed',
                  },
                ]}
              >
                <Select
                  placeholder="Select Processor Speed"
                  onChange={speedOnChange}
                >
                  <Option value="1G">1G</Option>
                  <Option value="10G">10G</Option>
                  <Option value="25G">25G</Option>
                  <Option value="40G">40G</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item
                label="LAN Card Connector"
                name="connector"
                rules={[
                  {
                    required: true,
                    message: 'jSelect LAN Card Connector',
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value="RJ-45">RJ-45</Radio>
                  <Radio value="SFP">SFP</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item
                label="LAN Card PCIe Version"
                name="pcie_version"
                rules={[
                  {
                    required: true,
                    message: 'Select LAN Card PCIe Version',
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value="3">3</Radio>
                  <Radio value="4">4</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="LAN Card PCIe Lanes"
                name="pcie_lanes"
                rules={[
                  {
                    required: true,
                    message: 'Select LAN Card PCIe Lanes',
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value="X8">X8</Radio>
                  <Radio value="X16">X16</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col span="24" className="my-1 py-2 border-top">
              <Button type="default" size="large" className="me-1 px-2" onClick={() => setUpdateModal(false)}>
                Cancel
              </Button>
              <Button
                type="primary"
                size="large"
                className="me-1 btn-success px-4"
                htmlType="submit"
              >
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* Delete confirmation */}
      <Modal
        title="Confirm if you really want to delete?"
        visible={deleteModal}
        onCancel={cancelModal}
        okText="Delete"
        okButtonProps={{ className: 'btn-danger' }}
        onOk={deleteData}
      >
        <Row>
          <Col span="24">
            <p>
              <span style={{ fontSize: '20px', fontWeight: 500, color: 'var(--bs-danger)' }}>{deleteDataId ? `${deleteDataId.make}/${deleteDataId.model}/${deleteDataId.pcie_lanes}/${deleteDataId.speed}` : ''}</span>
            </p>
            <p>
              Do you really want to
              delete above Processor?
            </p>
            <p />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default AllLanCards;
