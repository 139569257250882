import React, { useContext, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Input,
  Button,
  Breadcrumb,
  Radio,
  Form,
  Select,
  message,
  notification,
} from 'antd';

// Custom imports
import AppContext from '../../context/appContext';
import { BREADCRUMB } from '../../context/types';
import LoadPage from '../../common/loadPage';
import { POST } from '../../common/crud';

const AddUser = () => {
  const [statusValue, setStatusValue] = useState('active');

  const [state, dispatch] = useContext(AppContext);

  const { Option } = Select;

  const emptySpaceRegEx = /\s/;

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('allUsers', dispatch)}
        >
          All Users
        </Button>

      </Breadcrumb.Item>
      <Breadcrumb.Item>Add User</Breadcrumb.Item>
    </Breadcrumb>
  );

  // Create Location SUCCESS
  const createNewUserSuccess = (values) => {
    const newUserBody = {
      first_name: values.userFirstName,
      last_name: values.userLastName,
      email: values.userEmail,
      mobile: values.userMobile,
      role: values.contactRole,
      status: values.userStatus,

    };
    POST('users', state.token, newUserBody).then((res) => {
      if (res.success === true) {
        LoadPage('allUsers', dispatch);
        notification.success({
          message: 'User Successfully Added!',
          description: `We have sent Reset Password Link to ${values.userEmail}!`,
        });
        message.success('Successfully Added!!!');
      } else if (res.success === false) {
        message.error('Something went wrong! Please try again!');
      }
    });
  };

  //  Status on Change
  const statusOnChange = (value) => {
    setStatusValue(value);
  };

  useEffect(() => {
    //  Breadcrumb
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Add User
            </Col>
            <Col span="10" flex="row" align="end" />
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24">
          <Form
            name="basic"
            layout="vertical"
            onFinish={createNewUserSuccess}
            autoComplete="off"
            initialValues={{
              userFirstName: '',
              userLastName: '',
              userEmail: '',
              userMobile: '',
              contactRole: '',
              userStatus: statusValue,
            }}
          >
            <Row gutter="30" className="flex-direction-column">
              <Col span="12">
                <Form.Item
                  label="First Name"
                  name="userFirstName"
                  rules={[() => ({
                    validator(_, value) {
                      if (!value || !emptySpaceRegEx.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Empty spaces are not allowed!'));
                    },
                  })]}
                >
                  <Input placeholder="Enter First Name..." />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Last Name"
                  name="userLastName"
                  rules={[() => ({
                    validator(_, value) {
                      if (!value || !emptySpaceRegEx.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Empty spaces are not allowed!'));
                    },
                  })]}
                >
                  <Input placeholder="Enter Last Name..." />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Email"
                  name="userEmail"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Email Id',
                    },
                  ]}
                >
                  <Input type="email" placeholder="Enter Email..." />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Mobile" name="userMobile">
                  <Input placeholder="Enter Mobile..." />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item label="Role" name="contactRole">
                  <Select
                    showSearch
                    placeholder="Select Role"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value="Administrator">Administrator</Option>
                    <Option value="Sales">Sales</Option>
                    <Option value="Accounts">Accounts</Option>
                    <Option value="DCEngineer">DCEngineer</Option>
                    <Option value="Employee">Employee</Option>
                    <Option value="DeveloperMode">DeveloperMode</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Status"
                  name="userStatus"
                  rules={[
                    {
                      required: true,
                      message: 'Please select Supplier Type',
                    },
                  ]}
                >
                  <Radio.Group onChange={statusOnChange}>
                    <Radio value="active">Active</Radio>
                    <Radio value="inactive">Inactive</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col span="24" className="my-1 py-2 border-top">
                <Button type="default" size="large" className="me-1 px-2">
                  Cancel
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="me-1 btn-success px-4"
                  htmlType="submit"
                >
                  Add User
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default AddUser;
