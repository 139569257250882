import React, { useContext, useEffect, useState } from 'react';
import {
  Form,
  Select,
  Row,
  Col,
  Input,
  Button,
  notification,
} from 'antd';

import AppContext from '../../../context/appContext';
import Breadcrumbs from '../../../common/breadcrumbs';
import { BREADCRUMB } from '../../../context/types';
import { POST } from '../../../common/crud';
import LoadPage from '../../../common/loadPage';

const AddDisk = () => {
  const [state, dispatch] = useContext(AppContext);
  const [form] = Form.useForm();
  const [diskType, setDiskType] = useState('');

  const { Option } = Select;

  const breadcrumb = <Breadcrumbs title="Add Disk" />;

  const initialValues = {
    make: '',
    model: '',
    protocol: '',
    capacity: '',
    form_factor: '',
    disk_type: '',
    dwpd: '',
    speed: '',
  };

  useEffect(() => {
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, [dispatch]);

  const submitForm = (values) => {
    POST('masterdata/disks', state.token, values).then((res) => {
      if (res.success) {
        notification.success({
          message: 'Success',
          description: 'Disk added successfully',
        });
        form.resetFields();
        LoadPage('allDisks', dispatch);
      } else {
        notification.error({
          message: 'Something went wrong',
          description: `Got error with status code ${res.status}`,
        });
      }
    }).catch((err) => {
      console.error('Add disk err:', err);
    });
  };

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Add Disk
            </Col>
            <Col span="10" flex="row" align="end" />
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24">
          <Form
            layout="vertical"
            form={form}
            onFinish={submitForm}
            initialValues={initialValues}
          >
            <Row gutter="30" className="flex-direction-column">
              <Col span="12">
                <Form.Item
                  label="Disk Make"
                  name="make"
                  rules={[
                    { required: true, message: 'Enter disk make' },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select disk make"
                    optionLabelProp="children"
                  >
                    <Option value="Kingston">Kingston</Option>
                    <Option value="Micron">Micron</Option>
                    <Option value="Samsung">Samsung</Option>
                    <Option value="Seagate">Seagate</Option>
                    <Option value="Toshiba">Toshiba</Option>
                    <Option value="WD">WD</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Disk Model"
                  name="model"
                  rules={[
                    { required: true, message: 'Enter disk model' },
                  ]}
                >
                  <Input placeholder="Enter disk model" />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Disk Protocol"
                  name="protocol"
                  rules={[
                    { required: true, message: 'Enter disk protocol' },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select disk protocol"
                    optionLabelProp="children"
                  >
                    <Option value="SATA">SATA</Option>
                    <Option value="SAS">SAS</Option>
                    <Option value="NVMe">NVMe</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Disk Capacity"
                  name="capacity"
                  rules={[
                    { required: true, message: 'Enter disk capacity' },
                  ]}
                >
                  <Input placeholder="Enter disk capacity in GB" addonAfter="GB" />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Disk Form Factor"
                  name="form_factor"
                  rules={[
                    { required: true, message: 'Enter disk form factor' },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select disk form factor"
                    optionLabelProp="children"
                  >
                    <Option value="2.5">2.5</Option>
                    <Option value="3.5">3.5</Option>
                    <Option value="M.2">M.2</Option>
                    <Option value="U.2">U.2</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12" />
              <Col span="12">
                <Form.Item
                  label="Disk Type"
                  name="disk_type"
                  rules={[
                    { required: true, message: 'Enter disk type' },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select disk type"
                    optionLabelProp="children"
                    onChange={(value) => setDiskType(value)}
                  >
                    <Option value="SSD">SSD</Option>
                    <Option value="HDD">HDD</Option>
                  </Select>
                </Form.Item>
              </Col>
              {diskType === 'SSD' && (
                <Col span="12">
                  <Form.Item
                    label="Drive Writes Per Day"
                    name="dwpd"
                    rules={[
                      { required: true, message: 'Enter disk dwpd' },
                    ]}
                  >
                    <Input placeholder="Enter DWPD" />
                  </Form.Item>
                </Col>
              )}
              {diskType === 'HDD' && (
                <Col span="12">
                  <Form.Item
                    label="Disk Speed"
                    name="disk_speed"
                    rules={[
                      { required: true, message: 'Enter disk speed' },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select disk speed"
                      optionLabelProp="children"
                    >
                      <Option value="7200 RPM">7200 RPM</Option>
                      <Option value="15000 RPM">15000 RPM</Option>
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col span="24" className="my-1 py-2 border-top">
                <Button type="default" size="large" className="me-1 px-2">
                  Cancel
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="me-1 btn-success px-4"
                  htmlType="submit"
                >
                  Add Disk
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default AddDisk;
