import React, { useContext } from 'react';
import {
  Layout,
  Row,
  Col,
  Button,
  Tooltip,
} from 'antd';
import { IoLogOutOutline } from 'react-icons/io5';

//  Custom Imports
import './header.scss';
import logo from '../../assets/images/ibee-hosting-logo.png';
import AppContext from '../../context/appContext';
import LoadPage from '../../common/loadPage';
import { LOGOUT_SUCCESS } from '../../context/types';

const TopHeader = () => {
  const { Header } = Layout;

  const [state, dispatch] = useContext(AppContext);

  const logout = () => {
    dispatch({
      type: LOGOUT_SUCCESS,
    });
    window.location.reload();
  };

  const userInfo = JSON.parse(sessionStorage.getItem('user_info'));
  console.log('username  - ', userInfo);

  return (
    <Header
      className="full-width header px-0"
      style={{ backgroundColor: '#fff' }}
    >
      <Row>
        <Col span="4">
          <div className="full-width branding-block">
            <Button
              type="link"
              className="branding-link"
              title="IBEE Hosting"
              onClick={() => LoadPage('dashboard', dispatch)}
            >
              <img src={logo} alt="IBEE Hosting" />
            </Button>
          </div>
        </Col>
        <Col span="14" className="pt-15 px-2">
          <div className="full-width">
            {state.breadcrumb ? state.breadcrumb : ''}
          </div>
        </Col>
        <Col
          span="1"
          className="d-flex align-items-center justify-content-center"
        />
        <Col span="4">
          <div className="d-flex align-items-center justify-content-end" style={{ fontSize: '17px', color: '#888', paddingTop: '3px' }}>
            <em>Welcome </em>
            <span style={{ paddingLeft: '8px', color: '#222' }}>
              {
                userInfo ? `${userInfo.first_name} ${userInfo.last_name}` : 'Guest'
              }
            </span>

          </div>
        </Col>
        <Col span="1">
          <Tooltip title="Logout">
            <Button
              className="mx-1"
              shape=""
              icon={<IoLogOutOutline />}
              onClick={() => logout()}
            />
          </Tooltip>
        </Col>
      </Row>
    </Header>
  );
};

export default TopHeader;
