import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Input,
  Button,
  Breadcrumb,
  Form,
  Select,
  DatePicker,
  Table,
  Modal,
  Tag,
  notification,
  Radio,
  Switch,
} from 'antd';

import { IoAdd, IoPencil, IoTrash } from 'react-icons/io5';
import moment from 'moment';

// Custom imports
import AppContext from '../../context/appContext';
import { BREADCRUMB } from '../../context/types';
import LoadPage from '../../common/loadPage';
import { DELETE, GET, PUT } from '../../common/crud';
import { dateFormat } from '../../common/common';
//  const dateFormat = 'DD-MM-YYYY';

const AllDiscounts = () => {
  const [data, setData] = useState(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateData, setUpdateData] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [startDateValue, setStartDateValue] = useState(updateData ? updateData.start_date : '');
  const [endDateValue, setEndDateValue] = useState(updateData ? updateData.end_date : '');
  const [locationList, setLocationList] = useState();
  const [discountTypeValue, setDiscountTypeValue] = useState(updateData ? updateData.type : '');
  const [currency, setCurrency] = useState(updateData ? updateData.currency : '');
  const [statusValue, setStatusValue] = useState(!!(updateData && updateData.status === 'active'));

  const [state, dispatch] = useContext(AppContext);
  const { Option } = Select;

  const [UpdateForm] = Form.useForm();

  //  Update Row Data
  const updateRowData = (record) => {
    console.log('Update Row Data - ', record);
    setUpdateData(record);
    const currSymbol = locationList
      .filter((itm) => itm.name === record.location).map((res) => res.base_currency_symbol);
    setCurrency(currSymbol);
    UpdateForm.setFieldsValue({
      ...record,
      start_date: moment(record.start_date),
      end_date: moment(record.end_date),
      status: record.status ? 'active' : 'inactive',
    });
    setUpdateModal(true);
    setStartDateValue(record.start_date);
    setEndDateValue(record.end_date);
  };

  //  Delete Row Data
  const deleteRowData = (id, record) => {
    console.log('Delete Row Data - ', id, record);
    setDeleteId(record);
    setDeleteModal(true);
  };

  // Table Columns
  const discountCols = [
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      width: 130,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: 130,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 100,
    },
    {
      title: 'Dis. (₹)',
      dataIndex: 'discount',
      key: 'discount',
      className: 'text-end',
      width: 110,
    },
    {
      title: '# Users',
      dataIndex: 'no_of_uses',
      key: 'no_of_uses',
      width: 95,
    },
    {
      title: 'Valid for',
      dataIndex: 'valid_for',
      key: 'valid_for',
      width: 120,
      render: (text) => (
        <>
          {
            text.map((txt) => (
              <Tag key={`${txt}`} style={{ fontSize: '13px' }}>
                {txt}
                {' '}
              </Tag>
            ))
          }
        </>
      ),
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      width: 130,
      render: (text) => (
        <>
          { moment(text).format(dateFormat) }
        </>
      ),
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
      width: 130,
      render: (text) => (
        <>
          { moment(text).format(dateFormat) }
        </>
      ),
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (text) => (
        <>
          {text ? (
            <Tag color="green">{text}</Tag>
          ) : (
            <>
              <Tag color="red">{text}</Tag>
            </>
          )}
        </>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      width: 120,
      render: (id, record) => (
        <>
          <Button
            type="default"
            className="me-1 btn-outline-secondary"
            onClick={() => updateRowData(record)}
            icon={<IoPencil />}
          />
          <Button
            type="default"
            className="me-1 btn-outline-danger"
            onClick={() => deleteRowData(id, record)}
            icon={<IoTrash />}
          />
        </>
      ),
    },
  ];

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>

      <Breadcrumb.Item>All Discounts</Breadcrumb.Item>
    </Breadcrumb>
  );

  //  Set Starting Date
  const startDateOnChange = (value) => {
    setStartDateValue(value.toISOString(true));
  };

  //  Set End Date
  const endDateOnChange = (value) => {
    setEndDateValue(value.toISOString(true));
  };

  // Close Update Modal
  const handleCancel = () => {
    setUpdateModal(false);
    setUpdateData(null);
    UpdateForm.resetFields();
  };

  // Cancel Delete Modal
  const cancelModal = () => {
    setDeleteModal(false);
    setDeleteId(null);
  };

  const tableData = () => {
    GET('discounts', state.token).then((res) => {
      //  setLoading(false);
      console.log('Get res - ', res);
      if (res.success === true) {
        setData(res.data);
      } else if (res.success === false) {
        notification.success({
          message: 'Something Wrong!',
          description: 'Something went wrong! Please try again!',
        });
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  };

  // Update Discount Success
  const updateDiscountSuccess = (values) => {
    console.log('Update discount - ', values);
    // LoadPage('allDiscounts', dispatch);
    setUpdateModal(false);
    const newDiscountBody = {
      location: values.location,
      code: values.code,
      type: values.type,
      discount: values.discount,
      no_of_uses: values.no_of_uses,
      applicable_on: values.applicable_on,
      valid_for: values.valid_for,
      start_date: startDateValue,
      end_date: endDateValue,
    };
    setUpdateData(null);
    PUT('discounts', updateData._id, state.token, newDiscountBody).then((res) => {
      if (res.success === true) {
        notification.success({
          message: 'Successfully Updated!',
          description: 'Data is Successfully Updated!',
        });
        LoadPage('allDiscounts', dispatch);
        UpdateForm.resetFields();
        setStartDateValue(null);
        setEndDateValue(null);
        tableData();
      } else {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  //  Delete Data
  const deleteSuccess = () => {
    console.log('Got Delete id - ', deleteId);
    setDeleteModal(false);

    DELETE('discounts', deleteId._id, state.token).then((res) => {
      setUpdateData(null);
      setDeleteId(null);
      if (res.success === true) {
        UpdateForm.resetFields();
        notification.success({
          message: 'Deleted Successfully!',
          description: 'Data is Successfully Deleted!',
        });

        tableData();
      } else {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  //  Get Locations
  const getLocations = async () => {
    GET('masterdata/locations', state.token).then(async (res) => {
      //  setLoading(false);
      console.log('Get Locations res - ', res);
      if (res.success === true) {
        setLocationList(res.data.locations);
      } else if (res.success === false) {
        notification.error({
          message: 'Something Went Wrong!',
          description: 'Sorry we did not got Locations list from master data!!!',
        });
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  };

  //  Discount type on change
  const discountTypeOnChange = (e) => {
    console.log('Discount type on change - ', e.target.value);
    setDiscountTypeValue(e.target.value);
  };

  //  Sttus on Change
  const statusOnChange = (value) => {
    setStatusValue(value);
  };

  useEffect(() => {
    tableData();
    getLocations();

    // Bredcrumb
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              All Discounts
            </Col>
            <Col span="10" flex="row" align="end" className="text-end">
              <Button
                type="primary"
                onClick={() => LoadPage('addDiscount', dispatch)}
              >
                <IoAdd />
                {' '}
                Add Discount
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24">
          <Table columns={discountCols} dataSource={data} />
        </Col>
      </Row>
      <Modal
        width={1000}
        footer={false}
        title="Update Discount"
        visible={updateModal}
        onCancel={handleCancel}
      >
        <Form
          form={UpdateForm}
          name="basic"
          layout="vertical"
          onFinish={updateDiscountSuccess}
          autoComplete="off"
        >
          <Row gutter="30">
            <Col span="12">
              <Form.Item
                label="Location"
                name="location"
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Code"
                name="code"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Coupon Code',
                  },
                ]}
              >
                <Input placeholder="Enter Coupon Code" />
              </Form.Item>
            </Col>

            <Col span="12">
              <Row>
                <Col span="12">
                  <Form.Item
                    label="Discount type"
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Select Discount type',
                      },
                    ]}
                    initialValue={discountTypeValue}
                  >
                    <Radio.Group onChange={discountTypeOnChange}>
                      <Radio value="Percentage">Percentage</Radio>
                      <Radio value="Amount">Amount</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span="12">
                  {discountTypeValue && discountTypeValue === 'Percentage' ? (
                    <Form.Item
                      label="Discount"
                      name="discount"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Discount Price',
                        },
                      ]}
                    >
                      <Input addonAfter="%" placeholder="Enter Discount Price" />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      label="Discount"
                      name="discount"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Discount Price',
                        },
                      ]}
                    >
                      <Input addonBefore={currency} placeholder="Enter Discount Price" />
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </Col>

            <Col span="12">
              <Form.Item
                label="Discount"
                name="discount"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Discount Price',
                  },
                ]}
              >
                <Input addonBefore="₹" placeholder="Enter Discount Price" />
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item
                label="Number of Users"
                name="no_of_uses"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Number of users > 0',
                  },
                ]}
              >
                <Input placeholder="Enter Number of users > 0" />
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item
                label="Applicable on"
                name="applicable_on"
                rules={[
                  {
                    required: true,
                    message: 'Please select Application type',
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="Select Currency"
                  optionFilterProp="children"
                >
                  <Option value="baremetal">Baremetal</Option>
                  <Option value="cloud">Cloud</Option>
                  <Option value="storage">Storage</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item
                label="Valid for"
                name="valid_for"
                rules={[
                  {
                    required: true,
                    message: 'Please select plan',
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="Select Currency"
                  optionFilterProp="children"
                >
                  <Option value="new">New Users</Option>
                  <Option value="old">Old Users</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item
                label="Start Date"
                name="start_date"
                rules={[
                  {
                    required: true,
                    message: 'Please select Code starting date',
                  },
                ]}
              >
                <DatePicker className="w-100" format={dateFormat} onChange={startDateOnChange} />
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item
                label="End Date"
                name="end_date"
                rules={[
                  {
                    required: true,
                    message: 'Please select Code ending date',
                  },
                ]}
              >
                <DatePicker onChange={endDateOnChange} format={dateFormat} className="w-100" />
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item
                label="Status"
                name="status"
                rules={[
                  {
                    required: true,
                    message: 'Please select Status',
                  },
                ]}
              >
                <Switch checkedChildren="active" unCheckedChildren="inactive" onChange={statusOnChange} checked={statusValue} />
              </Form.Item>
            </Col>
            <Col span="24" className="my-1 py-2 border-top">
              <Button type="default" size="large" className="me-1 px-2" onClick={() => setUpdateModal(false)}>
                Cancel
              </Button>
              <Button
                type="primary"
                size="large"
                className="me-1 btn-success px-4"
                htmlType="submit"
              >
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      {/* Delete confirmation */}
      <Modal
        title="Confirm if you really want to delete?"
        visible={deleteModal}
        onCancel={cancelModal}
        okText="Delete"
        okButtonProps={{ className: 'btn-danger' }}
        onOk={deleteSuccess}
      >
        <Row>
          <Col span="24">
            <p>
              <span style={{ fontSize: '20px', fontWeight: 500, color: 'var(--bs-danger)' }}>
                {deleteId ? `${deleteId.code}/${deleteId.discount}` : ''}
                <small style={{ fontSize: '14px' }}>(price)</small>
              </span>
            </p>
            <p>
              Do you really want to
              delete above RAM?
            </p>
            <p />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default AllDiscounts;
