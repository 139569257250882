import React, { useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Input,
  Button,
  Breadcrumb,
  Form,
  Select,
  notification,
} from 'antd';

// Custom imports
import AppContext from '../../../context/appContext';

import { BREADCRUMB } from '../../../context/types';
import countries from '../../../assets/json/countries.json';
import LoadPage from '../../../common/loadPage';
import { POST } from '../../../common/crud';

const AddCurrency = () => {
  const [state, dispatch] = useContext(AppContext);

  const { Option } = Select;

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('allCurrencies', dispatch)}
        >
          All Currencies
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Add Currency</Breadcrumb.Item>
    </Breadcrumb>
  );

  // Create Location SUCCESS
  const addCurrencySuccess = (values) => {
    const addCurrencyBody = {
      name: values.currencyName,
      symbol: values.countrySymbol,
      country: values.countryName,
      pg: values.paymentGateway,
    };
    console.log('Add currency form success - ', values, state.apiUrl, addCurrencyBody);

    POST('masterdata/currencies', state.token, addCurrencyBody).then((res) => {
      console.log('Curr err - ', res.errors);
      if (res.success === true) {
        console.log('Post res - ', res);
        LoadPage('allCurrencies', dispatch);
        notification.success({
          message: 'Successfully Added!',
          description: 'Data is Successfully Added!',
        });
      } else {
        console.log('Curr err - ', res.errors);
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    }).catch((err) => {
      console.log('Err - ', err);
    });
  };

  // Create Location FAILED
  const addCurrencyFailed = () => {};

  // countries on change
  const countriesOnChange = (value) => {
    console.log('Country on change - ', value);
  };

  // Countries List
  const CountriesList = countries.map((country) => (
    <Option key={country.isoCode} value={country.name}>
      {country.name}
    </Option>
  ));

  useEffect(() => {
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Add Currency
            </Col>
            <Col span="10" flex="row" align="end" />
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24">
          <Form
            name="basic"
            layout="vertical"
            onFinish={addCurrencySuccess}
            onFinishFailed={addCurrencyFailed}
            autoComplete="off"
            initialValues={{
              currencyName: '',
              countrySymbol: '',
              countryName: '',
              paymentGateway: '',
            }}
          >
            <Row gutter="30" className="flex-direction-column">
              <Col span="12">
                <Form.Item
                  label="Currency Name"
                  name="currencyName"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Currency Name',
                    },
                  ]}
                >
                  <Input placeholder="Currency Name like Indian Rupee, Unisted States Dollar, Australia Dollar..." />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Currency Symbol"
                  name="countrySymbol"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Currency Symbol',
                    },
                  ]}
                >
                  <Input placeholder="Enter symbol like $, A$, HK$..." />
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Country name"
                  name="countryName"
                  rules={[
                    {
                      required: true,
                      message: 'Select Country',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select Country"
                    onChange={countriesOnChange}
                    style={{ width: '100%' }}
                    filterOption={(input, option) => option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0}
                  >
                    {CountriesList}
                  </Select>
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Payment Gateway"
                  name="paymentGateway"
                  rules={[
                    {
                      required: true,
                      message: 'Select Payment Gateway',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select Payment Gateway"
                    onChange={countriesOnChange}
                    style={{ width: '100%' }}
                    filterOption={(input, option) => option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value="Razorpay">Razorpay</Option>
                    <Option value="Paypal">Paypal</Option>
                    <Option value="Stripe">Stripe</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span="24" className="my-1 py-2 border-top">
                <Button type="default" size="large" className="me-1 px-2">
                  Cancel
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="me-1 btn-success px-4"
                  htmlType="submit"
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default AddCurrency;
