import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Breadcrumb,
  Button,
  //  Table,
  Tooltip,
  Tag,
  notification,
  Modal,
  Alert,
} from 'antd';

import {
  IoCheckmarkCircle,
  IoAlertCircle,
} from 'react-icons/io5';

//  Custom imports
import './orderDetail.scss';
import moment from 'moment';
import AppContext from '../../context/appContext';
import { BREADCRUMB } from '../../context/types';
import { GET, POST } from '../../common/crud';
import LoadPage from '../../common/loadPage';
import { dateFormat } from '../../common/common';
//  import { dateFormat } from '../../common/common';

const OrderDetail = () => {
  const [data, setData] = useState(null);
  const [custDetails, setCustDetails] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [stopModal, setStopModal] = useState(false);

  const [state, dispatch] = useContext(AppContext);

  const orderId = sessionStorage.getItem('orderId');

  //  Order Edit
  const orderEdit = (record) => {
    console.log('Order Edit record - ', record);
  };

  //  Ordre Cancel
  const orderCancel = (record) => {
    console.log('Order cancel record - ', record);
  };

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('recurringOrders', dispatch)}
        >
          Recurring Orders
        </Button>
      </Breadcrumb.Item>

      <Breadcrumb.Item>Order Detail</Breadcrumb.Item>
    </Breadcrumb>
  );

  //  Get Order detail
  const getOrderDetail = (id) => {
    GET(`orders/${id}`, state.token).then((res) => {
      console.log('Order detail - ', res.data);
      if (res.success === true) {
        setData(res.data);
        setOrderData(res.data.cart_items);
        GET(`customers/${res.data.customer_id}`, state.token).then((resCust) => {
          if (resCust.success === true) {
            console.log('Customers res - ', resCust.data);
            //  setCustomers(res.data);
            setCustDetails(resCust.data);
          } else {
            notification.error({
              message: 'Something Wrong!!!',
              description: 'Something went wrong, did not get data!',
            });
          }
        });
      }
    });
  };

  //  Order Status Tag
  const orderStatus = (value) => {
    switch (value) {
      case 'received':
        return (
          <Tag color="green" style={{ fontSize: '15px' }}>
            {value}
          </Tag>
        );

      case 'idle':
        return (
          <Tag color="orange" style={{ fontSize: '15px' }}>
            {value}
          </Tag>
        );

      default:
        return (
          <Tag>
            {value}
          </Tag>
        );
    }
  };

  //  Payment Sttus Tag
  const paymentStatus = (value) => {
    switch (value) {
      case 'paid':
        return (
          <Tag color="green" style={{ fontSize: '15px' }}>
            {value}
          </Tag>
        );

      case 'pending':
        return (
          <Tag color="red" style={{ fontSize: '15px' }}>
            {value}
          </Tag>
        );

      case 'partial':
        return (
          <Tag color="orange" style={{ fontSize: '15px' }}>
            {value}
          </Tag>
        );

      case 'cancelled':
        return (
          <Tag color="magenta" style={{ fontSize: '15px' }}>
            {value}
          </Tag>
        );

      default:
        return (
          <Tag>
            {value}
          </Tag>
        );
    }
  };

  //  Addon Title based on Category
  const addonTitle = (addonInfo) => {
    const addonCat = 'category' in addonInfo ? addonInfo.category : '';
    switch (addonCat) {
      case 'os':
        return (
          `${addonInfo.title}`
        );

      case 'ram':
        return (
          `${addonInfo.technology}/${addonInfo.size}GB`
        );

      case 'hdd':
        return (
          `${addonInfo.protocol}/${addonInfo.capacity}GB`
        );

      case 'bandwidth':
        return (
          'Bandwidth'
        );

      case 'ipaddress':
        return (
          'IP Address'
        );

      case 'raid':
        if ('raid' in addonInfo) {
          return (addonInfo.raid);
        }
        return ('N/A');

      default:
        return 'N/A';
    }
  };

  //  Order Cancel
  const cancelOrder = () => {
    const stop = data.order_type !== 'single';

    POST(`orders/recurring/${data._id}?stop=${stop}`, state.token, {}).then((res) => {
      if (res.success === true) {
        setStopModal(false);
        if (orderId && orderId !== undefined && orderId !== '') {
          getOrderDetail(JSON.parse(orderId));
        }
        notification.success({
          message: 'Successfully your order cancelled!',
          description: 'Your order is cancelled successfully!!!',
        });
      }
    });
  };

  //  Cancel Modal
  const handleCancel = () => {
    setStopModal(false);
  };

  useEffect(() => {
    // orderDetail();

    //  getCustomers();

    if (orderId && orderId !== undefined && orderId !== '') {
      getOrderDetail(JSON.parse(orderId));
    }

    // Bredcrumb
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });

      //  Remove Order Id from Session Storage
      sessionStorage.removeItem('ordreId');
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Order Detail
            </Col>
            {/* <Col span="10" flex="row" align="end" className="text-end">
              <Button
                type="primary"
                onClick={() => LoadPage('addLocation', dispatch)}
              >
                <IoAdd />
                {' '}
                Add Location
              </Button>
            </Col> */}
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        {
          data && data.order_type === 'single' ? (
            <Col span="24" className="mb-2">
              <Alert type="warning" showIcon message="This order is stopped from recurring in future!" />
            </Col>
          ) : ''
        }
        <Col span="24 mb-2">
          <Row span="40">
            <Col span="11">
              <ul className="list-unstyled full-width customer-details-list">
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">Company Name:</span>
                    <span className="each-customer-details">
                      {custDetails ? custDetails.display_name : 'N/A'}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">Customer Name:</span>
                    <span className="each-customer-details">
                      {custDetails
                        ? `${custDetails.first_name} ${custDetails.last_name}`
                        : 'N/A'}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">Email:</span>
                    {custDetails && custDetails.email_verified ? (
                      <span className="each-customer-details">
                        <Tooltip title="Verified">
                          <IoCheckmarkCircle
                            color="var(--bs-success)"
                            size="18"
                            style={{ marginRight: '5px' }}
                          />
                        </Tooltip>
                        {custDetails.email}
                      </span>
                    ) : (
                      <span className="each-customer-details">
                        <Tooltip title="Verified">
                          <IoAlertCircle
                            color="var(--bs-orange)"
                            size="18"
                            style={{ marginRight: '5px' }}
                          />
                        </Tooltip>
                        {custDetails.email ? custDetails.email : 'N/A'}
                      </span>
                    )}
                  </div>
                </li>
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">Phone:</span>
                    <span className="each-customer-details">
                      <Tooltip title="Not Verified">
                        <IoAlertCircle color="var(--bs-warning)" size="18" />
                      </Tooltip>
                      +91 12345 12345
                    </span>
                  </div>
                </li>
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">GST:</span>
                    {custDetails && custDetails.gstin_verified ? (
                      <span className="each-customer-details">
                        <Tooltip title="Verified">
                          <IoCheckmarkCircle
                            color="var(--bs-success)"
                            size="18"
                            style={{ marginRight: '5px' }}
                          />
                        </Tooltip>
                        GST NUMBER HERE
                      </span>
                    ) : (
                      <span className="each-customer-details">
                        <Tooltip title="Verified">
                          <IoAlertCircle
                            color="var(--bs-orange)"
                            size="18"
                            style={{ marginRight: '5px' }}
                          />
                        </Tooltip>
                        GST NUMBER HERE
                      </span>
                    )}
                  </div>
                </li>
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">IP Address:</span>
                    <span className="each-customer-details">000.000.000</span>
                  </div>
                </li>
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">
                      KYC Verification:
                    </span>
                    <span className="each-customer-details">
                      <Tooltip title="Rejected">
                        <IoAlertCircle color="var(--bs-danger)" size="18" />
                      </Tooltip>
                      {' '}
                      Rejected
                    </span>
                  </div>
                </li>
                {/* <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">&nbsp;</span>
                    <span className="each-customer-details">
                      <Button
                        type="primary"
                        size="large"
                        className="btn-danger"
                      >
                        Ban User
                      </Button>
                    </span>
                  </div>
                </li> */}
              </ul>
            </Col>

            <Col span="9" offset="4">
              <ul className="list-unstyled full-width customer-details-list">
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">Order Nos.:</span>
                    <span className="each-customer-details">
                      {data ? data._id.slice(-6) : ''}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">Order Date:</span>
                    <span className="each-customer-details">
                      {data ? moment(data.order_date).format(dateFormat) : ''}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">Order Status:</span>
                    <span className="each-customer-details">
                      {data ? orderStatus(data.order_status) : 'N/A'}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">Payment Status</span>
                    <span className="each-customer-details">
                      {data ? paymentStatus(data.payment_status) : 'N/A'}
                    </span>
                  </div>
                </li>
                {/* <li>
                  <div className="each-customer-detail full-width">
                    <span className="each-customer-title">&nbsp;</span>
                    <span className="each-customer-details">
                      <Button
                        type="primary"
                        size="large"
                        className="btn-success me-2"
                      >
                        Accept Order
                      </Button>
                    </span>
                  </div>
                </li> */}
              </ul>
            </Col>
          </Row>
        </Col>

        <Col span="24">
          {/* <Table columns={ordersCols} dataSource={orderData} /> */}
          <div className="ant-table">
            <div className="ant-table-container">
              <div className="ant-table-content" style={{ fontSize: '14px' }}>
                <table
                  className="w-100 table-striped"
                  style={{ tableLayout: 'auto' }}
                >
                  <thead className="ant-table-thead">
                    <tr>
                      <th className="ant-table-cell">Item Description</th>
                      <th className="ant-table-cell" width="70">
                        Qty.
                      </th>
                      <th className="ant-table-cell" width="100">
                        Item Price/Unit
                      </th>
                      {/* <th className="ant-table-cell text-end" width="100">
                        Discount
                      </th> */}
                      <th className="ant-table-cell text-end" width="120">
                        Tax
                      </th>
                      <th className="ant-table-cell text-end" width="130">
                        Sub Total
                      </th>
                      <th className="ant-table-cell" width="170">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="ant-table-tbody">
                    {orderData
                      ? orderData.map((res, index) => (
                        <>
                          <tr>
                            <td className="ant-table-cell">
                              <strong>
                                {index + 1}
                                .
                                {' '}
                                {res.product_info.title}
                              </strong>
                            </td>
                            <td className="ant-table-cell">{res.quantity}</td>
                            <td className="ant-table-cell text-end">
                              {'base_currency_symbol' in res.product_info
                                ? res.product_info.base_currency_symbol
                                : ''}
                              {' '}
                              {Number(
                                100
                                    * (res.product_price
                                      / (100 + res.product_tax)),
                              ).toFixed(2)}
                            </td>
                            {/* <td className="ant-table-cell text-end">
                              N/A
                            </td> */}
                            <td className="ant-table-cell text-end">
                              {'base_currency_symbol' in res.product_info
                                ? res.product_info.base_currency_symbol
                                : ''}
                              {' '}
                              {Number(
                                res.product_tax
                                    * (res.product_price
                                      / (100 + res.product_tax)),
                              ).toFixed(2)}
                            </td>
                            <td className="ant-table-cell text-end">
                              {'base_currency_symbol' in res.product_info
                                ? res.product_info.base_currency_symbol
                                : ''}
                              {' '}
                              {res.product_price * res.quantity}
                            </td>
                            <td className="ant-table-cell">
                              <Button
                                type="default"
                                className="me-1 btn-gray"
                                size="small"
                                onClick={() => orderEdit('id')}
                                disabled
                              >
                                Edit
                              </Button>
                              <Button
                                type="default"
                                className="me-1 btn-gray"
                                size="small"
                                onClick={() => orderCancel('id')}
                                disabled
                              >
                                Cancel
                              </Button>
                            </td>
                          </tr>
                          {'addons' in res && res.addons.length > 0
                            ? res.addons.map((add) => (
                              <tr>
                                <td className="ant-table-cell ps-3">
                                  {addonTitle(add.addon_info)}
                                </td>
                                <td className="ant-table-cell">
                                  {add.quantity}
                                </td>
                                <td className="ant-table-cell text-end">
                                  {/*

                                    {Number(
                                      add.price - ((add.price * res.addon_tax) / 100),
                                    ).toFixed(2)} */}
                                  {'base_currency_symbol' in add.addon_info
                                    ? add.addon_info.base_currency_symbol
                                    : ''}
                                  {' '}
                                  {Number(add.price).toFixed(2)}
                                </td>
                                {/* <td className="ant-table-cell text-end">
                                    N/A
                                  </td> */}
                                <td className="ant-table-cell text-end">
                                  {'base_currency_symbol' in add.addon_info
                                    ? add.addon_info.base_currency_symbol
                                    : ''}
                                  {' '}
                                  {Number(
                                    (add.price * res.addon_tax) / 100,
                                  ).toFixed(2)}
                                </td>
                                <td className="ant-table-cell text-end">
                                  {'base_currency_symbol' in add.addon_info
                                    ? add.addon_info.base_currency_symbol
                                    : ''}
                                  {' '}
                                  {Number(
                                    add.price
                                          + (add.price * res.addon_tax) / 100,
                                  ).toFixed(2)}
                                </td>
                                <td className="ant-table-cell" />
                              </tr>
                            ))
                            : ''}
                        </>
                      ))
                      : ''}
                    {/* <tr>
                      <td className="ant-table-cell" />
                      <td className="ant-table-cell text-end" />
                      <td className="ant-table-cell" />
                      <td className="ant-table-cell text-end">SubTotal:</td>
                      <td className="ant-table-cell text-end">
                        {
                          data && orderTax ? (
                            Number(
                              100 * (data.cart_price
                                  / (100 + orderTax)
                              ),
                            ).toFixed(2)
                          ) : 'N/A'
                        }
                      </td>
                      <td className="ant-table-cell" />
                    </tr>
                    <tr>
                      <td className="ant-table-cell" />
                      <td className="ant-table-cell text-end" />
                      <td className="ant-table-cell" />
                      <td className="ant-table-cell text-end">Total Tax:</td>
                      <td className="ant-table-cell text-end">
                        {
                          data && orderTax ? (
                            Number(
                              orderTax * (data.cart_price
                                  / (100 + orderTax)
                              ),
                            ).toFixed(2)
                          ) : 'N/A'
                        }
                        14750
                      </td>
                      <td className="ant-table-cell" />
                    </tr> */}
                    <tr>
                      <td className="ant-table-cell" />
                      <td className="ant-table-cell text-end" />
                      <td className="ant-table-cell" />
                      <td className="ant-table-cell text-end">Discount:</td>
                      <td className="ant-table-cell text-end">
                        {data ? Number(data.discount_value).toFixed(2) : 'N/A'}
                      </td>
                      <td className="ant-table-cell" />
                    </tr>
                    <tr>
                      <td className="ant-table-cell" />
                      <td className="ant-table-cell text-end" />
                      <td className="ant-table-cell" />
                      <td className="ant-table-cell text-end">
                        <strong>Grand Total:</strong>
                        :
                      </td>
                      <td className="ant-table-cell text-end">
                        {data ? Number(data.cart_price).toFixed(2) : 'N/A'}
                      </td>
                      <td className="ant-table-cell" />
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Col>
        <Col span="24" className="mt-3">
          <Row>
            <Col span="8">
              <Button type="ghost" size="large" className=" btn-gray" disabled>
                Suspend Order
              </Button>
            </Col>
            <Col span="8" offset="8" className="text-end">
              <Button type="ghost" size="large" className={data && data.order_type === 'single' ? 'btn-info text-white' : 'btn-danger'} onClick={() => setStopModal(true)}>
                {
                  data && data.order_type === 'single' ? 'Resume Order' : 'Cancel Order'
                }
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        title="Cancel Order"
        visible={stopModal}
        onCancel={handleCancel}
        okText="Yes Stop Recurring"
        okButtonProps={{
          className: 'btn-primary',
          title: stopModal.order_type === 'single' ? ' Continue Recurring order '
            : 'Stop Recurring order ',
        }}
        onOk={() => cancelOrder()}
      >
        <Row>
          <Col style={{ fontSize: '20px' }}>
            Dear
            {' '}
            {custDetails ? custDetails.display_name : null}
            , are you sure, do you really want to
            {' '}
            <span className="text-danger">
              {data && data.order_type === 'single' ? ' Continue with '
                : 'Stop Order' }
              {' '}
              {data ? data._id.slice(-6) : null}
              {' '}
              from Next Month
            </span>
            . Once abandoned by system, this order cannot be revoked, you need to again
            create new order!
          </Col>
        </Row>
        <Row />
      </Modal>
    </>
  );
};

export default OrderDetail;
