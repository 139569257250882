import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Input,
  Button,
  Breadcrumb,
  Radio,
  Form,
  Select,
  //  notification,
  Tabs,
  notification,
} from 'antd';
import { IoTrash, IoAdd } from 'react-icons/io5';

import countries from '../../assets/json/countries.json';
import cities from '../../assets/json/cities.json';
import states from '../../assets/json/india-states.json';
import curencies from '../../assets/json/country-currencies.json';

// Custom imports
import AppContext from '../../context/appContext';
import { BREADCRUMB, EDIT_FORM_DATA } from '../../context/types';
import LoadPage from '../../common/loadPage';
import { POST, GET, PUT } from '../../common/crud';

const AddCustomer = () => {
  const [tabActiveValue, setTabActiveValue] = useState('basicInfo');
  const [currencyList, setCurrencyList] = useState(null);

  const [state, dispatch] = useContext(AppContext);
  const [customerForm] = Form.useForm();
  const { TabPane } = Tabs;
  const { Option } = Select;

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('allCustomers', dispatch)}
        >
          All Customers
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Add Customer</Breadcrumb.Item>
    </Breadcrumb>
  );

  //  Customers List
  const countryNameList = countries.map((country) => (
    <Option key={country.name} value={country.name}>{country.name}</Option>
  ));

  //  Cities List
  const citiesList = cities.map((city) => (
    <Option key={city.name} value={city.name}>{city.name}</Option>
  ));

  //  States List
  const statesList = states.map((inState) => (
    <Option key={inState.code} value={inState.name}>{inState.name}</Option>
  ));

  //  Tab onClick
  const addSupplierTabClick = (key) => {
    setTabActiveValue(key);
    console.log('Tab clicked key - ', key);
  };

  //  Get Locations
  const getLocations = () => {
    GET('masterdata/locations', state.token).then((res) => {
      const currencyArray = {};
      if (res.success === true) {
        console.log('Locations res - ', res.data.locations);

        if (res.data.locations) {
          res.data.locations.map((location) => (
            location.currency.map((cur) => { currencyArray[cur] = cur; return true; })
          ));
        }
      } else {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }

      console.log('currencyArray - ', currencyArray);

      const currList = Object.keys(currencyArray).map((cur) => {
        const symbol = curencies.filter((itm) => itm.currency.code === cur)[0];
        return (
          <Option key={cur} value={cur}>
            {symbol.currency.symbol}
            {' '}
            -
            {' '}
            {cur}
          </Option>
        );
      });

      setCurrencyList(currList);
    });
  };

  //  Add Customer Form Success
  const addCustomerSuccess = (values) => {
    const addCustomerBody = {
      ...values,
      email_verified: true,
      support_login: false,
      enable_2fa: values.enable_2fa === 'Yes',
      login_notify: values.login_notify === 'Yes',
      opening_balance: values && values.opening_balance ? Number(values.opening_balance) : 0,
      billing_address: {
        city: values.city,
        country: values.country,
        line1: values.line1,
        line2: values.line2,
        postal_code: values.postal_code,
        state: values.state,
      },
      ssh: [],
      contacts: values.contacts_list,
      invoiced_amount: 0,
      amount_recieved: 0,
      balance_due: 0,
      is_inr_from_ip: false,
      groups: [],
      gstin_verified: false,
      first_payment_verified: false,
      /* customer_type: 'personal',
      first_name: values.first_name,
      last_name: values.last_name,
      display_name: values.display_name ? values.display_name : values.email,
      email: values.email,
      phone: values.phone,
      website: values.website,
      role: values.role,
      gst_treatment: values.gst_treatment,
      gstin: values.gstin,
      payment_terms: values.payment_terms,
      tax_preference: values.tax_preference,
      status: values.active,
      kyc_verified: values.kyc_verified, */
    };

    console.log('Add Customer Body - ', addCustomerBody);

    if (state.editPageName === 'addCustomer') {
      PUT('customers', state.editFormData._id, state.token, addCustomerBody).then((putRes) => {
        if (putRes.success === true) {
          customerForm.resetFields();
          LoadPage('allCustomers', dispatch);
          notification.success({
            message: 'Successfully Added!',
            description: 'Data is Successfully Added!',
          });
        } else {
          notification.error({
            message: 'Something Went Wrong!',
            description: `Got Error with status code = ${putRes.status}!`,
          });
        }
      });
    } else {
      POST('customers', state.token, addCustomerBody).then((res) => {
        if (res.success === true) {
          customerForm.resetFields();
          LoadPage('allCustomers', dispatch);
          notification.success({
            message: 'Successfully Added!',
            description: 'Data is Successfully Added!',
          });
        } else {
          notification.error({
            message: 'Something Went Wrong!',
            description: `Got Error with status code = ${res.status}!`,
          });
        }
      });
    }
  };

  useEffect(() => {
    getLocations();

    console.log('Add Customer state - ', state);

    //  Breadcrum Nav
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    if (state.editPageName === 'addCustomer') {
      customerForm.setFieldsValue({
        ...state.editFormData,
        contacts_list: state.editFormData.contacts,
        city: state.editFormData.billing_address.city,
        country: state.editFormData.billing_address.country,
        line1: state.editFormData.billing_address.line1,
        line2: state.editFormData.billing_address.line2,
        postal_code: state.editFormData.billing_address.postal_code,
        state: state.editFormData.billing_address.state,
        enable_2fa: state.editFormData.enable_2fa ? 'Yes' : 'No',
        login_notify: state.editFormData.login_notify ? 'Yes' : 'No',
      });
    }

    return () => {
      //  Empty Edit Form
      dispatch({
        type: EDIT_FORM_DATA,
        payload: {
          editFormData: '',
          editPageName: '',
        },
      });

      //  Breadcrumb Empty
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Add Customer
            </Col>

          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24">
          <Form
            form={customerForm}
            name="basic"
            layout="vertical"
            onFinish={addCustomerSuccess}
            autoComplete="off"
          >
            <Row gutter="30" className="flex-direction-column">
              <Col span="24">
                <Col span="24">
                  <Tabs defaultActiveKey="basicInfo" className="addStockTabsBlock" onTabClick={addSupplierTabClick} activeKey={tabActiveValue}>
                    <TabPane tab="Basic Info" key="basicInfo">
                      <Row gutter="30">
                        <Col span="12">
                          <Form.Item
                            label="Customer Type"
                            name="customer_type"
                            rules={[
                              {
                                required: true,
                                message: 'Please select Customer Type',
                              },
                            ]}
                          >
                            <Radio.Group value="">
                              <Radio value="business">Business</Radio>
                              <Radio value="contractor">Contractor</Radio>
                              <Radio value="personal">Personal</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col span="12">
                          <Form.Item
                            label="Currency"
                            name="currency"
                          >
                            <Select
                              placeholder="Select Currency"
                              optionFilterProp="children"
                            >
                              {currencyList !== null ? currencyList : ''}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span="12">
                          <Form.Item
                            label="Salutation (Primary Contact)"
                            name="salutation"
                          >
                            <Select
                              placeholder="Select Salutation"
                              optionFilterProp="children"
                            >
                              <Option value="Mr.">Mr.</Option>
                              <Option value="Mrs.">Mrs.</Option>
                              <Option value="Miss">Miss</Option>
                              <Option value="M/s">M/s.</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span="12">
                          <Form.Item
                            label="First Name"
                            name="first_name"
                          >
                            <Input placeholder="Enter First Name..." />
                          </Form.Item>
                        </Col>
                        <Col span="12">
                          <Form.Item
                            label="Last Name"
                            name="last_name"
                          >
                            <Input placeholder="Enter Last Name..." />
                          </Form.Item>
                        </Col>

                        <Col span="12">
                          <Form.Item label="Company Name" name="company_name">
                            <Input placeholder="Enter Company Name..." />
                          </Form.Item>
                        </Col>

                        <Col span="12">
                          <Form.Item
                            label="Customer Display Name"
                            name="display_name"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter Customer Display Name',
                              },
                            ]}
                          >
                            <Input placeholder="Enter customer display..." />
                          </Form.Item>
                        </Col>

                        <Col span="12">
                          <Form.Item
                            label="Customer Email"
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter Customer Email',
                              },
                            ]}
                          >
                            <Input placeholder="Enter Customer Email..." />
                          </Form.Item>
                        </Col>

                        <Col span="12">
                          <Form.Item
                            label="Customer Phone"
                            name="phone"
                          >
                            <Input placeholder="Enter Customer Phone..." />
                          </Form.Item>
                        </Col>

                        <Col span="12">
                          <Form.Item label="Designation" name="designation">
                            <Input placeholder="Enter Designation..." />
                          </Form.Item>
                        </Col>

                        <Col span="12">
                          <Form.Item label="Department" name="department">
                            <Input placeholder="Enter Department..." />
                          </Form.Item>
                        </Col>

                        <Col span="12">
                          <Form.Item label="Website" name="website">
                            <Input placeholder="Enter Website Name ..." />
                          </Form.Item>
                        </Col>

                        <Col span="12">
                          <Form.Item
                            label="GST Treatment"
                            name="gst_treatment"
                          >
                            <Select
                              showSearch
                              placeholder="Select GST Treatment"
                              optionFilterProp="children"
                            >
                              <Option value="Unregistered">
                                Unregistered
                              </Option>
                              <Option value="Registered">
                                Registered
                              </Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span="12">
                          <Form.Item label="GST#" name="gstin">
                            <Input placeholder="Enter GST Number..." />
                          </Form.Item>
                        </Col>

                        <Col span="12">
                          <Form.Item
                            label="Payment Terms"
                            name="payment_terms"
                            rules={[
                              {
                                required: true,
                                message: 'Please selet Payment Terms',
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Select Payment Terms "
                              optionFilterProp="children"
                              filterOption={(input, option) => option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0}
                            >
                              <Option value="Immediate">Immediate</Option>
                              <Option value="Net 15">Net 15</Option>
                              <Option value="Net 30">Net 30</Option>
                              <Option value="Net 60">Net 60</Option>
                              <Option value="Net 90">Net 90</Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span="12">
                          <Form.Item
                            label="Opening Balance"
                            name="opening_balance"
                            initialValue={
                              state.editPageName === 'addCustomer' ? state.editFormData.opening_balance : 0
                            }
                          >
                            <Input placeholder="Enter Opening Balance ..." />
                          </Form.Item>
                        </Col>

                        <Col span="12">
                          <Form.Item
                            label="Tax Preference"
                            name="tax_preference"
                            rules={[
                              {
                                required: true,
                                message: 'Please select Tax Preference',
                              },
                            ]}
                          >
                            <Radio.Group value="Taxable">
                              <Radio value="Taxable">Taxable</Radio>
                              <Radio value="Tax Exempt">Tax Exempt</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>

                        <Col span="12">
                          <Form.Item
                            label="Customer Status"
                            name="status"
                            rules={[
                              {
                                required: true,
                                message: 'Please select Customer Status',
                              },
                            ]}
                            initialValue="Active"
                          >
                            <Radio.Group>
                              <Radio value="Active">Active</Radio>
                              <Radio value="Inactive">Inactive</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>

                        <Col span="24" className="my-1 py-2 border-top">
                          <Button
                            type="primary"
                            size="large"
                            className="me-1 btn-success px-4"
                            htmlType="button"
                            onClick={() => setTabActiveValue('address')}
                          >
                            Proceed to Address
                          </Button>
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tab="Address" key="address">
                      <Row gutter="30">
                        <Col span="12">
                          <Form.Item label="Country" name="country">
                            <Select
                              showSearch
                              placeholder="Select Currency"
                              optionFilterProp="children"
                              filterOption={(input, option) => option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0}
                            >
                              {countryNameList}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span="12">
                          <Form.Item label="Address 1" name="line1">
                            <Input placeholder="Enter Address..." />
                          </Form.Item>
                        </Col>

                        <Col span="12">
                          <Form.Item label="Address 2" name="line2">
                            <Input placeholder="Enter Address..." />
                          </Form.Item>
                        </Col>

                        <Col span="12">
                          <Form.Item label="City" name="city">
                            <Select
                              showSearch
                              placeholder="Select City "
                              optionFilterProp="children"
                              filterOption={(input, option) => option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0}
                            >
                              {citiesList}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span="12">
                          <Form.Item label="State" name="state">
                            <Select
                              showSearch
                              placeholder="Select State "
                              optionFilterProp="children"
                              filterOption={(input, option) => option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0}
                            >
                              {statesList}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span="12">
                          <Form.Item label="Zip Code" name="postal_code">
                            <Input placeholder="Enter Zip Code..." />
                          </Form.Item>
                        </Col>

                        {/* <Col span="12">
                          <Form.Item label="Phone" name="phone">
                            <Input placeholder="Enter Phone..." />
                          </Form.Item>
                        </Col>

                        <Col span="12">
                          <Form.Item label="Fax" name="fax">
                            <Input placeholder="Enter Fax..." />
                          </Form.Item>
                        </Col> */}
                        <Col span="24" className="my-1 py-2 border-top">
                          <Button
                            type="primary"
                            size="large"
                            className="me-1 btn-success px-4"
                            htmlType="button"
                            onClick={() => setTabActiveValue('contacts')}
                          >
                            Proceed to Add Contacts
                          </Button>
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tab="Contacts" key="contacts">
                      <Row gutter="30">
                        <Col span="24">
                          <Row gutter="30">
                            <Col span="24">
                              <Form.List
                                name="contacts_list"
                              >
                                {(fields, { add, remove }) => (
                                  <>
                                    <Row>
                                      <Col span="24" className="text-end mb-2">
                                        { fields.length < 4 ? (
                                          <Button type="ghost" onClick={() => add()}>
                                            <IoAdd />
                                            {' '}
                                            Add New Contact
                                          </Button>
                                        ) : ''}
                                      </Col>
                                    </Row>
                                    {fields.map(({
                                      key, name, fieldKey,
                                    }) => (
                                      state.editPageName !== 'addSupplier'
                                        ? (
                                          <Row key={key} gutter="30" align="bottom">
                                            <Col span="4">
                                              <Form.Item
                                                label="Contact Type"
                                                name={[name, 'role']}
                                                fieldKey={[fieldKey, 'contact_type']}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Please Select Contact Type',
                                                  },
                                                ]}
                                              >
                                                <Select
                                                  showSearch
                                                  mode="multiple"
                                                  placeholder="Select Contact type"
                                                  optionFilterProp="children"
                                                >
                                                  <Option value="Owner">Owner</Option>
                                                  <Option value="Admin">Admin</Option>
                                                  <Option value="Billing">Billing</Option>
                                                  <Option value="Tech">
                                                    Tech
                                                  </Option>
                                                  <Option value="Emergency">
                                                    Emergency
                                                  </Option>
                                                </Select>
                                              </Form.Item>
                                            </Col>
                                            <Col span="4">
                                              <Form.Item
                                                label="First Name"
                                                name={[name, 'first_name']}
                                                fieldKey={[fieldKey, 'first_name']}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Please Enter First Name',
                                                  },
                                                ]}
                                              >
                                                <Input placeholder="Enter First Name..." />
                                              </Form.Item>
                                            </Col>
                                            <Col span="4">
                                              <Form.Item
                                                label="Last Name"
                                                name={[name, 'last_name']}
                                                fieldKey={[fieldKey, 'last_name']}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Please Enter Last Name',
                                                  },
                                                ]}
                                              >
                                                <Input placeholder="Enter Last Name..." />
                                              </Form.Item>
                                            </Col>

                                            <Col span="5">
                                              <Form.Item
                                                label="Email"
                                                name={[name, 'email']}
                                                fieldKey={[fieldKey, 'email']}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Please Enter Email Id',
                                                  },
                                                ]}
                                              >
                                                <Input placeholder="Enter Email..." />
                                              </Form.Item>
                                            </Col>

                                            <Col span="5">
                                              <Form.Item
                                                label="Phone"
                                                name={[name, 'phone']}
                                                fieldKey={[fieldKey, 'phone']}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: 'Please Enter Phone Number',
                                                  },
                                                ]}
                                              >
                                                <Input placeholder="Enter Phone..." />
                                              </Form.Item>
                                            </Col>
                                            <Col span="2">
                                              <Button
                                                type="default"
                                                className="btn-outline-danger mb-2"
                                                onClick={() => remove(name)}
                                              >
                                                <IoTrash />
                                              </Button>
                                            </Col>
                                          </Row>
                                        ) : (
                                          <Row key={key} gutter="30" align="bottom">
                                            <Col span="4">
                                              <Form.Item
                                                label="Contact Type"
                                                name={[name, 'contact_type']}
                                                fieldKey={[fieldKey, 'contact_type']}
                                              >
                                                <Select
                                                  showSearch
                                                  placeholder="Select Contact type"
                                                  optionFilterProp="children"
                                                >
                                                  <Option value="Owner">Owner</Option>
                                                  <Option value="Admin">Admin</Option>
                                                  <Option value="Billing">Billing</Option>
                                                  <Option value="Tech">
                                                    Tech
                                                  </Option>
                                                </Select>
                                              </Form.Item>
                                            </Col>
                                            <Col span="4">
                                              <Form.Item
                                                label="First Name"
                                                name={[name, 'first_name']}
                                                fieldKey={[fieldKey, 'first_name']}
                                              >
                                                <Input placeholder="Enter First Name..." />
                                              </Form.Item>
                                            </Col>
                                            <Col span="4">
                                              <Form.Item
                                                label="Last Name"
                                                name={[name, 'last_name']}
                                                fieldKey={[fieldKey, 'last_name']}
                                              >
                                                <Input placeholder="Enter Last Name..." />
                                              </Form.Item>
                                            </Col>

                                            <Col span="5">
                                              <Form.Item
                                                label="Email"
                                                name={[name, 'email']}
                                                fieldKey={[fieldKey, 'email']}
                                              >
                                                <Input placeholder="Enter Email..." />
                                              </Form.Item>
                                            </Col>

                                            <Col span="5">
                                              <Form.Item
                                                label="Phone"
                                                name={[name, 'phone']}
                                                fieldKey={[fieldKey, 'phone']}
                                              >
                                                <Input placeholder="Enter Phone..." />
                                              </Form.Item>
                                            </Col>
                                            <Col span="2">
                                              <Button
                                                type="default"
                                                className="btn-outline-danger mb-2"
                                                onClick={() => remove(name)}
                                              >
                                                <IoTrash />
                                              </Button>
                                            </Col>
                                          </Row>
                                        )
                                    ))}
                                  </>
                                )}
                              </Form.List>
                            </Col>
                          </Row>
                        </Col>
                        <Col span="24" className="my-1 py-2 border-top">
                          <Button
                            type="primary"
                            size="large"
                            className="me-1 btn-success px-4"
                            htmlType="button"
                            onClick={() => setTabActiveValue('others')}
                          >
                            Proceed to Others
                          </Button>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tab="Others" key="others">
                      <Row gutter="30">
                        <Col span="12">
                          <Form.Item
                            label="Role"
                            name="role"
                            initialValue="Customer"
                          >
                            <Radio.Group value="Customer" disabled>
                              <Radio value="Customer">Customer</Radio>
                              <Radio value="Account">Account</Radio>
                              <Radio value="Group-Account">Group-Account</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col span="12">
                          <Form.Item
                            label="KYC Verified"
                            name="kyc_verified"
                          >
                            <Select
                              showSearch
                              placeholder="Select Payment Terms "
                              optionFilterProp="children"
                              filterOption={(input, option) => option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0}
                            >
                              <Option value="not-verified">Not Verified</Option>
                              <Option value="verified">Verified</Option>
                              <Option value="rejected">Rejected</Option>
                              <Option value="ban">Banned</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span="12">
                          <Form.Item
                            label="Send Login Notification"
                            name="login_notify"
                            initialValue="No"
                          >
                            <Radio.Group value="No" disabled>
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="No">No</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col span="12">
                          <Form.Item
                            label="Enable 2FA"
                            name="enable_2fa"
                            initialValue="No"
                          >
                            <Radio.Group value="No" disabled>
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="No">No</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>

                        <Col span="24" className="my-1 py-2 border-top">
                          <Button
                            type="primary"
                            size="large"
                            className="me-1 btn-success px-4"
                            htmlType="submit"
                          >
                            {
                              state.editPageName === 'addCustomer' ? 'Update Customer' : 'Add Customer'
                            }
                          </Button>
                        </Col>
                      </Row>
                    </TabPane>
                  </Tabs>
                </Col>
              </Col>

            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default AddCustomer;
