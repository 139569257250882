import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Table,
  notification,
  Modal,
  Form,
  Select,
  Switch,
} from 'antd';

import { IoAdd, IoPencil, IoTrash } from 'react-icons/io5';

import AppContext from '../../../context/appContext';
import { BREADCRUMB } from '../../../context/types';
import LoadPage from '../../../common/loadPage';
import { DELETE, GET, PUT } from '../../../common/crud';
import Breadcrumbs from '../../../common/breadcrumbs';

const AllRAIDCards = () => {
  const [state, dispatch] = useContext(AppContext);
  const [data, setData] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateData, setUpdateData] = useState([]);
  const [deleteData, setDeleteData] = useState([]);
  const [batteryChecked, setBatteryChecked] = useState(false);

  const [UpdateForm] = Form.useForm();

  const { Option } = Select;

  const breadcrumb = <Breadcrumbs title="All RAID Cards" />;

  //  Update HDD
  const updateRowData = (record) => {
    console.log('Update record - ', record);
    setUpdateModal(true);
    setUpdateData(record);
    UpdateForm.setFieldsValue({
      ...record,
    });
    setBatteryChecked(record.battery);
  };

  //  Delete HDD
  const deleteRowData = (record) => {
    console.log('Delete record - ', record);
    setDeleteModal(true);
    setDeleteData(record);
  };

  const tableColumns = [
    {
      title: 'Make',
      dataIndex: 'make',
      key: 'make',
      width: 300,
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
      width: 300,
    },
    {
      title: 'Battery',
      dataIndex: 'battery',
      key: 'battery',
      render: (val) => (val ? 'Yes' : 'No'),
      width: 300,
    },
    {
      title: 'Supported Levels',
      dataIndex: 'supported_levels',
      key: 'supported_levels',
      render: (val) => val.join(', '),
      width: 300,
    },
    {
      title: 'Actions',
      width: 300,
      render: (_, record) => (
        <>
          <Button
            type="default"
            className="me-1 btn-outline-secondary"
            onClick={() => updateRowData(record)}
            icon={<IoPencil />}
          />
          <Button
            type="default"
            className="me-1 btn-outline-danger"
            onClick={() => deleteRowData(record)}
            icon={<IoTrash />}
          />
        </>
      ),
    },
  ];

  const fetchData = () => {
    GET('masterdata/raid_cards', state.token).then((res) => {
      if (res.success) {
        setData(res.data.raid_cards);
      } else {
        notification.error({
          message: 'Something went wrong!',
          description: `Got Error with status code ${res.status}`,
        });
      }
    });
  };

  //  Battery status on change
  const batteryOnChange = (value) => {
    setBatteryChecked(value);
  };

  //  Update Submit
  const submitForm = (values) => {
    console.log('Submit Form values - ', values);
    setUpdateModal(false);
    PUT('masterdata/raid_cards', updateData._id, state.token, values).then((res) => {
      if (res.success === true) {
        UpdateForm.resetFields();
        setUpdateData(null);
        notification.success({
          message: 'Successfully Updated!',
          description: 'Data is Successfully Updated!',
        });
        fetchData();
      } else {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  //  Delete Cancel Modal
  const deleteModalCancel = () => {
    setDeleteData(null);
    setDeleteModal(false);
  };

  //  Update Cancel Modal
  const updateCancelModal = () => {
    UpdateForm.resetFields();
    setUpdateData(null);
    setUpdateModal(false);
  };

  //  Delete on Succes
  const deleteSuccess = () => {
    setDeleteModal(false);
    setUpdateModal(false);
    UpdateForm.resetFields();
    DELETE('masterdata/raid_cards', deleteData._id, state.token).then((res) => {
      if (res.success === true) {
        setUpdateData(null);
        setDeleteData(null);
        notification.success({
          message: 'Deleted Successfully!',
          description: 'Data is Successfully Deleted!',
        });
        fetchData();
      } else {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  useEffect(() => {
    fetchData();
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, [dispatch]);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              All RAID Cards
            </Col>
            <Col span="10" flex="row" align="end">
              <Button
                type="primary"
                size="large"
                onClick={() => LoadPage('addRaidCard', dispatch)}
              >
                <IoAdd />
                {' '}
                Add Card
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter="20" className="m-2">
        <Col span="24">
          <Table
            pagination={false}
            columns={tableColumns}
            dataSource={data}
          />
        </Col>
      </Row>

      <Modal
        width={1100}
        footer={false}
        title="Update RAID Cards"
        visible={updateModal}
        onCancel={updateCancelModal}
      >
        <Form
          layout="vertical"
          form={UpdateForm}
          onFinish={submitForm}
        >
          <Row gutter="30" className="flex-direction-column">
            <Col span="12">
              <Form.Item
                label="Make"
                name="make"
                rules={[
                  { required: true, message: 'Enter make' },
                ]}
              >
                <Select
                  showSearch
                  optionLabelProp="children"
                >
                  <Option value="Dell">Dell</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Model"
                name="model"
                rules={[
                  { required: true, message: 'Enter model' },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select model"
                  optionLabelProp="children"
                >
                  <Option value="H200">H200</Option>
                  <Option value="H330">H330</Option>
                  <Option value="H700">H700</Option>
                  <Option value="H710">H710</Option>
                  <Option value="H730">H730</Option>
                  <Option value="H730P">H730P</Option>
                  <Option value="H800">H800</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Battery?"
                name="battery"
                rules={[
                  { required: true, message: 'Enter model' },
                ]}
              >
                <Switch checked={batteryChecked} onChange={batteryOnChange} checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Supported Levels"
                name="supported_levels"
                rules={[
                  { required: true, message: 'Select levels' },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select levels"
                  optionLabelProp="children"
                  mode="tags"
                >
                  <Option value="0">0</Option>
                  <Option value="1">1</Option>
                  <Option value="5">5</Option>
                  <Option value="6">6</Option>
                  <Option value="10">10</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span="24" className="my-1 py-2 border-top">
              <Button type="default" size="large" className="me-1 px-2" onClick={() => updateCancelModal()}>
                Cancel
              </Button>
              <Button
                type="primary"
                size="large"
                className="me-1 btn-success px-4"
                htmlType="submit"
              >
                Update Card
              </Button>
            </Col>
          </Row>
        </Form>

      </Modal>

      <Modal
        title="Confirm if you really want to delete?"
        visible={deleteModal}
        onCancel={deleteModalCancel}
        okText="Delete"
        okButtonProps={{ className: 'btn-danger' }}
        onOk={deleteSuccess}
      >
        <Row>
          <Col span="24">
            <p>
              <span style={{ fontSize: '20px', fontWeight: 500, color: 'var(--bs-danger)' }}>
                {deleteData ? `${deleteData.make} / ${deleteData.model}` : ''}
              </span>
            </p>
            <p>
              Do you really want to
              delete above RAM?
            </p>
            <p />
          </Col>
        </Row>
      </Modal>

    </>
  );
};

export default AllRAIDCards;
