import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Input,
  Button,
  Breadcrumb,
  Form,
  Select,
  Switch,
  Radio,
  notification,
  Alert,
  //  message,
} from 'antd';
//  import { IoHelpCircle } from 'react-icons/io5';

//  Custom imports
import AppContext from '../../../context/appContext';
import countries from '../../../assets/json/countries.json';
import timezone from '../../../assets/json/timezones.json';
import { BREADCRUMB } from '../../../context/types';
import { POST } from '../../../common/crud';
import LoadPage from '../../../common/loadPage';
import Currencies from '../../../assets/json/country-currencies.json';

const AddLocation = () => {
  const [taxIncluded, setTaxIncluded] = useState(true);
  const [marketClass, setMarketClass] = useState('');
  //  const [currencyList, setCurrencyList] = useState([]);
  const [errors, setErrors] = useState();

  const [state, dispatch] = useContext(AppContext);

  const { Option } = Select;

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          //  onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>All Locations</Breadcrumb.Item>
      <Breadcrumb.Item>Add Location</Breadcrumb.Item>
    </Breadcrumb>
  );

  // Create Location SUCCESS
  const createNewLocationSuccess = (values) => {
    console.log('Form submit success - ', values);

    const locationBody = {
      name: values.locationName,
      address: values.locationAddress,
      code: values.locationCode,
      currency: values.locationCurrency,
      marketing_class: values.locationMarketingClass,
      tax_included: taxIncluded,
      tax_rate: values.tax_rate ? Number(values.tax_rate) : 0,
      timezone: values.locationTimezone,
      location_url: values.locationColonyURL,
      pxe_url: values.locationPXEWorkURL,
      vlan_range: values.locationVlanRange,
      base_currency: values.base_currency.split('-')[1].trim(),
      base_currency_symbol: values.base_currency.split('-')[0],
      gateway: values.paymentGateway,
    };

    console.log('Add Location body - ', locationBody);

    POST('masterdata/locations', state.token, locationBody).then((res) => {
      console.log('Add Location res -', res);
      if (res.success === true) {
        LoadPage('allLocations', dispatch);
        notification.success({
          message: 'Successfully Added!',
          description: 'Data is Successfully Added!',
        });
      } else {
        console.log('Errors - ', res.errors);
        const errorsList = (
          <ul className="mb-0">
            {
              res.errors.detail.map((resErr) => (
                <li>{resErr[1] - resErr.msg}</li>
              ))
            }
          </ul>
        );
        setErrors(errorsList);
        notification.error({
          message: 'Something went wrong',
          description: `Got error with status code ${res.errors}`,
        });
      }
    });
  };

  // Currency onChange
  const currencyOnChange = (value) => {
    console.log('Currency selected on change - ', value);
  };

  // Countries Short Code
  const countryCode = countries.map((country) => (
    <Option value={country.isoCode} key={country.isoCode}>{country.isoCode}</Option>
  ));

  // Countries Short Code
  const timeZoneList = timezone.map((timezoneItem) => (
    <Option value={`${timezoneItem.abbr}-${timezoneItem.value}`} key={`${timezoneItem.abbr}${timezoneItem.value}`}>{`${timezoneItem.abbr}-${timezoneItem.value}`}</Option>
  ));

  // Location Tax onChange
  const taxIncludedOnChange = (checked) => {
    setTaxIncluded(checked);
  };

  //  Location Marketing Class onChange
  const locationMarketingOnChange = (values) => {
    setMarketClass(values);
  };

  //  Breadcrumb Dispatch Action
  const breadcrumb = () => {
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });
  };

  //  Get currencies
  /* const getCurrencies = async () => {
    await fetch(`${state.apiUrl}/masterdata/currencies`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authentication: `Bearer ${state.token}`,
      },
    }).then((res) => res.json()).then((res) => {
      console.log('First res - ', res);
      const tableData = [];
      res.map((mapData) => {
        const newData = {
          key: mapData._id,
          name: mapData.name,
          symbol: mapData.symbol,
          countryName: mapData.country,
          paymentGateway: mapData.pg,
          actions: mapData._id,
        };
        return tableData.push(newData);
      });
      setCurrencyList(tableData);
      console.log('Table data - ', tableData);
    });
  }; */

  useEffect(() => {
    breadcrumb();
    //  getCurrencies();

    return () => {

    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Add Location
            </Col>
            <Col span="10" flex="row" align="end" />
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        {
         errors ? (
           <Alert
             description={errors}
             type="error"
             className="mb-2"
           />
         ) : ''
       }
        <Col span="24">
          <Form
            name="basic"
            layout="vertical"
            onFinish={createNewLocationSuccess}
            autoComplete="off"
            initialValues={{
              locationName: '',
              locationAddress: '',
              locationCode: '',
              locationCurrency: [],
              locationMarketingClass: '',
              locationTax: taxIncluded,
              externalTax: '',
            }}
          >
            <Row gutter="30" className="flex-direction-column">
              <Col span="12">
                <Form.Item
                  label="Location Name"
                  name="locationName"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Location Name',
                    },
                  ]}
                >
                  <Input placeholder="Enter Location Name..." />
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Location Address"
                  name="locationAddress"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Location Address',
                    },
                  ]}
                  tooltip="Minimum 25 and maximum should be 250 characters"
                >
                  <Input minLength={25} maxLength={250} placeholder="Enter Location Address..." />
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Country Code"
                  name="locationCode"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Country Code',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select Country Code"
                    optionFilterProp="children"
                    onChange={currencyOnChange}
                    filterOption={(input, option) => option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0}
                  >
                    {countryCode}
                  </Select>
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Location Currency"
                  name="locationCurrency"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Location Currency',
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select Currency"
                    optionFilterProp="children"
                    onChange={currencyOnChange}
                  >
                    {Currencies.length > 0 ? Currencies.map((item) => (
                      <Option key={item.id} value={`${item.currency.code}`}>
                        {item.currency.symbol}
                        {' '}
                        -
                        {' '}
                        {item.currency.code}
                      </Option>
                    )) : null }
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Payment Gateway"
                  name="paymentGateway"
                  rules={[
                    {
                      required: true,
                      message: 'Select Payment Gateway',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    mode="multiple"
                    placeholder="Select Payment Gateway"
                    style={{ width: '100%' }}
                    filterOption={(input, option) => option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value="Razorpay">Razorpay</Option>
                    <Option value="Paypal">Paypal</Option>
                    <Option value="Stripe">Stripe</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Base Currency"
                  name="base_currency"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Base Currency',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select Currency"
                    optionFilterProp="children"

                  >
                    {Currencies.length > 0 ? Currencies.map((item) => (
                      <Option key={item.key} value={`${item.currency.symbol} - ${item.currency.code}`}>
                        {item.currency.symbol}
                        {' '}
                        -
                        {' '}
                        {item.currency.code}
                      </Option>
                    )) : null }
                  </Select>
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Location Marketing Class"
                  name="locationMarketingClass"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Marketing class',
                    },
                  ]}
                >
                  <Radio.Group value={marketClass} onChange={locationMarketingOnChange}>
                    <Radio value="enterprise">Enterprise</Radio>
                    <Radio value="standard">Standard</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col span="12">
                <Row>
                  <Col span="12">
                    <Form.Item
                      label="Location Tax Included in Price"
                      name="locationTax"
                    >
                      <Switch
                        onChange={taxIncludedOnChange}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        checked={taxIncluded}
                      />
                    </Form.Item>
                  </Col>

                  {!taxIncluded ? (
                    <Col span="12">
                      <Form.Item
                        label="Please Enter Tax Percentage"
                        name="tax_rate"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter Tax Percentage',
                          },
                        ]}
                      >
                        <Input
                          addonAfter="%"
                          placeholder="Please enter Tax Percentage..."
                        />
                      </Form.Item>
                    </Col>
                  ) : null}
                </Row>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Location Timezone"
                  name="locationTimezone"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Location Timezone',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select Timezone"
                    optionFilterProp="children"
                    onChange={currencyOnChange}
                  >
                    {timeZoneList}
                  </Select>
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Location Colony URL"
                  name="locationColonyURL"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Location Colony URL',
                    },
                  ]}
                >
                  <Input placeholder="Location Colony URL..." />
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Location PXE Work URL"
                  name="locationPXEWorkURL"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Location PXE Work URL',
                    },
                  ]}
                >
                  <Input placeholder="Location PXE Work URL..." />
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Location VLAN Range"
                  name="locationVlanRange"
                  rules={[
                    {
                      required: true,
                      message: 'Please select Location VLAN Range',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select Location VLAN Range"
                    optionFilterProp="children"
                    onChange={currencyOnChange}
                    filterOption={(input, option) => option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value="0-100">0-100</Option>
                    <Option value="101-200">101-200</Option>
                    <Option value="201-300">201-300</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span="24" className="my-1 py-2 border-top">
                <Button type="default" size="large" className="me-1 px-2">
                  Cancel
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="me-1 btn-success px-4"
                  htmlType="submit"
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default AddLocation;
