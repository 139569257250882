import React, { useContext, useState, useEffect } from 'react';
import {
  Row, Col, Form,
  Breadcrumb,
  Modal,
  notification,
  Table,
  Button,
  Tag,
  Select,
  Input,
  Switch,
} from 'antd';

// Custom imports
import { IoAdd, IoPencil, IoTrash } from 'react-icons/io5';
import AppContext from '../../context/appContext';
import { BREADCRUMB } from '../../context/types';
import LoadPage from '../../common/loadPage';
import {
  DELETE, GET, POST, PUT,
} from '../../common/crud';

const CurrencyExchangeRates = () => {
  const [data, setData] = useState(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateData, setUpdateData] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [currencyList, setCurrencyList] = useState();
  const [baseCurrencySymbol, setBaseCurrencySymbol] = useState();
  const [pairCurrencySymbol, setPairCurrencySymbol] = useState();
  const [statusValue, setStatusValue] = useState(true);
  const [currencyData, setCurrencyData] = useState(null);

  const { Option } = Select;
  const [CurrentExchangeForm] = Form.useForm();
  const [state, dispatch] = useContext(AppContext);

  //  Update Row Data
  const updateRowData = (record) => {
    console.log('Update Row Data - ', record);
    setUpdateData(record);
    setUpdateModal(true);
    setStatusValue(record.status);
    const baseCurrency = currencyData
      .filter((filData) => filData.name === record.base_curreny).map((res) => {
        const bc = `${res.symbol}-${res.name}`;
        setBaseCurrencySymbol(res.symbol);
        console.log('BC - ', bc);
        return bc;
      });

    const pairCurrency = currencyData
      .filter((filData) => filData.name === record.pair_currency).map((res) => {
        const bc = `${res.symbol}-${res.name}`;
        setPairCurrencySymbol(res.symbol);
        console.log('BC - ', bc);
        return bc;
      });

    console.log('baseCurrency - ', baseCurrency);
    CurrentExchangeForm.setFieldsValue({
      ...record,
      base_curreny: baseCurrency.toString(),
      pair_currency: pairCurrency.toString(),
    });
  };

  //  Delete Row Data
  const deleteRowData = (id, record) => {
    console.log('Delete Row Data - ', id, record);
    setDeleteId(record);
    setDeleteModal(true);
  };

  // Table Columns
  const currencyExchangeCols = [
    {
      title: 'Base Currency',
      dataIndex: 'base_curreny',
      key: 'base_curreny',
    },
    {
      title: 'Base Rate',
      dataIndex: 'base_rate',
      key: 'base_rate',
    },
    {
      title: 'Pair Currency',
      dataIndex: 'pair_currency',
      key: 'pair_currency',
    },
    {
      title: 'Pair Rate',
      dataIndex: 'pair_rate',
      key: 'pair_rate',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (text) => (
        <>
          {text ? (
            <Tag color="green">{`${text}`}</Tag>
          ) : (
            <Tag color="red">{`${text}`}</Tag>
          )}
        </>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      width: 120,
      render: (id, record) => (
        <>
          <Button
            type="default"
            className="me-1 btn-outline-secondary"
            onClick={() => updateRowData(record)}
            icon={<IoPencil />}
          />
          <Button
            type="default"
            className="me-1 btn-outline-danger"
            onClick={() => deleteRowData(id, record)}
            icon={<IoTrash />}
          />
        </>
      ),
    },
  ];

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>

      <Breadcrumb.Item>All Currency Exchange Rates</Breadcrumb.Item>
    </Breadcrumb>
  );

  const tableData = () => {
    GET('rates', state.token).then((res) => {
      //  setLoading(false);
      console.log('Get res - ', res);
      if (res.success === true) {
        setData(res.data.rates);
      } else {
        notification.success({
          message: 'Something Wrong!',
          description: 'Something went wrong! Please try again!',
        });
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  };

  // Close Update Modal
  const handleCancel = () => {
    setUpdateModal(false);
    setUpdateData(null);
    CurrentExchangeForm.resetFields();
  };

  // Cancel Delete Modal
  const cancelModal = () => {
    setDeleteModal(false);
    setDeleteId(null);
  };

  //  Add Currency Exchange
  const addCurrencyExchange = () => {
    CurrentExchangeForm.resetFields();
    setUpdateModal(true);
  };

  //  Delete Data
  const deleteSuccess = () => {
    console.log('Delete id - ', deleteId);
    setUpdateModal(false);
    setDeleteModal(false);
    DELETE('rates', deleteId._id, state.token).then((res) => {
      setUpdateData(null);
      setDeleteId(null);
      console.log('After delete res - ', res);
      if (res.success === true) {
        notification.success({
          message: 'Deleted Successfully!',
          description: 'Data is Successfully Deleted!',
        });
        CurrentExchangeForm.resetFields();
        tableData();
      } else {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  //  Base Currency on Change
  const baseCurrencyOnChange = (value) => {
    setBaseCurrencySymbol(value.split('-')[0]);
  };

  //  Pair Currency on Change
  const pairCurrencyOnChange = (value) => {
    setPairCurrencySymbol(value.split('-')[0]);
  };

  //  Get Currencies
  const getCurrency = async () => {
    GET('masterdata/locations', state.token).then(async (res) => {
      //  setLoading(false);
      console.log('Get Currency res - ', res);
      if (res.success === true) {
        const currencyListArray = (
          await res.data.location.map((currencyVal) => (
            <Option key={currencyVal._id} value={`${currencyVal.base_currency_symbol}-${currencyVal.base_currency}`}>{`${currencyVal.base_currency_symbol}-${currencyVal.base_currency}`}</Option>
          ))
        );
        console.log('Currency list after adding - ', currencyListArray);
        setCurrencyData(res.data);
        setCurrencyList(currencyListArray);
      } else if (res.success === false) {
        notification.error({
          message: 'Something Went Wrong!',
          description: 'Sorry we did not got Locations list from master data!!!',
        });
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  };

  //  Currency Exchange on Success
  const createCurrencyExchangeSuccess = (values) => {
    console.log('Create success values - ', values);
    setUpdateModal(false);

    const CurrencyExchangeBody = {
      location: '',
      base_curreny: values.base_curreny.split('-')[1],
      pair_currency: values.pair_currency.split('-')[1],
      base_rate: values.base_rate,
      pair_rate: values.pair_rate,
      status: statusValue,
    };

    console.log('Create/Update body - ', CurrencyExchangeBody);

    if (updateData) {
      PUT('rates', updateData._id, state.token, CurrencyExchangeBody).then((res) => {
        if (res.success === true) {
          notification.success({
            message: 'Successfully Added!',
            description: 'Data is Successfully Added!',
          });
          tableData();
          setUpdateData(null);
          CurrentExchangeForm.resetFields();
        } else {
          notification.error({
            message: 'Something Went Wrong!',
            description: `Got Error with status code = ${res.status}!`,
          });
          tableData();
          setUpdateData(null);
          CurrentExchangeForm.resetFields();
        }
      });
    } else {
      POST('rates', state.token, CurrencyExchangeBody).then((res) => {
        if (res.success === true) {
          notification.success({
            message: 'Successfully Added!',
            description: 'Data is Successfully Added!',
          });
          setUpdateData(null);
          tableData();
        } else {
          notification.error({
            message: 'Something Went Wrong!',
            description: `Got Error with status code = ${res.status}!`,
          });
          tableData();
          setUpdateData(null);
        }
      });
    }
  };

  //  Sttus on Change
  const statusOnChange = (value) => {
    console.log('Status on change - ', value);
    setStatusValue(value);
  };

  useEffect(() => {
    tableData();
    getCurrency();
    console.log('Update Data - ', updateData);

    console.log('Data - ', data);

    // Bredcrumb
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Currency Exchange Rates
            </Col>
            <Col span="10" flex="row" align="end" className="text-end">
              <Button
                type="primary"
                onClick={() => addCurrencyExchange()}
              >
                <IoAdd />
                {' '}
                Add Discount
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter="20" className="m-2">
        <Col span="24">
          <Table columns={currencyExchangeCols} dataSource={data} />
        </Col>
      </Row>

      {/* Update Modal */}
      <Modal
        width={800}
        footer={false}
        title={updateData ? 'Update Currency Exchange Rates' : 'Add Currency Exchange Rates'}
        visible={updateModal}
        onCancel={handleCancel}
        maskClosable={false}
      >
        <Form
          form={CurrentExchangeForm}
          name="basic"
          layout="vertical"
          onFinish={createCurrencyExchangeSuccess}
          autoComplete="off"
        >
          <Row gutter="30" className="flex-direction-column">
            <Col span="12">
              <Form.Item
                label="Base Currency"
                name="base_curreny"
                rules={[
                  {
                    required: true,
                    message: 'Select Base Currency',
                  },
                ]}
              >
                <Select
                  placeholder="Select Base Currency"
                  optionFilterProp="children"
                  className="w-100"
                  onChange={baseCurrencyOnChange}
                >
                  {currencyList}
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Pair Currency"
                name="pair_currency"
                rules={[
                  {
                    required: true,
                    message: 'Select Pair Currency',
                  },
                ]}
              >
                <Select
                  placeholder="Select Pair Currency"
                  optionFilterProp="children"
                  className="w-100"
                  onChange={pairCurrencyOnChange}
                >
                  {currencyList}
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Base Rate"
                name="base_rate"
                rules={[
                  {
                    required: true,
                    message: 'Select Base Rate',
                  },
                ]}
                initialValue={1}
              >
                <Input prefix={baseCurrencySymbol} className="w-100" placeholder="Enter Base Rate Number" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Pair Rate"
                name="pair_rate"
                rules={[
                  {
                    required: true,
                    message: 'Select Pair Rate',
                  },
                ]}
              >
                <Input prefix={pairCurrencySymbol} className="w-100" placeholder="Enter Pair Rate Number" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Status"
                name="status"
                rules={[
                  {
                    required: true,
                    message: 'Select Pair Rate',
                  },
                ]}
                initialValue={statusValue}
              >
                <Switch checkedChildren="active" unCheckedChildren="inactive" onChange={statusOnChange} checked={statusValue} />
              </Form.Item>
            </Col>

            <Col span="24" className="my-1 py-2 border-top">
              <Button type="default" size="large" className="me-1 px-2" onClick={() => setUpdateModal(false)}>
                Cancel
              </Button>
              <Button
                type="primary"
                size="large"
                className="me-1 btn-success px-4"
                htmlType="submit"
              >
                {updateData ? 'Update' : 'Add'}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="Confirm if you really want to delete?"
        visible={deleteModal}
        onCancel={cancelModal}
        okText="Delete"
        okButtonProps={{ className: 'btn-danger' }}
        onOk={deleteSuccess}
      >
        <Row>
          <Col span="24">
            <p>
              <span style={{ fontSize: '20px', fontWeight: 500, color: 'var(--bs-danger)' }}>
                {deleteId ? `${deleteId.base_curreny}-${deleteId.base_rate} = ${deleteId.pair_currency} - ${deleteId.pair_rate}` : ''}
              </span>
            </p>
            <p>
              Do you really want to
              delete above Exchange Conversion?
            </p>
            <p />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default CurrencyExchangeRates;
