import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Breadcrumb,
  Form,
  Select,
  Input,
  Radio,
  Table,
  Tabs,
  Modal,
  Tag,
  notification,
  Switch,
} from 'antd';

import { IoAdd, IoPencil, IoTrash } from 'react-icons/io5';
import { FaRupeeSign } from 'react-icons/fa';

// Custom imports
import AppContext from '../../context/appContext';
import { BREADCRUMB } from '../../context/types';
import LoadPage from '../../common/loadPage';
import { DELETE, GET, PUT } from '../../common/crud';

const AllAddons = () => {
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [updateData, setUpdateData] = useState([]);
  //  const [updateId, setUpdateId] = useState();
  //  const [categoryFilterValue, setCategoryFilterValue] = useState('os');
  const [categoryValue, setCategoryValue] = useState('');
  //  const [architectureValue, setArchitectureValue] = useState('x86_64');
  const [osTypeValue, setOsTypeValue] = useState('');
  const [osBrandValue, setOsBrandValue] = useState('');
  const [ramSizeValue, setRamSizeValue] = useState('');
  const [osData, setOsData] = useState(null);
  const [ramData, setRamData] = useState(null);
  const [hddData, setHddData] = useState(null);
  const [bandwidthData, setBandwidthData] = useState(null);
  const [ipaddressData, setIpaddressData] = useState(null);
  const [raidData, setRaidData] = useState(null);
  const [portsspeedData, setPortsspeedData] = useState(null);
  const [statusToggle, setstatusToggle] = useState(false);

  const [state, dispatch] = useContext(AppContext);
  const { Option } = Select;
  const { TabPane } = Tabs;

  const [UpdateForm] = Form.useForm();

  // Row Data on Edit
  const updateRowData = async (id, record, type) => {
    console.log('Update Row ID - ', id);
    console.log('Update Row Record - ', record);
    UpdateForm.setFieldsValue({
      ...record,
      ram: record.sku_id,
      hdd: record.sku_id,
    });
    setstatusToggle(record.status);
    setUpdateData(record);
    //  setUpdateId(id);
    console.log('Update id - ', id);
    setUpdateModal(true);
    //  Setting values in state
    setCategoryValue(type);
    setOsTypeValue(record.ostype ? record.ostype : '');
    setOsBrandValue(record.brand);
    setRamSizeValue(record.ramsize);
  };

  // Confirm Modal Delete
  const deleteRowData = (values, type) => {
    const delData = {
      ...values,
      category: type,
    };
    console.log('Delete Row data id - ', delData);
    setDeleteModal(true);
    setDeleteId(delData);
  };

  //  OS Cols Status Row
  const statusCol = (value) => {
    console.log('Check status col type - ', typeof value);
    const statusVal = (
      value
        ? <Tag color="green">Active</Tag>
        : <Tag color="red">InActive</Tag>
    );

    return statusVal;
  };

  // Common onChange
  const unitsOnChange = () => {};

  //  Table OS Columns
  const osCols = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
    },
    {
      title: 'OS Type',
      dataIndex: 'ostype',
      key: 'ostype',
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      key: 'brand',
    },
    {
      title: '# of Unit',
      dataIndex: 'num_unit',
      key: 'num_unit',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      className: 'text-end',
      render: (text) => (
        <>
          <FaRupeeSign size="13" color="#666" />
          {' '}
          {text}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '120px',
      render: (text) => (
        statusCol(text)
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      render: (id, record) => (
        <div className="text-center">
          <Button
            type="default"
            icon={<IoPencil color="#777" />}
            onClick={() => updateRowData(id, record, 'os')}
            className="me-1"
          />
          <Button
            type="default"
            className="me-1 btn-outline-danger"
            onClick={() => deleteRowData(record, 'os')}
            icon={<IoTrash />}
          />
        </div>
      ),
    },
  ];

  // Table RAM Columns
  const ramCols = [
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Technology',
      dataIndex: 'technology',
      key: 'technology',
    },
    {
      title: 'Ram Size',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      className: 'text-end',
      render: (text) => (
        <>
          <FaRupeeSign size="14" color="#777" />
          {text}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => (
        statusCol(text)
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      render: (id, record) => (
        <div className="text-center">
          <Button
            type="default"
            icon={<IoPencil color="#777" />}
            onClick={() => updateRowData(id, record, 'ram')}
            className="me-1"
          />
          <Button
            type="default"
            className="me-1 btn-outline-danger"
            onClick={() => deleteRowData(record, 'ram')}
            icon={<IoTrash />}
          />
        </div>
      ),
    },
  ];

  // Table HDD Columns
  const hddCols = [
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Type',
      dataIndex: 'disk_type',
      key: 'disk_type',
    },
    {
      title: 'Form Factor',
      dataIndex: 'form_factor',
      key: 'form_factor',
    },
    {
      title: 'Capacity (GB)',
      dataIndex: 'capacity',
      key: 'capacity',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      className: 'text-end',
      render: (text) => (
        <>
          <FaRupeeSign size="14" color="#777" />
          {text}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => (
        statusCol(text)
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      render: (id, record) => (
        <div className="text-center">
          <Button
            type="default"
            icon={<IoPencil color="#777" />}
            onClick={() => updateRowData(id, record, 'hdd')}
            className="me-1"
          />
          <Button
            type="default"
            className="me-1 btn-outline-danger"
            onClick={() => deleteRowData(record, 'hdd')}
            icon={<IoTrash />}
          />
        </div>
      ),
    },
  ];

  // Table Bandwidth Columns
  const bandwidthCols = [
    {
      title: 'Locations',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Price per GB',
      dataIndex: 'price',
      key: 'price',
      width: '300',
      className: 'text-end',
      render: (text) => (
        <>
          <FaRupeeSign size="14" color="#777" />
          {text}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => (
        statusCol(text)
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      render: (id, record) => (
        <div className="text-center">
          <Button
            type="default"
            icon={<IoPencil color="#777" />}
            onClick={() => updateRowData(id, record, 'bandwidth')}
            className="me-1"
          />
          <Button
            type="default"
            className="me-1 btn-outline-danger"
            onClick={() => deleteRowData(record, 'bandwidth')}
            icon={<IoTrash />}
          />
        </div>
      ),
    },
  ];

  // Table IPAddress Columns
  const ipaddressCols = [
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Price per IP',
      dataIndex: 'price',
      key: 'price',
      width: '300',
      className: 'text-end',
      render: (text) => (
        <>
          <FaRupeeSign size="14" color="#777" />
          {text}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => (
        statusCol(text)
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      render: (id, record) => (
        <div className="text-center">
          <Button
            type="default"
            icon={<IoPencil color="#777" />}
            onClick={() => updateRowData(id, record, 'ipaddress')}
            className="me-1"
          />
          <Button
            type="default"
            className="me-1 btn-outline-danger"
            onClick={() => deleteRowData(record, 'ipaddress')}
            icon={<IoTrash />}
          />
        </div>
      ),
    },
  ];

  // Table RAID Columns
  const raidCols = [
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'RAID',
      dataIndex: 'raid',
      key: 'raid',
      width: '300',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => (
        statusCol(text)
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      render: (id, record) => (
        <div className="text-center">
          <Button
            type="default"
            icon={<IoPencil color="#777" />}
            onClick={() => updateRowData(id, record, 'raid')}
            className="me-1"
          />
          <Button
            type="default"
            className="me-1 btn-outline-danger"
            onClick={() => deleteRowData(record, 'raid')}
            icon={<IoTrash />}
          />
        </div>
      ),
    },
  ];

  // Table RAID Columns
  const portsspeedCols = [
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Ports Speed',
      dataIndex: 'port_speed',
      key: 'port_speed',
      width: '300',
      render: (text) => (
        <>
          {text}
          {' '}
          Mbps
        </>
      ),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      width: '300',
      className: 'text-end',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => (
        statusCol(text)
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      className: 'text-center',
      width: 130,
      render: (id, record) => (
        <div className="text-center">
          <Button
            type="default"
            icon={<IoPencil color="#777" />}
            onClick={() => updateRowData(id, record, 'portspeed')}
            className="me-1"
          />
          <Button
            type="default"
            className="me-1 btn-outline-danger"
            onClick={() => deleteRowData(record, 'portspeed')}
            icon={<IoTrash />}
          />
        </div>
      ),
    },
  ];

  const handleCancel = () => {
    setCategoryValue('');
    setOsTypeValue('');
    setOsBrandValue('');
    setUpdateData('');
    setUpdateModal(false);
  };

  // Cancel Delete Modal
  const cancelModal = () => {
    setDeleteModal(false);
  };

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>

      <Breadcrumb.Item>All Addons</Breadcrumb.Item>
    </Breadcrumb>
  );

  //  Status ON Change
  const statusOnChange = (value) => {
    setstatusToggle(value);
  };

  //  Table Data
  /* const tableData = (selectedCategory) => {
    console.log('Table data got value - ', selectedCategory);
    console.log('getDataValue - ', getDataValue);
    const tableDataArray = [];
    setData(null);
    if (getDataValue && getDataValue.length > 0) {
      let newData;
      switch (selectedCategory) {
        case 'os':
          setOsData(getDataValue.filter((item) => item.category === 'os'));
          getDataValue.filter((item) => item.category === selectedCategory).map((item) => {
            newData = item;
            return tableDataArray.push(item);
          });
          break;

        case 'ram':
          setRamData(getDataValue.filter((item) => item.category === 'ram'));
          getDataValue.filter((item) => item.category === selectedCategory).map((item) => {
            newData = item;
            return tableDataArray.push(item);
          });
          break;

        case 'hdd':
          setHddData(getDataValue.filter((item) => item.category === 'hdd'));
          getDataValue.filter((item) => item.category === selectedCategory).map((item) => {
            newData = item;
            return tableDataArray.push(newData);
          });
          break;

        case 'bandwidth':
          getDataValue.filter((item) => item.category === selectedCategory).map((item) => {
            newData = item;
            return tableDataArray.push(newData);
          });
          break;

        case 'ipaddress':
          getDataValue.filter((item) => item.category === 'ipaddress').map((item) => {
            newData = item;
            return tableDataArray.push(newData);
          });
          break;

        case 'raid':
          getDataValue.filter((item) => item.category === selectedCategory).map((item) => {
            newData = item;
            return tableDataArray.push(newData);
          });
          break;

        default:
          break;
      }
    }
    console.log('Finally Table Data Array - ', tableDataArray);
    return setData(tableDataArray);
  }; */

  //  OS Data
  const getOsData = () => {
    GET('add-ons/os', state.token).then((res) => {
      console.log('Addons OS res - ', res);
      if (res.success === true) {
        setOsData(res.data.addons);
      } else {
        notification.error({
          message: 'Something Wrong!',
          description: `Something went wrong, OS Data failed with status code = ${res.status}`,
        });
      }
    });
  };

  //  HDD Data
  const getHddData = () => {
    GET('add-ons/hdd', state.token).then((res) => {
      console.log('Addons HDD res - ', res);
      if (res.success === true) {
        setHddData(res.data.addons);
      } else {
        notification.error({
          message: 'Something Wrong!',
          description: `Something went wrong, HDD Data failed with status code = ${res.status}`,
        });
      }
    });
  };

  //  RAM Data
  const getRamData = () => {
    GET('add-ons/ram', state.token).then((res) => {
      console.log('Addons RAM res - ', res);
      if (res.success === true) {
        setRamData(res.data.addons);
      } else {
        notification.error({
          message: 'Something Wrong!',
          description: `Something went wrong, HDD Data failed with status code = ${res.status}`,
        });
      }
    });
  };

  //  RAID Data
  const getRaidData = () => {
    GET('add-ons/raid', state.token).then((res) => {
      console.log('Addons RAID res - ', res);
      if (res.success === true) {
        setRaidData(res.data.addons);
      } else {
        notification.error({
          message: 'Something Wrong!',
          description: `Something went wrong, RAID Data failed with status code = ${res.status}`,
        });
      }
    });
  };

  //  IPAddress Data
  const getIpAddressData = () => {
    GET('add-ons/ipaddress', state.token).then((res) => {
      console.log('Addons IPAddress res - ', res);
      if (res.success === true) {
        setIpaddressData(res.data.addons);
      } else {
        notification.error({
          message: 'Something Wrong!',
          description: `Something went wrong, IP Address Data failed with status code = ${res.status}`,
        });
      }
    });
  };

  //  PortsSpeed Data
  const getPortsSpeedData = () => {
    GET('add-ons/portspeed', state.token).then((res) => {
      if (res.success === true) {
        console.log('Addon PORT SPEED res - ', res);
        setPortsspeedData(res.data.addons);
      } else {
        notification.error({
          message: 'Something Wrong!',
          description: `Something went wrong, IP Address Data failed with status code = ${res.status}`,
        });
      }
    });
  };

  //  Bandwidth Data
  const getBandwidthData = () => {
    GET('add-ons/bandwidth', state.token).then((res) => {
      if (res.success === true) {
        setBandwidthData(res.data.addons);
      } else {
        notification.error({
          message: 'Something Wrong!',
          description: `Something went wrong, Bandwidth Data failed with status code = ${res.status}`,
        });
      }
    });
  };

  // OS Type on change
  const osTypeOnchange = (e) => {
    console.log('OS Type onChange - ', e);
    //  setSelectOSBrand('');
    setOsBrandValue('');
    setOsTypeValue(e.target.value);
  };

  // Brand Select on Change
  const osBrandOnChange = (value) => {
    console.log('Os Brand change - ', osBrandValue);
    setOsBrandValue(value);
  };

  /*  //  Filter category on Change
  const filterCategoryOnChange = (value) => {
    console.log('Category filter changed to - ', value);
    setCategoryFilterValue(value);
    let fillData;
    //  Category Filter Switch
    switch (value) {
      case 'os':
        setDataCols(osCols);
        tableData('os');
        break;

      case 'ram':
        setDataCols(ramCols);
        tableData('ram');
        break;

      case 'hdd':
        setDataCols(hddCols);
        tableData('hdd');
        break;

      case 'bandwidth':
        setDataCols(bandwidthCols);
        tableData('bandwidth');
        break;

      case 'ipaddress':
        setDataCols(ipaddressCols);
        tableData('ipaddress');
        break;

      case 'raid':
        setDataCols(raidCols);
        setData(fillData);
        tableData('raid');
        break;

      default:
        break;
    }
  }; */

  /* //  Get Data
  const getData = async (cat = 'os') => {
    setDataCols(null);
    await GET('add-ons', state.token).then((res) => {
      //  setLoading(false);
      console.log('Get res - ', res);
      if (res.success === true) {
        setGetDataValue(res.data.addons);
        const setD = res.data.addons.filter((item) => item.category === cat);
        setData(setD);
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  }; */

  //  Architecture on change
  const architectureOnChange = (value) => {
    console.log('Architecture on change - ', value);
    //  setArchitectureValue(value);
  };

  //  OsFileSystem Switch
  let OsFileSystem;
  switch (osBrandValue) {
    case 'Microsoft':
      OsFileSystem = (
        <Col span="6">
          <Form.Item label="ntfs" name="msFileSystem" initialValue="100">
            <Input
              placeholder="%"
              addonAfter="%"
              disabled
            />
          </Form.Item>
        </Col>
      );
      break;

    case 'RedHat':
      OsFileSystem = (
        <>
          <Col span="4">
            <Form.Item
              label="ext2"
              name="fileSystemLinux1"
              initialValue="10"
            >
              <Input
                type="text"
                placeholder="%"
                addonAfter="%"
                disabled
              />
            </Form.Item>
          </Col>
          <Col span="4">
            <Form.Item
              label="ext3"
              name="fileSystemLinux2"
              initialValue="10"
            >
              <Input
                type="text"
                placeholder="%"
                addonAfter="%"
                disabled
              />
            </Form.Item>
          </Col>
          <Col span="4">
            <Form.Item
              label="ext4"
              name="fileSystemLinux3"
              initialValue="10"
            >
              <Input
                type="text"
                placeholder="%"
                addonAfter="%"
                disabled
              />
            </Form.Item>
          </Col>
          <Col span="4">
            <Form.Item
              label="swap"
              name="fileSystemLinux4"
              initialValue="10"
            >
              <Input
                type="text"
                placeholder="%"
                addonAfter="%"
                disabled
              />
            </Form.Item>
          </Col>
          <Col span="4">
            <Form.Item
              label="xfs"
              name="fileSystemLinux5"
              initialValue="10"
            >
              <Input
                type="text"
                placeholder="%"
                addonAfter="%"
                disabled
              />
            </Form.Item>
          </Col>
          <Col span="4">
            <Form.Item
              label="reiser"
              name="fileSystemLinux6"
              initialValue="10"
            >
              <Input
                type="text"
                placeholder="%"
                addonAfter="%"
                disabled
              />
            </Form.Item>
          </Col>
        </>
      );
      break;

    default:
      break;
  }

  //  Brand Type on Change
  let OSType;
  switch (osTypeValue) {
    case 'enterprise':
      OSType = (
        <>
          <Col span="12">
            <Form.Item
              label="Brand"
              name="brand"
              rules={[
                {
                  required: true,
                  message: 'Select Brand',
                },
              ]}
            >
              <Select
                placeholder="Select Brand"
                onChange={osBrandOnChange}
                style={{ width: '100%' }}
              >
                <Option value="Microsoft">Microsoft</Option>
                <Option value="RedHat">RedHat</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Enter Title',
                },
              ]}
            >
              <Input placeholder="Enter title..." />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Version"
              name="version"
              rules={[
                {
                  required: true,
                  message: 'Enter Title',
                },
              ]}
            >
              <Input placeholder="Enter title..." />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Architecture" name="architecture" initialValue="x86_64">
              <Select
                placeholder="Select Architecture"
                onChange={architectureOnChange}
                style={{ width: '100%' }}
                disabled
              >
                <Option value="x86_64">x86_64</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="# of Unit"
              name="num_unit"
              rules={[
                {
                  required: true,
                  message: 'Select number of of Unit',
                },
              ]}
            >
              <Select
                placeholder="Select Unit"
                onChange={unitsOnChange}
                style={{ width: '100%' }}
              >
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="6">6</Option>
                <Option value="7">7</Option>
                <Option value="8">8</Option>
                <Option value="9">9</Option>
                <Option value="10">10</Option>
                <Option value="12">12</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Price"
              name="price"
              rules={[
                {
                  required: true,
                  message: 'Enter Price',
                },
              ]}
            >
              <Input
                placeholder="Enter price..."
                addonBefore={<FaRupeeSign color="#666" />}
              />
            </Form.Item>
          </Col>
          <Col span="24" style={{ paddingBottom: '8px' }}>
            <strong>File Systems</strong>
          </Col>
          <Col span="24">
            <Row gutter="30">
              {OsFileSystem}
            </Row>
          </Col>
        </>
      );
      break;

    case 'opensource':
      OSType = (
        <>
          <Col span="12">
            <Form.Item
              label="Brand"
              name="brand"
              rules={[
                {
                  required: true,
                  message: 'Select Brand',
                },
              ]}
            >
              <Select
                placeholder="Select Brand"
                onChange={osBrandOnChange}
                style={{ width: '100%' }}
              >
                <Option value="Fedora">Fedora</Option>
                <Option value="Ubuntu">Ubuntu</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Enter Title',
                },
              ]}
            >
              <Input placeholder="Enter title..." />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Version"
              name="version"
              rules={[
                {
                  required: true,
                  message: 'Enter Title',
                },
              ]}
            >
              <Input placeholder="Enter title..." />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Price" name="price">
              <strong>0</strong>
            </Form.Item>
          </Col>
          <Col span="24" style={{ paddingBottom: '8px' }}>
            <strong>File Systems</strong>
          </Col>
          <Col span="24">
            <Row gutter="30">
              <Col span="4">
                <Form.Item label="ext2" name="fileSystemLinux2">
                  <Input
                    type="text"
                    placeholder="%"
                    addonAfter="%"
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span="4">
                <Form.Item label="ext3" name="fileSystemLinux3">
                  <Input
                    type="text"
                    placeholder="%"
                    addonAfter="%"
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span="4">
                <Form.Item label="ext4" name="fileSystemLinux4">
                  <Input
                    type="text"
                    placeholder="%"
                    addonAfter="%"
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span="4">
                <Form.Item label="swap" name="fileSystemLinux5">
                  <Input
                    type="text"
                    placeholder="%"
                    addonAfter="%"
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span="4">
                <Form.Item label="xfs" name="fileSystemLinux6">
                  <Input
                    type="text"
                    placeholder="%"
                    addonAfter="%"
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span="4">
                <Form.Item label="reiser" name="fileSystemLinux7">
                  <Input
                    type="text"
                    placeholder="%"
                    addonAfter="%"
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </>
      );
      break;

    default:
      break;
  }

  //  RAID on Change
  const raidOnChange = (value) => {
    console.log('Raid on change - ', value);
    //  setRaidValue(value);
  };

  //  CategoryValue Form Switch
  let CategoryValueForm;
  switch (categoryValue) {
    case 'os':
      CategoryValueForm = (
        <Row gutter="30">
          <Col span="12">
            <Form.Item
              label="Location"
              name="location"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="OS Type"
              name="ostype"
              initialValue={osTypeValue}
              rules={[
                {
                  required: true,
                  message: 'Select OS Type',
                },
              ]}
            >
              <Radio.Group onChange={osTypeOnchange}>
                <Radio value="enterprise" disabled>Enterprise</Radio>
                <Radio value="opensource" disabled>OpenSource</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          {OSType}
          <Col span="12">
            <Form.Item
              label="Status"
              name="status"
              initialValue="Active"
            >
              <Switch onChange={statusOnChange} checkedChildren="Active" unCheckedChildren="InActive" checked={statusToggle} />
            </Form.Item>
          </Col>
        </Row>
      );
      break;

    case 'ram':
      CategoryValueForm = (

        <Row align="stretch" gutter="30">
          <Col span="20">
            <Form.Item
              label="Location"
              name="location"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="RAM"
              name="ram"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Price"
              name="price"
              rules={[
                {
                  required: true,
                  message: 'Enter Price',
                },
              ]}
            >
              <Input
                placeholder="Enter price..."
                addonBefore={<FaRupeeSign color="#666" />}
              />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Status"
              name="status"
              initialValue="Active"
            >
              <Switch onChange={statusOnChange} checkedChildren="Active" unCheckedChildren="InActive" checked={statusToggle} />
            </Form.Item>
          </Col>
        </Row>
      );
      break;

    case 'hdd':
      CategoryValueForm = (
        <Row gutter="30">
          <Col span="20">
            <Form.Item
              label="Location"
              name="location"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Disk"
              name="hdd"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Price"
              name="price"
              rules={[
                {
                  required: true,
                  message: 'Enter Price',
                },
              ]}
            >
              <Input
                placeholder="Enter price..."
                //  addonBefore={<FaRupeeSign color="#666" />}
              />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Status"
              name="status"
              initialValue="Active"
            >
              <Switch onChange={statusOnChange} checkedChildren="Active" unCheckedChildren="InActive" checked={statusToggle} />
            </Form.Item>
          </Col>
        </Row>
      );
      break;

    case 'bandwidth':
      CategoryValueForm = (
        <Row gutter="30">
          <Col span="20">
            <Form.Item
              label="Location"
              name="location"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Price"
              name="price"
              rules={[
                {
                  required: true,
                  message: 'Enter price',
                },
              ]}
            >
              <Input
                placeholder="Enter Price..."
                //  addonBefore={<FaRupeeSign color="#666" />}
                addonAfter="/GB"
              />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Status"
              name="status"
              initialValue="Active"
            >
              <Switch onChange={statusOnChange} checkedChildren="Active" unCheckedChildren="InActive" checked={statusToggle} />
            </Form.Item>
          </Col>
        </Row>
      );
      break;

    case 'ipaddress':
      CategoryValueForm = (
        <Row gutter="30">
          <Col span="20">
            <Form.Item
              label="Location"
              name="location"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Price"
              name="price"
              rules={[
                {
                  required: true,
                  message: 'Enter price',
                },
              ]}
            >
              <Input
                placeholder="Enter Price..."
                //  addonBefore={<FaRupeeSign color="#666" />}
                addonAfter="/IP"
              />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Status"
              name="status"
              initialValue="Active"
            >
              <Switch onChange={statusOnChange} checkedChildren="Active" unCheckedChildren="InActive" checked={statusToggle} />
            </Form.Item>
          </Col>
        </Row>
      );
      break;

    case 'raid':
      CategoryValueForm = (
        <Row gutter="30">
          <Col span="20">
            <Form.Item
              label="Location"
              name="location"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="RAID"
              name="raid"
              rules={[
                {
                  required: true,
                  message: 'Enter RAID',
                },
              ]}
            >
              <Select
                placeholder="Select Disk Type"
                optionFilterProp="children"
                onChange={raidOnChange}
                filterOption={(input, option) => option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0}
              >
                <Option value="RAID1">RAID1</Option>
                <Option value="RAID5">RAID5</Option>
                <Option value="RAID6">RAID6</Option>
                <Option value="RAID10">RAID10</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Status"
              name="status"
              initialValue="Active"
            >
              <Switch onChange={statusOnChange} checkedChildren="Active" unCheckedChildren="InActive" checked={statusToggle} />
            </Form.Item>
          </Col>
        </Row>
      );
      break;

    case 'portspeed':
      CategoryValueForm = (
        <Row gutter="30">
          <Col span="20">
            <Form.Item
              label="Location"
              name="location"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Port Speed"
              name="port_speed"
              rules={[
                {
                  required: true,
                  message: 'Enter RAID',
                },
              ]}
            >
              <Select
                placeholder="Select Disk Type"
                optionFilterProp="children"
              >
                <Option value="10">10 Mbps</Option>
                <Option value="100">100 Mbps</Option>
                <Option value="1024">1 Gbps</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Price" name="price">
              <Input
                placeholder="Enter price..."
                //  addonBefore={location ? location.split('-')[2] : ''}
              />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Status"
              name="status"
              initialValue="Active"
            >
              <Switch onChange={statusOnChange} checkedChildren="Active" unCheckedChildren="InActive" checked={statusToggle} />
            </Form.Item>
          </Col>
        </Row>
      );
      break;

    default:
      break;
  }

  const updateAddonSuccess = (values) => {
    console.log('Update Addon success - ', values);
    setUpdateModal(false);

    let osBody;
    switch (categoryValue) {
      case 'os':
        osBody = {
          location: values.location,
          ostype: osTypeValue,
          brand: osBrandValue,
          title: values.title,
          version: values.version,
          num_unit: values.num_unit,
          price: values.price,
          category: 'os',
          status: statusToggle,
        };
        PUT('add-ons/os', updateData._id, state.token, osBody).then((res) => {
          console.log('Post Addon res - ', res);
          setUpdateData(null);
          setDeleteId(null);
          if (res.success === true) {
            notification.success({
              message: 'Successfully Updated!',
              description: 'Data is Successfully Updated!',
            });
            getOsData();
          } else {
            notification.error({
              message: 'Something Went Wrong!',
              description: `Got Error with status code = ${res.status}!`,
            });
          }
        });
        break;

      case 'ram':
        osBody = {
          location: values.location,
          technology: values.technology,
          ramsize: Number(ramSizeValue),
          price: values.price,
          category: 'ram',
          status: statusToggle,
        };
        PUT('add-ons/ram', updateData._id, state.token, osBody).then((res) => {
          console.log('Post Addon res - ', res);
          setUpdateData(null);
          setDeleteId(null);
          if (res.success === true) {
            notification.success({
              message: 'Successfully Updated!',
              description: 'Data is Successfully Updated!',
            });
            getRamData();
          } else {
            notification.error({
              message: 'Something Went Wrong!',
              description: `Got Error with status code = ${res.status}!`,
            });
          }
        });
        break;

      case 'hdd':
        osBody = {
          location: values.location,
          diskformfactor: values.diskformfactor,
          disktype: values.disktype,
          diskcapacityingb: values.diskcapacityingb,
          price: values.price,
          category: 'hdd',
          status: statusToggle,
        };
        PUT('add-ons/hdd', updateData._id, state.token, osBody).then((res) => {
          setUpdateData(null);
          setDeleteId(null);
          console.log('Post Addon res - ', res);

          if (res.success === true) {
            notification.success({
              message: 'Successfully Updated!',
              description: 'Data is Successfully Updated!',
            });
            getHddData();
          } else {
            notification.error({
              message: 'Something Went Wrong!',
              description: `Got Error with status code = ${res.status}!`,
            });
          }
        });
        break;

      case 'bandwidth':
        osBody = {
          location: values.location,
          price: values.price,
          category: 'bandwidth',
          status: statusToggle,
        };
        PUT('add-ons/bandwidth', updateData._id, state.token, osBody).then((res) => {
          console.log('Post Addon res - ', res);
          setUpdateData(null);
          setDeleteId(null);
          if (res.success === true) {
            notification.success({
              message: 'Successfully Updated!',
              description: 'Data is Successfully Updated!',
            });
            getBandwidthData();
          } else {
            notification.error({
              message: 'Something Went Wrong!',
              description: `Got Error with status code = ${res.status}!`,
            });
          }
        });
        break;

      case 'ipaddress':
        osBody = {
          location: values.location,
          price: values.price,
          category: 'ipaddress',
          status: statusToggle,
        };
        PUT('add-ons/ipaddress', updateData._id, state.token, osBody).then((res) => {
          setUpdateData(null);
          setDeleteId(null);
          console.log('Post Addon res - ', res);

          if (res.success === true) {
            notification.success({
              message: 'Successfully Updated!',
              description: 'Data is Successfully Updated!',
            });
            getIpAddressData();
          } else {
            notification.error({
              message: 'Something Went Wrong!',
              description: `Got Error with status code = ${res.status}!`,
            });
          }
        });
        break;

      case 'raid':
        osBody = {
          location: values.location,
          raid: values.raid,
          category: 'raid',
          price: 0,
          status: statusToggle,
        };
        PUT('add-ons/raid', updateData._id, state.token, osBody).then((res) => {
          setUpdateData(null);
          setDeleteId(null);
          console.log('Post Addon res - ', res);

          if (res.success === true) {
            notification.success({
              message: 'Successfully Updated!',
              description: 'Data is Successfully Updated!',
            });
            getRaidData();
          } else {
            notification.error({
              message: 'Something Went Wrong!',
              description: `Got Error with status code = ${res.status}!`,
            });
          }
        });
        break;

      case 'portspeed':
        osBody = {
          category: 'portspeed',
          location: 'location',
          port_speed: Number(values.port_speed),
          price: Number(values.price),
          status: statusToggle,
        };
        console.log('Port speed put body - ', osBody);
        PUT('add-ons/portspeed', updateData._id, state.token, osBody).then((res) => {
          setUpdateData(null);
          setDeleteId(null);
          console.log('Post Addon res - ', res);

          if (res.success === true) {
            notification.success({
              message: 'Successfully Updated!',
              description: 'Data is Successfully Updated!',
            });
            getPortsSpeedData();
          } else {
            notification.error({
              message: 'Something Went Wrong!',
              description: `Got Error with status code = ${res.status}!`,
            });
          }
        });
        break;

      default:
        break;
    }
  };

  const deleteRow = () => {
    DELETE('add-ons', deleteId._id, state.token).then((res) => {
      setDeleteModal(false);
      if (res.success === true) {
        notification.success({
          message: 'Deleted Successfully!',
          description: 'Data is Successfully Deleted!',
        });
        getOsData();
        getHddData();
        getRamData();
        getIpAddressData();
        getPortsSpeedData();
        getRaidData();
        getBandwidthData();
        setTimeout(() => {
          setDeleteId(null);
          setUpdateData(null);
        }, 1500);
        LoadPage('allAddons', dispatch);
      } else if (res.success === false) {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  useEffect(() => {
    //  getRams();
    //  getDisks();

    //  getData();
    getOsData();
    getHddData();
    getRamData();
    getIpAddressData();
    getPortsSpeedData();
    getRaidData();
    getBandwidthData();

    console.log('Update Data - ', updateData);

    // Bredcrumb
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              All Addons
            </Col>
            <Col span="10" flex="row" align="end" className="text-end">
              <Button
                type="primary"
                onClick={() => LoadPage('addAddons', dispatch)}
              >
                <IoAdd />
                {' '}
                Add Addon
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        {/* <Col span="24" className="mb-2">
          <Row gutter="30">
            <Col>
              <Select
                placeholder="Select Category"
                optionFilterProp="children"
                style={{ width: '300px' }}
                defaultValue={categoryFilterValue}
                onChange={filterCategoryOnChange}
                size="large"
              >
                <Option value="select">Select Category</Option>
                <Option value="os">Operating System</Option>
                <Option value="ram">RAM</Option>
                <Option value="hdd">HDD</Option>
                <Option value="bandwidth">Bandwidth</Option>
                <Option value="ipaddress">Addtional IPs</Option>
                <Option value="raid">RAID</Option>
              </Select>
            </Col>
          </Row>
        </Col> */}
        {/* <Col span="24">
          {data && data.length > 0
            ? <Table columns={dataCols} dataSource={data} /> : ''}
        </Col> */}
        <Col span="24">
          <Tabs defaultActiveKey="os">
            <TabPane tab="Operating System" key="os">
              <Table columns={osCols} dataSource={osData} />
            </TabPane>
            <TabPane tab="RAM" key="ram">
              <Table columns={ramCols} dataSource={ramData} />
            </TabPane>
            <TabPane tab="HDD" key="hdd">
              <Table columns={hddCols} dataSource={hddData} />
            </TabPane>
            <TabPane tab="Bandwidth" key="bandwidth">
              <Table columns={bandwidthCols} dataSource={bandwidthData} />
            </TabPane>
            <TabPane tab="IP Address" key="ipaddress">
              <Table columns={ipaddressCols} dataSource={ipaddressData} />
            </TabPane>
            <TabPane tab="RAID" key="raid">
              <Table columns={raidCols} dataSource={raidData} />
            </TabPane>
            <TabPane tab="Port Speed" key="portsspeed">
              <Table columns={portsspeedCols} dataSource={portsspeedData} />
            </TabPane>

          </Tabs>
        </Col>
      </Row>
      <Modal
        width={800}
        footer={false}
        title="Update Addons"
        visible={updateModal}
        onCancel={handleCancel}
        destroyOnClose
      >
        <Form
          name="basic"
          layout="vertical"
          onFinish={updateAddonSuccess}
          autoComplete="off"
          form={UpdateForm}
        >
          {CategoryValueForm}
          <Row gutter="30">
            <Col span="24" className="my-1 py-2 border-top">
              <Button type="default" size="large" className="me-1 px-2" onClick={() => setUpdateModal(false)}>
                Cancel
              </Button>
              <Button
                type="primary"
                size="large"
                className="me-1 btn-success px-4"
                htmlType="submit"
              >
                Update
              </Button>
            </Col>
          </Row>
        </Form>

      </Modal>

      {/* Delete confirmation */}
      <Modal
        title="Confirm if you really want to delete?"
        visible={deleteModal}
        onCancel={cancelModal}
        okText="Delete"
        okButtonProps={{ className: 'btn-danger' }}
        onOk={deleteRow}
      >
        <Row>
          <Col span="24">
            <p>
              <span style={{ fontSize: '20px', fontWeight: 500, color: 'var(--bs-danger)' }}>
                {deleteId && deleteId.title ? `${deleteId.brand}/${deleteId.title} / ${deleteId.version}` : ''}
                {deleteId && deleteId.technology ? `${deleteId.technology}/${deleteId.ramsize}` : ''}
                {deleteId && deleteId.type ? `${deleteId.type}/${deleteId.form_factor}/${deleteId.capacity}` : ''}
                {deleteId && deleteId.price ? `${deleteId.price}/GB` : ''}
                {deleteId && deleteId.price ? `${deleteId.price}/IP` : ''}
                {deleteId && deleteId.raid ? `${deleteId.raid}` : ''}
              </span>
            </p>
            <p>
              Do you really want to
              delete above
              {' '}
              {deleteId ? `${deleteId.category}` : 'item'}
              ?
            </p>
            <p />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default AllAddons;
