import React, {
  useState, useContext, useEffect, useRef,
} from 'react';
import {
  Row,
  Col,
  Input,
  Button,
  Breadcrumb,
  Radio,
  Tabs,
  Form,
  Select,
  notification,
  Tag,
} from 'antd';

import { IoTrash, IoAdd } from 'react-icons/io5';

// Custom imports
import './suppliers.scss';
import AppContext from '../../context/appContext';
import { BREADCRUMB, EDIT_FORM_DATA } from '../../context/types';
import LoadPage from '../../common/loadPage';
import countries from '../../assets/json/countries.json';
import cities from '../../assets/json/cities.json';
import states from '../../assets/json/india-states.json';
import { GET, POST, PUT } from '../../common/crud';

const AddSupplier = () => {
  const [locationValue, setLocationValue] = useState('');
  const [supplierTypeValue, setSupplierTypeValue] = useState('');
  //  const [saluationValue, setSaluationValue] = useState('');
  //  const [gstTreatmentValue, setGstTreatmentValue] = useState('');
  //  const [placeSupplierValue, setPlaceSupplierValue] = useState('');
  //  const [currencyValue, setCurrencyValue] = useState('');
  //  const [paymentTermsValue, setPaymentTermsValue] = useState('');
  //  const [taxPreferrenceValue, setTaxPreferrenceValue] = useState();
  const [supplierStatusValue, setSupplierStatusValue] = useState('active');
  //  const [countryValue, setCountryValue] = useState('');
  const [tabActiveValue, setTabActiveValue] = useState('');
  const [tabsToggle, setTabsToggle] = useState({
    tab1: false,
    tab2: true,
    tab3: true,
  });
  const [locationsList, setLocationsList] = useState();
  const [locationsAll, setLocationsAll] = useState(null);
  //  const [listCurrency, setListCurrency] = useState();
  const [updateData, setUpdateData] = useState(null);
  const [primContact, setPrimContact] = useState();
  const [contactsArray, setContactsArray] = useState([]);
  //  const [currencyArray, setCurrencyArray] = useState([]);
  const [baseCurrency, setBaseCurrency] = useState(null);

  const mainScroll = useRef();

  const [state, dispatch] = useContext(AppContext);

  const { TabPane } = Tabs;

  const { Option } = Select;

  const [BasicInfoForm] = Form.useForm();

  const [AddressForm] = Form.useForm();

  const [ContactsInfoForm] = Form.useForm();

  const [LocationForm] = Form.useForm();

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('allSuppliers', dispatch)}
        >
          Suppliers
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Add Supplier</Breadcrumb.Item>
    </Breadcrumb>
  );

  //  Contact Initial Values
  const ContactsInitialValues = {
    contacts_list: contactsArray,
  };

  //  If Updated initial Values of Basic Info
  const BasicInfoInitialValues = {
    supplier_type: supplierTypeValue,
    display_name: updateData ? updateData.display_name : '',
    first_name: primContact ? primContact.first_name : '',
    last_name: primContact ? primContact.last_name : '',
    company_name: updateData ? updateData.name : '',
    name: updateData ? updateData.name : '',
    email: primContact ? primContact.email : '',
    phone: primContact ? primContact.phone : '',
    work_phone: updateData ? updateData.address.phone : '',
    website: updateData ? updateData.website : '',
    gst_treatment: updateData ? updateData.gst_treatment : '',
    currency: updateData ? updateData.currency : '',
    place_supply: updateData ? updateData.place_supply : '',
    gstin: updateData ? updateData.gstin : '',
    payment_terms: updateData ? updateData.payment_terms : '',
    opening_balance: updateData ? Number(updateData.opening_balance) : 0,
    status: supplierStatusValue,
  };

  //  If Update Initial Values of Address
  const AddressInitialValues = {
    country: updateData ? updateData.address.country : '',
    address1: updateData ? updateData.address.address1 : '',
    address2: updateData ? updateData.address.address1 : '',
    state: updateData ? updateData.address.state : '',
    city: updateData ? updateData.address.city : '',
    zip_code: updateData ? updateData.address.zip_code : '',
    phone: updateData ? updateData.address.phone : '',
  };

  //  If Update Initial values of Contacts
  const UpdateContactsInitialValues = {
    contact_type: contactsArray,
  };

  //  Create Supplier Basic Info Success
  const createSupplierBasicInfo = (values) => {
    console.log('Create supplier basic info values - ', values);
    document.getElementById('main').scrollTo(0, 0, 'smooth');
    mainScroll.current.scrollTo(0, 0);
    setTabActiveValue('supplierAddress');
    setTimeout(() => {
      setTabsToggle({ tab1: false, tab2: false, tab3: true });
    }, 500);
  };

  //  Create Supplier Address on success
  const createSupplierAddress = (values) => {
    console.log('Create new supplier address values - ', values);
    document.getElementById('main').scrollTo(0, 0, 'smooth');
    setTabsToggle({ tab1: false, tab2: false, tab3: true });
    setTabActiveValue('supplierContacts');
  };

  //  Create Supplier Contacts on success
  const createSupplierContacts = async (values) => {
    console.log('Create new supplier contacts values - ', values);
  };

  //  Get Locations
  const getLocations = async () => {
    GET('masterdata/locations', state.token).then(async (res) => {
      //  setLoading(false);
      console.log('Get res - ', res);
      if (res.success === true) {
        setLocationsAll(res.data.locations);
        console.log('Location list - ', res.data.locations);
        const locationListArray = (await res.data.locations.map((locItm) => (<Option key={locItm._id} value={`${locItm.name}-${locItm._id}`}>{`${locItm.name}, ${locItm.code}`}</Option>)));
        /*  const currencyListArray = (await res.data.locations.map((locItm) => (
          <Option key={locItm._id} value={`${locItm.base_currency}-${locItm._id}`}>
            {`${locItm.base_currency_symbol} - ${locItm.base_currency}`}
          </Option>
        ))); */
        //  setListCurrency(currencyListArray);
        console.log('Location list after adding - ', locationListArray);
        setLocationsList(locationListArray);
      } else if (res.success === false) {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  };

  //  Get Currencies
  /* const getCurrencies = async () => {
    GET('masterdata/currencies', state.token).then(async (res) => {
      //  setLoading(false);
      console.log('Get Currencies res - ', res);
      if (res.success === true) {
        const currencyListArray = (await res.data.map((locItm) => (
          <Option key={locItm._id} value={`${locItm.name}`}>
            {`${locItm.symbol} - ${locItm.name}`}
          </Option>
        )));
        console.log('Currencies list after adding - ', currencyListArray);
        setListCurrency(currencyListArray);
      } else if (res.success === false) {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  }; */

  //  Finally submitted
  const finallyButton = async () => {
    let basic;
    let address;
    let contacts;
    await BasicInfoForm.validateFields().then((values) => {
      basic = values;
    }).catch(() => {
      setTabActiveValue('supplierBasicInfo');
      setTabsToggle({
        tab1: false,
        tab2: false,
        tab3: false,
      });
    });
    await AddressForm.validateFields().then((values) => {
      address = values;
    }).catch(() => {
      setTabActiveValue('supplierAddress');
      setTabsToggle({
        tab1: false,
        tab2: false,
        tab3: false,
      });
    });
    await ContactsInfoForm.validateFields().then((values) => {
      contacts = values;
    }).catch(() => {
      setTabActiveValue('supplierContacts');
      setTabsToggle({
        tab1: false,
        tab2: false,
        tab3: false,
      });
    });

    console.log('BASIC - ', basic);
    console.log('ADDRESS - ', address);
    console.log('CONTACTS - ', contacts);

    if (basic && address && contacts) {
      const addSupplierBody = {
        location: locationValue,
        supplier_type: basic.supplier_type,
        name: basic.name,
        display_name: basic.display_name,
        website: basic.website,
        gst_treatment: basic.gst_treatment,
        currency: baseCurrency ? baseCurrency.trim() : '',
        gstin: basic.gstin,
        payment_terms: basic.payment_terms,
        status: basic.status,
        opening_balance: basic.opening_balance,
        place_supply: basic.place_supply,
        contacts: [
          ...contacts.contacts_list,
          {
            contact_type: 'Primary',
            first_name: basic.first_name,
            last_name: basic.last_name,
            email: basic.email,
            phone: basic.phone,
          },
        ],
        address: {
          country: address.country,
          address1: address.address1,
          address2: address.address1,
          city: address.city,
          state: address.state,
          zip_code: address.zip_code,
          phone: basic.phone,
        },
      };

      console.log('Add Supplier Body - ', addSupplierBody);
      if (updateData) {
        PUT('suppliers', updateData._id, state.token, addSupplierBody).then((res) => {
          if (res.success === true) {
            setUpdateData(null);
            LoadPage('allSuppliers', dispatch);
            notification.success({
              message: 'Successfully Updated!',
              description: 'Data is Successfully Updated!',
            });
          } else {
            notification.error({
              message: 'Something Went Wrong!',
              description: `Got Error with status code = ${res.status}!`,
            });
          }
        });
      } else {
        POST('suppliers', state.token, addSupplierBody).then((res) => {
          if (res.success === true) {
            LoadPage('allSuppliers', dispatch);
            notification.success({
              message: 'Successfully Added!',
              description: 'Data is Successfully Added!',
            });
          } else {
            notification.error({
              message: 'Something Went Wrong!',
              description: `Got Error with status code = ${res.status}!`,
            });
          }
        });
      }
    }
  };

  // Countries Short Code
  /* const countryCode = countries.map((country) => (
    <Option key={country.isoCode} value={country.isoCode}>{country.isoCode}</Option>
  )); */

  // Countries Short Code
  const countryNameList = countries.map((country) => (
    <Option key={country.name} value={country.name}>{country.name}</Option>
  ));

  //  Cities List
  const citiesList = cities.map((city) => (
    <Option key={city.name} value={city.name}>{city.name}</Option>
  ));

  //  States List
  const statesList = states.map((inState) => (
    <Option key={inState.code} value={inState.name}>{inState.name}</Option>
  ));

  //  Location on Change
  const locationOnChange = (value) => {
    console.log('Location change - ', value);
    setLocationValue(value.split('-')[0]);
    const listcurrency = locationsAll.filter((itm) => itm._id === value.split('-')[1])[0];
    console.log('listcurrency - ', listcurrency);
    setBaseCurrency(listcurrency.base_currency);
  };

  //  Supplier Type on change
  const supplierTypeOnChange = (e) => {
    setSupplierTypeValue(e.target.value);
  };

  //  Supplier Status no change
  const supplierStatusOnChange = (e) => {
    setSupplierStatusValue(e.target.value);
  };

  // Contact Type On Change
  const contactTypeOnChange = (value) => {
    console.log('Contact type on chnge - ', value);
  };

  //  Tab onClick
  const addSupplierTabClick = (key) => {
    setTabActiveValue(key);
    console.log('Tab clicked key - ', key);
  };

  useEffect(() => {
    getLocations();

    setTabActiveValue('supplierBasicInfo');

    if (state.editPageName === 'addSupplier') {
      console.log('Update edit form - ', state.editFormData);
      console.log('Update location - ', state.editFormData.location);
      LocationForm.setFieldsValue({
        supplier_location: state.editFormData.location,
      });
      setUpdateData(state.editFormData);
      setSupplierStatusValue(state.editFormData.status);
      setLocationValue(state.editFormData.location);
      setSupplierTypeValue(state.editFormData.supplier_type);
      setBaseCurrency(state.editFormData.currency);

      //  Set Primary Contact
      state.editFormData.contacts.filter((res) => res.contact_type === 'Primary').map((res) => {
        setPrimContact(res);
        return true;
      });

      //  Set Contacts Array
      const contactArrayData = state.editFormData.contacts.filter((res) => res.contact_type !== 'Primary');
      setContactsArray(contactArrayData);
      setLocationValue(state.editFormData.location);
    }

    console.log('Update data  - ', updateData);

    console.log('Update basic info initial values - ', BasicInfoInitialValues);

    console.log('Update Address initial values - ', AddressInitialValues);

    console.log('Contacts Array  -', contactsArray);

    console.log('Supplier state - ', state);

    //  Breadcrumb
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });

      dispatch({
        type: EDIT_FORM_DATA,
        payload: {
          editPageName: null,
          editFormData: null,
        },
      });
    };
  }, [contactsArray, locationValue]);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Add Supplier
            </Col>
            <Col span="10" flex="row" align="end" />
          </Row>
        </Col>
      </Row>

      <Row gutter={[30, 30]} className="m-2" ref={mainScroll}>
        <Col span="24">
          <Row>
            <Col span="24" className="initialLocation">
              <Form
                form={LocationForm}
                name="basicInfo"
                layout="vertical"
                autoComplete="off"
              >
                <Form.Item
                  label="Supplier Location"
                  name="supplier_location"
                  initialValue={locationValue}
                >
                  <Select
                    showSearch
                    placeholder="Select Location"
                    optionFilterProp="children"
                    onChange={locationOnChange}
                    size="large"
                  >
                    {locationsList}
                  </Select>
                </Form.Item>
              </Form>
            </Col>
            {locationValue !== '' ? (
              <>
                <Col span="24">
                  <Tabs defaultActiveKey="1" className="addStockTabsBlock" onTabClick={addSupplierTabClick} activeKey={tabActiveValue}>
                    <TabPane tab="Basic Info" key="supplierBasicInfo" activeKey={tabsToggle.tab1}>
                      <Form
                        form={BasicInfoForm}
                        name="basicInfo"
                        layout="vertical"
                        onFinish={createSupplierBasicInfo}
                        autoComplete="off"
                        initialValues={
                          BasicInfoInitialValues
                        }
                      >

                        <Row gutter="30">
                          <Col span="12">
                            <Form.Item
                              label="Supplier Type"
                              name="supplier_type"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select Supplier Type',
                                },
                              ]}
                            >
                              <Radio.Group onChange={supplierTypeOnChange}>
                                <Radio value="Business">Business</Radio>
                                <Radio value="Contractor">Contractor</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                          <Col span="12">
                            <Form.Item
                              label="Currency"
                              name="currency"
                            >
                              {/* <Select
                                placeholder="Select Currency "
                                optionFilterProp="children"
                              >
                                {listCurrency}
                              </Select> */}
                              <div className="currency-block">
                                { baseCurrency ? <Tag>{baseCurrency}</Tag> : '' }
                              </div>

                            </Form.Item>
                          </Col>

                          <Col span="12">
                            <Form.Item
                              label="GST Treatment"
                              name="gst_treatment"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please selet GST Treatment',
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder="Select GST Treatment"
                                optionFilterProp="children"
                                //  onChange={gstTreatmentOnChange}
                              >
                                <Option value="Unregistered">
                                  Unregistered
                                </Option>
                                <Option value="Registered">
                                  Registered
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span="12">
                            <Form.Item
                              label="GST#"
                              name="gstin"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please Enter GST Treatment',
                                },
                              ]}
                            >
                              <Input placeholder="Enter GST# ..." />
                            </Form.Item>
                          </Col>
                          {/*  <Col span="12">
                            <Form.Item
                              label="Saluation (Primary Contact)"
                              name="saluation"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select Saluation',
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder="Select Saluation"
                                optionFilterProp="children"
                              >
                                <Option value="Mr.">Mr.</Option>
                                <Option value="Mrs.">Mrs.</Option>
                                <Option value="Miss">Miss</Option>
                                <Option value="M/s">M/s.</Option>
                              </Select>
                            </Form.Item>
                          </Col> */}
                          <Col span="12">
                            <Form.Item
                              label="First Name"
                              name="first_name"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select First Name',
                                },
                              ]}
                            >
                              <Input placeholder="Enter First Name..." />
                            </Form.Item>
                          </Col>
                          <Col span="12">
                            <Form.Item
                              label="Last Name"
                              name="last_name"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select Last Name',
                                },
                              ]}
                            >
                              <Input placeholder="Enter Last Name..." />
                            </Form.Item>
                          </Col>

                          <Col span="12">
                            <Form.Item label="Company Name" name="name">
                              <Input placeholder="Enter Company Name..." />
                            </Form.Item>
                          </Col>

                          <Col span="12">
                            <Form.Item
                              label="Supplier Display Name"
                              name="display_name"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter Supplier Display Name',
                                },
                              ]}
                            >
                              <Input placeholder="Enter Display Name..." />
                            </Form.Item>
                          </Col>

                          <Col span="12">
                            <Form.Item
                              label="Supplier Email"
                              name="email"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter Supplier Email',
                                },
                              ]}
                            >
                              <Input placeholder="Enter Supplier Email..." />
                            </Form.Item>
                          </Col>

                          <Col span="12">
                            <Form.Item
                              label="Supplier Phone"
                              name="phone"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter Supplier Phone',
                                },
                              ]}
                            >
                              <Input placeholder="Enter Supplier Phone..." />
                            </Form.Item>
                          </Col>

                          {/*  <Col span="12">
                            <Form.Item label="Work Phone" name="work_phone">
                              <Input placeholder="Enter Work Phone..." />
                            </Form.Item>
                          </Col>

                          <Col span="12">
                            <Form.Item label="Designation" name="designation">
                              <Input placeholder="Enter Designation..." />
                            </Form.Item>
                          </Col>

                          <Col span="12">
                            <Form.Item label="Department" name="department">
                              <Input placeholder="Enter Department..." />
                            </Form.Item>
                          </Col> */}

                          <Col span="12">
                            <Form.Item label="Website" name="website">
                              <Input type="url" placeholder="Enter Website Name ..." />
                            </Form.Item>
                          </Col>

                          <Col span="12">
                            <Form.Item
                              label="Place of Supplier (Only if India)"
                              name="place_supply"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please selet Place of Supplier',
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder="Select State of Supplier "
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0}
                              >
                                {statesList}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col span="12">
                            <Form.Item
                              label="Payment Terms"
                              name="payment_terms"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please selet Payment Terms',
                                },
                              ]}
                            >
                              <Select
                                placeholder="Select Payment Terms "
                                optionFilterProp="children"
                                //  onChange={paymentTermsOnChange}
                              >
                                <Option value="Immediate">Immediate</Option>
                                <Option value="Net 15">Net 15</Option>
                                <Option value="Net 30">Net 30</Option>
                                <Option value="Net 60">Net 60</Option>
                                <Option value="Net 90">Net 90</Option>
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col span="12">
                            <Form.Item
                              label="Opening Balance"
                              name="opening_balance"
                            >
                              <Input placeholder="Enter Opening Balance ..." />
                            </Form.Item>
                          </Col>

                          {/* <Col span="12">
                            <Form.Item
                              label="Tax Preference"
                              name="tax_preference"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select Tax Preference',
                                },
                              ]}
                            >
                              <Radio.Group onChange={taxPreferenceOnChange}>
                                <Radio value="Taxable">Taxable</Radio>
                                <Radio value="Tax Exempt">Tax Exempt</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Col> */}

                          <Col span="12">
                            <Form.Item
                              label="Supplier Status"
                              name="status"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select Supplier Status',
                                },
                              ]}
                            >
                              <Radio.Group onChange={supplierStatusOnChange}>
                                <Radio value="active">Active</Radio>
                                <Radio value="inactive">Inactive</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                          <Col span="24" className="border-top mb-2 py-2">
                            <Button type="default" size="large" className="me-1" htmlType="reset">
                              Rest
                            </Button>
                            <Button
                              type="primary"
                              htmlType="submit"
                              size="large"
                              className="btn-success"
                            >
                              Next Add Address
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>

                    <TabPane tab="Address" key="supplierAddress">
                      <Form
                        form={AddressForm}
                        name="address"
                        layout="vertical"
                        onFinish={createSupplierAddress}
                        autoComplete="off"
                        initialValues={
                          AddressInitialValues
                          }
                      >

                        <Row gutter="30">
                          <Col span="12">
                            <Form.Item
                              label="Country"
                              name="country"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select Country',
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder="Select Currency"
                                optionFilterProp="children"

                              >
                                {countryNameList}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col span="12">
                            <Form.Item
                              label="Address 1"
                              name="address1"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please Enter Address',
                                },
                              ]}
                            >
                              <Input placeholder="Enter Address..." />
                            </Form.Item>
                          </Col>

                          <Col span="12">
                            <Form.Item
                              label="Address 2"
                              name="address2"

                            >
                              <Input placeholder="Enter Address..." />
                            </Form.Item>
                          </Col>

                          <Col span="12">
                            <Form.Item
                              label="City (only from india)"
                              name="city"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please Select City name',
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder="Select City "
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0}
                              >
                                {citiesList}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col span="12">
                            <Form.Item
                              label="State (only from india)"
                              name="state"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please Select State name',
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder="Select State "
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0}
                              >
                                {statesList}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col span="12">
                            <Form.Item
                              label="Zip Code"
                              name="zip_code"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please Enter Zip Code',
                                },
                              ]}
                            >
                              <Input placeholder="Enter Zip Code..." />
                            </Form.Item>
                          </Col>

                          <Col span="12">
                            <Form.Item label="Phone" name="phone">
                              <Input placeholder="Enter Phone..." />
                            </Form.Item>
                          </Col>

                          {/*  <Col span="12">
                            <Form.Item label="Fax" name="fax">
                              <Input placeholder="Enter Fax..." />
                            </Form.Item>
                          </Col> */}
                        </Row>
                        <Row gutter="30">
                          <Col span="24" className="border-top mb-2 py-2">
                            <Button type="default" size="large" className="me-1">
                              Cancel
                            </Button>
                            <Button
                              type="primary"
                              htmlType="submit"
                              size="large"
                              className="btn-success"
                            >
                              Proceed to Contacts
                            </Button>
                          </Col>
                        </Row>

                      </Form>
                    </TabPane>

                    <TabPane tab="Contacts" key="supplierContacts">
                      <Form
                        form={ContactsInfoForm}
                        name="contact"
                        layout="vertical"
                        onFinish={createSupplierContacts}
                        autoComplete="off"
                        initialValues={
                          state.editPageName === 'addSupplier' ? UpdateContactsInitialValues : ContactsInitialValues
                        }
                      >
                        <Row gutter="30">
                          <Col span="24">
                            <Form.List
                              name="contacts_list"
                            >
                              {(fields, { add, remove }) => (
                                <>
                                  <Row>
                                    <Col span="24" className="text-end mb-2">
                                      { fields.length < 4 ? (
                                        <Button type="ghost" onClick={() => add()}>
                                          <IoAdd />
                                          {' '}
                                          Add New Contact
                                        </Button>
                                      ) : ''}
                                    </Col>
                                  </Row>
                                  {fields.map(({
                                    key, name, fieldKey,
                                  }) => (
                                    state.editPageName !== 'addSupplier'
                                      ? (
                                        <Row key={key} gutter="30" align="bottom">
                                          <Col span="4">
                                            <Form.Item
                                              label="Contact Type"
                                              name={[name, 'contact_type']}
                                              fieldKey={[fieldKey, 'contact_type']}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'Please Select Contact Type',
                                                },
                                              ]}
                                            >
                                              <Select
                                                showSearch
                                                placeholder="Select Contact type"
                                                optionFilterProp="children"
                                                onChange={contactTypeOnChange}
                                              >
                                                <Option value="Owner">Owner</Option>
                                                <Option value="Admin">Admin</Option>
                                                <Option value="Billing">Billing</Option>
                                                <Option value="Tech">
                                                  Tech
                                                </Option>
                                              </Select>
                                            </Form.Item>
                                          </Col>
                                          <Col span="4">
                                            <Form.Item
                                              label="First Name"
                                              name={[name, 'first_name']}
                                              fieldKey={[fieldKey, 'first_name']}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'Please Enter First Name',
                                                },
                                              ]}
                                            >
                                              <Input placeholder="Enter First Name..." />
                                            </Form.Item>
                                          </Col>
                                          <Col span="4">
                                            <Form.Item
                                              label="Last Name"
                                              name={[name, 'last_name']}
                                              fieldKey={[fieldKey, 'last_name']}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'Please Enter Last Name',
                                                },
                                              ]}
                                            >
                                              <Input placeholder="Enter Last Name..." />
                                            </Form.Item>
                                          </Col>

                                          <Col span="5">
                                            <Form.Item
                                              label="Email"
                                              name={[name, 'email']}
                                              fieldKey={[fieldKey, 'email']}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'Please Enter Email Id',
                                                },
                                              ]}
                                            >
                                              <Input placeholder="Enter Email..." />
                                            </Form.Item>
                                          </Col>

                                          <Col span="5">
                                            <Form.Item
                                              label="Phone"
                                              name={[name, 'phone']}
                                              fieldKey={[fieldKey, 'phone']}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: 'Please Enter Phone Number',
                                                },
                                              ]}
                                            >
                                              <Input placeholder="Enter Phone..." />
                                            </Form.Item>
                                          </Col>
                                          <Col span="2">
                                            <Button
                                              type="default"
                                              className="btn-outline-danger mb-2"
                                              onClick={() => remove(name)}
                                            >
                                              <IoTrash />
                                            </Button>
                                          </Col>
                                        </Row>
                                      ) : (
                                        <Row key={key} gutter="30" align="bottom">
                                          <Col span="4">
                                            <Form.Item
                                              label="Contact Type"
                                              name={[name, 'contact_type']}
                                              fieldKey={[fieldKey, 'contact_type']}
                                            >
                                              <Select
                                                showSearch
                                                placeholder="Select Contact type"
                                                optionFilterProp="children"
                                                onChange={contactTypeOnChange}
                                              >
                                                <Option value="Owner">Owner</Option>
                                                <Option value="Admin">Admin</Option>
                                                <Option value="Billing">Billing</Option>
                                                <Option value="Tech">
                                                  Tech
                                                </Option>
                                              </Select>
                                            </Form.Item>
                                          </Col>
                                          <Col span="4">
                                            <Form.Item
                                              label="First Name"
                                              name={[name, 'first_name']}
                                              fieldKey={[fieldKey, 'first_name']}
                                            >
                                              <Input placeholder="Enter First Name..." />
                                            </Form.Item>
                                          </Col>
                                          <Col span="4">
                                            <Form.Item
                                              label="Last Name"
                                              name={[name, 'last_name']}
                                              fieldKey={[fieldKey, 'last_name']}
                                            >
                                              <Input placeholder="Enter Last Name..." />
                                            </Form.Item>
                                          </Col>

                                          <Col span="5">
                                            <Form.Item
                                              label="Email"
                                              name={[name, 'email']}
                                              fieldKey={[fieldKey, 'email']}
                                            >
                                              <Input placeholder="Enter Email..." />
                                            </Form.Item>
                                          </Col>

                                          <Col span="5">
                                            <Form.Item
                                              label="Phone"
                                              name={[name, 'phone']}
                                              fieldKey={[fieldKey, 'phone']}
                                            >
                                              <Input placeholder="Enter Phone..." />
                                            </Form.Item>
                                          </Col>
                                          <Col span="2">
                                            <Button
                                              type="default"
                                              className="btn-outline-danger mb-2"
                                              onClick={() => remove(name)}
                                            >
                                              <IoTrash />
                                            </Button>
                                          </Col>
                                        </Row>
                                      )
                                  ))}
                                </>
                              )}

                            </Form.List>
                          </Col>
                          <Col span="24" className="border-top mb-2 py-2">
                            <Button type="default" size="large" className="me-1">
                              Cancel
                            </Button>
                            <Button
                              type="primary"
                              htmlType="button"
                              size="large"
                              className="btn-success"
                              onClick={() => finallyButton()}
                            >
                              {updateData ? 'Update Data' : 'Add Supplier'}
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>
                  </Tabs>
                </Col>
              </>
            ) : null}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default AddSupplier;
