import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Input,
  Button,
  Breadcrumb,
  Form,
  Select,
  Switch,
  Table,
  Modal,
  Radio,
  message,
  notification,
  Tag,
} from 'antd';

import { IoAdd, IoPencil, IoTrash } from 'react-icons/io5';

// Custom imports
import AppContext from '../../../context/appContext';
import { BREADCRUMB } from '../../../context/types';
import countries from '../../../assets/json/countries.json';
import timezone from '../../../assets/json/timezones.json';
import LoadPage from '../../../common/loadPage';
import { GET, PUT, DELETE } from '../../../common/crud';
import Currencies from '../../../assets/json/country-currencies.json';

const AllLocations = () => {
  const [taxIncluded, setTaxIncluded] = useState(true);
  //  const [tableData, setTableData] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [marketClass, setMarketClass] = useState('');
  const [updateData, setUpdateData] = useState([]);
  const [data, setData] = useState([]);
  const [deleteId, setDeleteId] = useState(null);

  const [state, dispatch] = useContext(AppContext);
  const { Option } = Select;

  const [UpdateForm] = Form.useForm();

  //  Update Row Data
  const updateRowData = async (id, record) => {
    console.log('Update data row id - ', id);
    UpdateForm.setFieldsValue({
      ...record,
      base_currency: `${record.base_currency_symbol}-${record.base_currency}`,
    });
    setTaxIncluded(record.tax_included);
    setUpdateModal(true);
    setUpdateData(record);
  };

  //  Delete Row Data
  const deleteRowData = (record) => {
    console.log('Delete data row id - ', record);
    setDeleteModal(true);
    setDeleteId(record);
  };

  // Table Columns
  const locationsCols = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Code/Currency',
      dataIndex: 'codeCurrency',
      key: 'codeCurrency',
    },
    {
      title: 'Tax Included',
      dataIndex: 'tax_included',
      key: 'tax_included',
      render: (text) => (
        <>
          {text === true ? (
            <Tag color="green">True</Tag>
          ) : (
            <Tag color="red">False</Tag>
          )}
        </>
      ),
    },
    {
      title: 'Timezone',
      dataIndex: 'timezone',
      key: 'timezone',
      className: 'text-truncate timeZoneCol',
    },
    {
      title: 'VLAN Range',
      dataIndex: 'vlan_range',
      key: 'vlan_range',
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      render: (id, record) => (
        <>
          <Button
            type="default"
            className="me-1 btn-outline-secondary"
            onClick={() => updateRowData(id, record)}
            icon={<IoPencil />}
          />
          <Button
            type="default"
            className="me-1 btn-outline-danger"
            onClick={() => deleteRowData(record)}
            icon={<IoTrash />}
          />
        </>
      ),
    },
  ];

  const handleCancel = () => {
    setUpdateModal(false);
  };

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>

      <Breadcrumb.Item>All Location</Breadcrumb.Item>
    </Breadcrumb>
  );

  // Location Tax onChange
  const taxIncludedOnChange = (value) => {
    setTaxIncluded(value);
  };

  // Countries Short Code
  const countryCode = countries.map((country) => (
    <Option key={country.dialCode} value={country.isoCode}>{country.isoCode}</Option>
  ));

  // Cancel Delete Modal
  const cancelModal = () => {
    setDeleteModal(false);
  };

  // Countries Short Code
  const timeZoneList = timezone.map((timezoneItem) => (
    <Option value={timezoneItem.text} key={timezoneItem.value}>{timezoneItem.text}</Option>
  ));

  //  All Locations List
  const tableData = () => {
    GET('masterdata/locations', state.token).then((res) => {
      //  setLoading(false);
      console.log('Get location res - ', res);
      const tableDataArray = [];
      if (res.success === true) {
        res.data.locations.map((mapData) => {
          const newDataContent = {
            ...mapData,
            codeCurrency: `${mapData.code}/${mapData.currency.join(', ')}`,
          };
          return tableDataArray.push(newDataContent);
        });
        setData(tableDataArray);
      } else if (res.success === false) {
        message.error('Something went wrong! Please try again!!!');
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  };

  //  Update Form Success
  const updateLocationSuccess = (values) => {
    console.log('Update form success values - ', values);
    const locationBody = {
      name: values.name,
      address: values.address,
      code: values.code,
      currency: values.currency,
      marketing_class: values.marketing_class,
      tax_included: taxIncluded,
      tax_rate: values.tax_rate ? Number(values.tax_rate) : 0,
      timezone: values.timezone,
      location_url: values.location_url,
      pxe_url: values.pxe_url,
      vlan_range: values.vlan_range,
      base_currency: values.base_currency.split('-')[1].replace(/ /g, ''),
      base_currency_symbol: values.base_currency.split('-')[0].replace(/ /g, ''),
      gateway: values.gateway,
    };
    setUpdateModal(false);
    console.log('Update Location POST Body - ', locationBody);
    PUT('masterdata/locations', updateData._id, state.token, locationBody).then((res) => {
      if (res.success === true) {
        UpdateForm.resetFields();
        setUpdateData({});
        setDeleteId(null);
        notification.success({
          message: 'Successfully Updated!',
          description: 'Data is Successfully Updated!',
        });
        tableData();
        setUpdateModal(false);
      } else {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  // Delete Row Data
  const deleteData = () => {
    setDeleteModal(false);
    DELETE('masterdata/locations', deleteId._id, state.token).then((res) => {
      UpdateForm.resetFields();
      if (res.success === true) {
        setUpdateData({});
        setDeleteId(null);
        notification.success({
          message: 'Deleted Successfully!',
          description: 'Data is Successfully Deleted!',
        });
        tableData();
      } else {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  // Currency onChange
  const currencyOnChange = (value) => {
    console.log('Currency selected on change - ', value);
  };

  //  Location Marketing Class onChange
  const locationMarketingOnChange = (values) => {
    setMarketClass(values);
  };

  useEffect(() => {
    // Bredcrumb
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    tableData();

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              All Locations
            </Col>
            <Col span="10" flex="row" align="end" className="text-end">
              <Button
                type="primary"
                onClick={() => LoadPage('addLocation', dispatch)}
              >
                <IoAdd />
                {' '}
                Add Location
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24">
          <Table columns={locationsCols} dataSource={data} />
        </Col>
      </Row>

      <Modal
        width={1100}
        footer={false}
        title="Update Location"
        visible={updateModal}
        onCancel={handleCancel}
      >
        <Form
          form={UpdateForm}
          name="basic"
          layout="vertical"
          onFinish={updateLocationSuccess}
          autoComplete="off"
        >
          <Row gutter="30" className="flex-direction-column">
            <Col span="12">
              <Form.Item
                label="Location Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Location Name',
                  },
                ]}
              >
                <Input placeholder="Enter Location Name..." />
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item
                label="Location Address"
                name="address"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Location Address',
                  },
                ]}
                tooltip="Minimum 25 and maximum should be 250 characters"
              >
                <Input minLength={25} maxLength={250} placeholder="Enter Location Address..." />
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item
                label="Country Code"
                name="code"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Country Code',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Country Code"
                  optionFilterProp="children"
                  onChange={currencyOnChange}
                  filterOption={(input, option) => option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0}
                >
                  {countryCode}
                </Select>
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item
                label="Location Currency"
                name="currency"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Location Currency',
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="Select Currency"
                  optionFilterProp="children"
                  onChange={currencyOnChange}
                >
                  {Currencies.length > 0 ? Currencies.map((item) => (
                    <Option key={item.key} value={`${item.currency.code}`}>
                      {item.currency.symbol}
                      {' '}
                      -
                      {' '}
                      {item.currency.code}
                    </Option>
                  )) : null }
                  ;
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Payment Gateway"
                name="gateway"
                rules={[
                  {
                    required: true,
                    message: 'Select Payment Gateway',
                  },
                ]}
              >
                <Select
                  showSearch
                  mode="multiple"
                  placeholder="Select Payment Gateway"
                  style={{ width: '100%' }}
                  filterOption={(input, option) => option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value="Razorpay">Razorpay</Option>
                  <Option value="Paypal">Paypal</Option>
                  <Option value="Stripe">Stripe</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Base Currency"
                name="base_currency"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Base Currency',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Currency"
                  optionFilterProp="children"
                >
                  {Currencies.length > 0 ? Currencies.map((item) => (
                    <Option key={item.key} value={`${item.currency.symbol}-${item.currency.code}`}>
                      {item.currency.symbol}
                      -
                      {item.currency.code}
                    </Option>
                  )) : null }
                  ;
                </Select>
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item
                label="Location Marketing Class"
                name="marketing_class"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Marketing class',
                  },
                ]}
              >
                <Radio.Group value={marketClass} onChange={locationMarketingOnChange}>
                  <Radio value="enterprise">Enterprise</Radio>
                  <Radio value="standard">Standard</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col span="12">
              <Row>
                <Col span="12">
                  <Form.Item
                    label="Location Tax Included in Price"
                    name="tax_included"
                  >
                    <Switch
                      onChange={taxIncludedOnChange}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked={taxIncluded}
                    />
                  </Form.Item>
                </Col>

                {!taxIncluded ? (
                  <Col span="12">
                    <Form.Item
                      label="Please Enter Tax Percentage"
                      name="tax_rate"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Tax Percentage',
                        },
                      ]}
                    >
                      <Input
                        addonAfter="%"
                        placeholder="Please enter Tax Percentage..."
                      />
                    </Form.Item>
                  </Col>
                ) : null}
              </Row>
            </Col>

            <Col span="12">
              <Form.Item
                label="Location Timezone"
                name="timezone"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Location Timezone',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Timezone"
                  optionFilterProp="children"
                  onChange={currencyOnChange}
                >
                  {timeZoneList}
                </Select>
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item
                label="Location Colony URL"
                name="location_url"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Location Colony URL',
                  },
                ]}
              >
                <Input placeholder="Location Colony URL..." />
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item
                label="Location PXE Work URL"
                name="pxe_url"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Location PXE Work URL',
                  },
                ]}
              >
                <Input placeholder="Location PXE Work URL..." />
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item
                label="Location VLAN Range"
                name="vlan_range"
                rules={[
                  {
                    required: true,
                    message: 'Please select Location VLAN Range',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Location VLAN Range"
                  optionFilterProp="children"
                  onChange={currencyOnChange}
                  filterOption={(input, option) => option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value="0-100">0-100</Option>
                  <Option value="101-200">101-200</Option>
                  <Option value="201-300">201-300</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span="24" className="my-1 py-2 border-top">
              <Button type="default" size="large" className="me-1 px-2">
                Cancel
              </Button>
              <Button
                type="primary"
                size="large"
                className="me-1 btn-success px-4"
                htmlType="submit"
              >
                Update
              </Button>
            </Col>
          </Row>
        </Form>

      </Modal>

      <Modal
        title="Confirm if you really want to delete?"
        visible={deleteModal}
        onCancel={cancelModal}
        okText="Delete"
        okButtonProps={{ className: 'btn-danger' }}
        onOk={deleteData}
      >
        <Row>
          <Col span="24">
            <p>
              <span style={{ fontSize: '20px', fontWeight: 500, color: 'var(--bs-danger)' }}>
                {deleteId ? `${deleteId.code} / ${deleteId.address}` : ''}
              </span>
            </p>
            <p>
              Do you really want to
              delete above RAM?
            </p>
            <p />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default AllLocations;
