import React, { useContext, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Breadcrumb,
  Select,
  Table,
  Modal,
  Radio,
  Input,
  Form,
  message,
  Tag,
  notification,
} from 'antd';

import { IoPencil, IoAdd, IoTrash } from 'react-icons/io5';

// Custom imports
import AppContext from '../../context/appContext';
import { BREADCRUMB } from '../../context/types';
import LoadPage from '../../common/loadPage';
import { DELETE, GET, PUT } from '../../common/crud';

const AllUsers = () => {
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [deleteDataId, setDeleteDataId] = useState(null);

  const [state, dispatch] = useContext(AppContext);

  const [UpdateForm] = Form.useForm();

  const { Option } = Select;

  const emptySpaceRegEx = /\s/;

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>All Users</Breadcrumb.Item>
    </Breadcrumb>
  );

  //  Update Date row
  const updateRowData = async (id, record) => {
    console.log('Update id ', id, record);
    /* let updateItem;
    await data.map((item) => {
      if (item.key === id) {
        updateItem = item;
      }
      console.log('Update item - ', updateItem);
      return setUpdateData(updateItem);
    });
    console.log('Update id - ', id); */
    setUpdateData(record);
    UpdateForm.setFieldsValue(record);
    setUpdateModal(true);
  };

  //  Delete Date Row
  const deleteRowData = (record) => {
    console.log('Delete id - ', record);
    setDeleteDataId(record);
    setDeleteModal(true);
  };

  // Supplier Table Columns
  const userTableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      render: (_, record) => (
        <>
          {record.first_name}
          {' '}
          {record.last_name}
        </>
      ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: 130,
    },
    {
      title: 'Email Id',
      dataIndex: 'email',
      key: 'email',
      className: 'text-truncate email-truncate',
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      key: 'mobile',
    },
    /* {
      title: 'Created Date',
      dataIndex: 'created_date',
      key: 'created_date',
    }, */
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 120,
      render: (text) => (
        text === 'active' ? <Tag color="green">{text}</Tag> : <Tag color="red">{text}</Tag>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '120px',
      render: (id, record) => (
        <div className="text-center">
          <Button
            type="default"
            icon={<IoPencil color="#777" />}
            onClick={() => updateRowData(id, record)}
            className="me-1"
            disabled={record.role === 'SuperAdmin'}
          />
          <Button
            type="default"
            className="me-1 btn-outline-danger"
            onClick={() => deleteRowData(record)}
            icon={<IoTrash />}
          />
        </div>
      ),
    },
  ];

  // Close Update Modal
  const handleCancel = () => {
    UpdateForm.resetFields();
    setUpdateModal(false);
  };

  // Cancel Delete Modal
  const cancelModal = () => {
    setDeleteModal(false);
  };

  //  Get User table data
  const tableData = () => {
    GET('users', state.token).then((res) => {
      //  setLoading(false);
      console.log('Get res - ', res);
      //  const tableDataArray = [];
      if (res.success === true) {
        /* res.data.map((mapData) => {
          const newData = {
            key: mapData._id,
            name: `${mapData.first_name}${' '}${mapData.last_name}`,
            status: mapData.status,
            role: mapData.role,
            emailId: mapData.email,
            mobile: mapData.mobile,
            actions: mapData._id,
          };
          return tableDataArray.push(newData);
        }); */
        setData(res.data);
      } else if (res.success === false) {
        message.error('Something went wrong! Please try again!!!');
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  };

  //  Update Currency
  const updateUserSuccess = async (values) => {
    console.log('Updated on form success - ', values);
    const updateBody = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      mobile: values.mobile,
      role: values.role,
      status: values.status,
    };
    setUpdateModal(false);
    PUT('users', updateData._id, state.token, updateBody).then((res) => {
      console.log('PUT res - ', res);
      if (res.success === true) {
        setUpdateData([]);
        setDeleteDataId(null);
        notification.success({
          message: 'Successfully Updated!',
          description: 'Data is Successfully Updated!',
        });
        tableData();
      } else {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    }).catch((err) => {
      console.log('PUT res err - ', err);
    });
  };

  //  Delete Row Data
  const deleteRow = (values) => {
    console.log('Delete Row id - ', values);
    setDeleteModal(false);
    DELETE('users', deleteDataId, state.token).then((res) => {
      if (res.success === true) {
        setUpdateData([]);
        setDeleteDataId(null);
        notification.success({
          message: 'Successfully Updated!',
          description: 'Data is Successfully Updated!',
        });
      } else {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
      console.log('Delete currency res - ', res);

      tableData();
    });
  };

  //  Status on Change
  const statusOnChange = (value) => {
    console.log('Status change value - ', value);
  };

  useEffect(() => {
    tableData();

    //  Breadcrumb
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              All Users
            </Col>
            <Col span="10" flex="row" align="end" className="text-end">
              <Button
                type="primary"
                size="large"
                onClick={() => LoadPage('addUser', dispatch)}
              >
                <IoAdd />
                {' '}
                Add User
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24" className="">
          <Table
            pagination={false}
            columns={userTableColumns}
            dataSource={data}
          />
        </Col>
      </Row>

      <Modal
        width={800}
        footer={false}
        title="Update User"
        visible={updateModal}
        onCancel={handleCancel}
      >
        <Form
          name="basic"
          layout="vertical"
          onFinish={updateUserSuccess}
          autoComplete="off"
          form={UpdateForm}
          /* initialValues={{
            userFirstName: updateData.name ? updateData.name.split(' ')[0] : '',
            userLastName: updateData.name ? updateData.name.split(' ')[1] : '',
            userEmail: updateData.emailId,
            userMobile: updateData.mobile,
            contactRole: updateData.role,
            userStatus: updateData.status,
          }} */
        >
          <Row gutter="30" className="flex-direction-column">
            <Col span="12">
              <Form.Item
                label="First Name"
                name="first_name"
                rules={[() => ({
                  validator(_, value) {
                    if (!value || !emptySpaceRegEx.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Empty spaces are not allowed!'));
                  },
                })]}
              >
                <Input placeholder="Enter First Name..." />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Last Name"
                name="last_name"
                rules={[() => ({
                  validator(_, value) {
                    if (!value || !emptySpaceRegEx.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Empty spaces are not allowed!'));
                  },
                })]}
              >
                <Input placeholder="Enter Last Name..." />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Email Id',
                  },
                ]}
              >
                <Input disabled type="email" placeholder="Enter Email..." />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item label="Mobile" name="mobile">
                <Input placeholder="Enter Mobile..." />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item label="Role" name="role">
                <Select
                  showSearch
                  placeholder="Select Role"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value="SuperAdmin">Super Admin</Option>
                  <Option value="Administrator">Administrator</Option>
                  <Option value="Sales">Sales</Option>
                  <Option value="Accounts">Accounts</Option>
                  <Option value="DCEngineer">DCEngineer</Option>
                  <Option value="Employee">Employee</Option>
                  <Option value="DeveloperMode">DeveloperMode</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Status"
                name="status"
                rules={[
                  {
                    required: true,
                    message: 'Please select Supplier Type',
                  },
                ]}
              >
                <Radio.Group onChange={statusOnChange}>
                  <Radio value="active">Active</Radio>
                  <Radio value="inactive">Inactive</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col span="24" className="my-1 py-2 border-top">
              <Button type="default" size="large" className="me-1 px-2" onClick={() => setUpdateModal(false)}>
                Cancel
              </Button>
              <Button
                type="primary"
                size="large"
                className="me-1 btn-success px-4"
                htmlType="submit"
              >
                Update User
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* Delete confirmation */}
      <Modal
        title="Confirm if you really want to delete?"
        visible={deleteModal}
        onCancel={cancelModal}
        okText="Delete"
        okButtonProps={{ className: 'btn-danger' }}
        onOk={deleteRow}
      >
        <Row>
          <Col span="24">
            <p>
              <span style={{ fontSize: '20px', fontWeight: 500, color: 'var(--bs-danger)' }}>
                {deleteDataId ? `${deleteDataId.country} / ${deleteDataId.name} / ${deleteDataId.symbol}` : ''}
              </span>
            </p>
            <p>
              Do you really want to
              delete above RAM?
            </p>
            <p />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default AllUsers;
