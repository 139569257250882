import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Input,
  Button,
  Form,
  Select,
  Table,
  Modal,
  Breadcrumb,
  notification,
} from 'antd';

import { IoPencil, IoAdd, IoTrash } from 'react-icons/io5';

// Custom imports
import { DELETE, GET, PUT } from '../../../common/crud';
import AppContext from '../../../context/appContext';
import countries from '../../../assets/json/countries.json';
import LoadPage from '../../../common/loadPage';
import { BREADCRUMB } from '../../../context/types';

const AllCurrencies = () => {
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState([]);
  const [updateData, setUpdateData] = useState({});
  const [pgValue, setPgValue] = useState(updateData ? updateData.pg : '');
  const [deleteDataId, setDeleteDataId] = useState(null);
  //  const [loading, setLoading] = useState(true);

  const [state, dispatch] = useContext(AppContext);

  const { Option } = Select;

  const [UpdateForm] = Form.useForm();

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Add Currencies</Breadcrumb.Item>
    </Breadcrumb>
  );

  // Close Update Modal
  const handleCancel = () => {
    console.log('Handle Cancel');
    setUpdateModal(false);
    setUpdateData({});
    setDeleteDataId(null);
    UpdateForm.resetFields();
  };

  // Countries List
  const CountriesList = countries.map((country) => (
    <Option key={country.dialCode} value={country.isoCode}>
      {country.name}
    </Option>
  ));

  // countries on change
  const countriesOnChange = (value) => {
    console.log('Country on change - ', value);
  };

  // Confirm Modal Delete
  const deleteItem = (id) => {
    console.log('delete id - ', id);
    setDeleteDataId(id);
    setDeleteModal(true);
  };

  // Cancel Delete Modal
  const cancelModal = () => {
    setDeleteModal(false);
    setUpdateData(null);
    setDeleteDataId(null);
    UpdateForm.resetFields();
  };

  //  Update Data
  const updateModalData = async (record) => {
    /* setUpdateData(null);
    let updateItem;
    await data.map((item) => {
      if (item.key === id) {
        updateItem = item;
      }
      console.log('Update item - ', updateItem);
      setUpdateData(updateItem);
      return true;
    });
    setPgValue(updateItem.pg);
    console.log('Update id - ', id);
    setUpdateModal(true); */
    setPgValue(record.pg);
    setUpdateModal(true);
    console.log('Record - ', record);
    setUpdateData(record);
    UpdateForm.setFieldsValue(record);
  };

  // Supplier Table Columns
  const currencyTableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 180,
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      width: 120,
    },
    {
      title: 'Country Name',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'Payment Gateway',
      dataIndex: 'pg',
      key: 'pg',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      render: (id, record) => (
        <div className="text-center">
          <Button
            type="default"
            icon={<IoPencil color="#777" />}
            onClick={() => updateModalData(record)}
            className="me-1"
          />
          <Button
            type="default"
            className="me-1 btn-outline-danger"
            onClick={() => deleteItem(record)}
            icon={<IoTrash />}
          />
        </div>
      ),
    },
  ];

  const tableData = () => {
    GET('masterdata/currencies', state.token).then((res) => {
      //  setLoading(false);
      console.log('Get res - ', res);
      // const tableDataArray = [];
      if (res.success === true) {
        /* res.data.map((mapData) => {
          const newData = {
            key: mapData._id,
            name: mapData.name,
            symbol: mapData.symbol,
            country: mapData.country,
            pg: mapData.pg,
            actions: mapData._id,
          };
          return tableDataArray.push(newData);
        }); */
        setData(res.data);
      } else if (res.success === false) {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  };

  //  Update Currency
  const updateCurrencyDataSuccess = async (values) => {
    console.log('Updated on form success - ', values);
    const updateBody = {
      _id: updateData.key,
      name: values.name,
      symbol: values.symbol,
      country: values.country,
      pg: pgValue,
    };

    PUT('masterdata/currencies', updateData._id, state.token, updateBody).then((res) => {
      setUpdateModal(false);
      console.log('PUT res - ', res);
      setUpdateData(null);
      setDeleteDataId(null);
      if (res.success === true) {
        notification.success({
          message: 'Successfully Updated!',
          description: 'Data is Successfully Updated!',
        });
        tableData();
      } else {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    }).catch((err) => {
      notification.error({
        message: 'Something Went Wrong!',
        description: `Got Error with status code = ${err.status}!`,
      });
    });
  };

  //  pg OnChange
  const pgOnChange = (value) => {
    setPgValue(value);
  };

  //  Delete Row Data
  const deleteRow = (values) => {
    console.log('Delete Row id - ', values);
    setDeleteModal(false);
    DELETE('masterdata/currencies', deleteDataId._id, state.token).then((res) => {
      console.log('Delete currency res - ', res);
      setDeleteDataId(null);
      setUpdateData(null);
      tableData();
      if (res.success === true) {
        notification.success({
          message: 'Deleted Successfully!',
          description: 'Data is Successfully Deleted!',
        });
      } else {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  useEffect(() => {
    tableData();

    console.log('data - ', data);

    //  Breadcrumb
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              All Currencies
            </Col>
            <Col span="10" flex="row" align="end" className="text-end">
              <Button
                type="primary"
                size="large"
                onClick={() => LoadPage('addCurrency', dispatch)}
              >
                <IoAdd />
                {' '}
                Add Currency
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24" className="">
          <Table
            pagination={false}
            columns={currencyTableColumns}
            dataSource={data}
            /* loading={
              loading ? <Spin size="large" /> : null
            } */
          />
        </Col>
      </Row>
      <Modal
        width={800}
        footer={false}
        title="Update Currency"
        visible={updateModal}
        onCancel={handleCancel}
      >
        <Form
          form={UpdateForm}
          name="basic"
          layout="vertical"
          onFinish={updateCurrencyDataSuccess}
          autoComplete="off"
        >
          <Row gutter="30" className="flex-direction-column">
            <Col span="12">
              <Form.Item
                label="Currency Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Enter Currency Name',
                  },
                ]}
              >
                <Input placeholder="Currency Name like Indian Rupee, Unisted States Dollar, Australia Dollar..." />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Currency Symbol"
                name="symbol"
                rules={[
                  {
                    required: true,
                    message: 'Enter Currency Symbol',
                  },
                ]}
              >
                <Input placeholder="Enter symbol like $, A$, HK$..." />
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item
                label="Country name"
                name="country"
                rules={[
                  {
                    required: true,
                    message: 'Select Country',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Country"
                  onChange={countriesOnChange}
                  style={{ width: '100%' }}
                  filterOption={(input, option) => option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0}
                >
                  {CountriesList}
                </Select>
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item
                label="Payment Gateway"
                name="pg"
                rules={[
                  {
                    required: true,
                    message: 'Select Payment Gateway',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Payment Gateway"
                  onChange={pgOnChange}
                  style={{ width: '100%' }}
                  filterOption={(input, option) => option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value="Razorpay">Razorpay</Option>
                  <Option value="Paypal">Paypal</Option>
                  <Option value="Stripe">Stripe</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span="24" className="my-1 py-2 border-top">
              <Button type="default" size="large" className="me-1 px-2" onClick={() => setUpdateModal(false)}>
                Cancel
              </Button>
              <Button
                type="primary"
                size="large"
                className="me-1 btn-success px-4"
                htmlType="submit"
              >
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* Delete confirmation */}
      <Modal
        title="Confirm if you really want to delete?"
        visible={deleteModal}
        onCancel={cancelModal}
        okText="Delete"
        okButtonProps={{ className: 'btn-danger' }}
        onOk={deleteRow}
      >
        <Row>
          <Col span="24">
            <p>
              <span style={{ fontSize: '20px', fontWeight: 500, color: 'var(--bs-danger)' }}>
                {deleteDataId ? `${deleteDataId.country} / ${deleteDataId.name} / ${deleteDataId.symbol}` : ''}
              </span>
            </p>
            <p>
              Do you really want to
              delete above Currency?
            </p>
            <p />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default AllCurrencies;
