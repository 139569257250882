import React, { useContext } from 'react';
import { Breadcrumb, Button } from 'antd';
import PropTypes from 'prop-types';

import AppContext from '../context/appContext';
import LoadPage from './loadPage';

const Breadcrumbs = ({ navList, title }) => {
  const [, dispatch] = useContext(AppContext);

  return (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button type="link" className="p-0" onClick={() => LoadPage('dashboard', dispatch)}>
          Home
        </Button>
      </Breadcrumb.Item>
      {navList && navList.map((item) => (
        <Breadcrumb.Item>
          <Button type="link" className="p-0" onClick={() => LoadPage(item.link, dispatch)}>
            {item.name}
          </Button>
        </Breadcrumb.Item>
      ))}
      <Breadcrumb.Item>{title}</Breadcrumb.Item>
    </Breadcrumb>
  );
};

Breadcrumbs.propTypes = {
  navList: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default Breadcrumbs;
