import React, { useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Breadcrumb,
  Form,
  Select,
  Input,
  notification,
} from 'antd';

// Custom imports
import AppContext from '../../../context/appContext';
import { BREADCRUMB } from '../../../context/types';
import LoadPage from '../../../common/loadPage';
import { POST } from '../../../common/crud';

const AddIPAddress = () => {
  const [state, dispatch] = useContext(AppContext);

  const { Option } = Select;

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Button type="link" className="p-0" onClick={() => LoadPage('allIpAddresses', dispatch)}>
          All IP Addresses
        </Button>

      </Breadcrumb.Item>
      <Breadcrumb.Item>Add IP Address</Breadcrumb.Item>
    </Breadcrumb>
  );

  // Create RAM SUCCESS
  const createNewIPAddressSuccess = async (values) => {
    console.log('Add IP Address Values - ', values);

    const AddIPAddressBody = {
      company_name: values.company_name,
      ip_version: values.ip_version,
      ip_addr: values.ip_addr,
      pool: Number(values.pool),
    };

    POST('masterdata/ip_addresses', state.token, AddIPAddressBody).then((res) => {
      console.log('Add Ram POST res - ', res);
      if (res.success === true) {
        LoadPage('allIpAddresses', dispatch);
        notification.success({
          message: 'Successfully Added!',
          description: 'Data is Successfully Added!',
        });
      } else if (res.success === false) {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    }).catch((err) => {
      notification.error({
        message: 'Something Went Wrong!',
        description: `Got Error with status code = ${err.status}!`,
      });
    });

    /* fetch(`${state.apiUrl}/masterdata/rams`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //  Authorization: `Bearer ${state.token}`,
      },
      body: JSON.stringify(AddIPAddressBody),
    }).then((res) => res.json()).then((res) => {
      console.log('Add form res - ', res);
      if (res.success === true) {
        message.success('Successfully RAM added!');
      }
    }); */
  };

  useEffect(() => {
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Add IP Address
            </Col>
            <Col span="10" flex="row" align="end" />
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24">
          <Form
            name="basic"
            layout="vertical"
            onFinish={createNewIPAddressSuccess}
            autoComplete="off"
            initialValues={{
              ipversion: '',
              company_name: '',
              pool_number: '24',
            }}
          >
            <Row gutter="30">
              <Col span="12">
                <Form.Item
                  label="Company Name"
                  name="company_name"
                  rules={[
                    {
                      required: true,
                      message: 'Select Company name',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Company Name"
                  >
                    <Option value="IBEE India">IBEE India</Option>
                    <Option value="IBEE USA">IBEE USA</Option>
                    <Option value="Vajrasila Infrastructure">Vajrasila Infrastructure</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter="30" className="flex-direction-column">
              <Col span="6">
                <Form.Item
                  label="IP Version"
                  name="ip_version"
                  rules={[
                    {
                      required: true,
                      message: 'Select IP Version',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select IP Version"
                  >
                    <Option value="IPV4">IPV4</Option>
                    <Option value="IPV6">IPV6</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter="30">
              <Col span="12">
                <Row align="middle" className="w-100">
                  <Col span="17">
                    <Form.Item
                      label="IP Address"
                      name="ip_addr"
                      rules={[
                        {
                          required: true,
                          message: 'Enter IP Address',
                        },
                      ]}
                    >
                      <Input className="w-100" placeholder="10.203.0..." />
                    </Form.Item>
                  </Col>
                  <Col span="1" className="text-center align-self-center">
                    .
                  </Col>
                  <Col span="6">
                    <Form.Item
                      label="Pool Number"
                      name="pool"
                      rules={[
                        {
                          required: true,
                          message: 'Select Pool Number',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Pool Number"
                      >
                        <Option value="48">48</Option>
                        <Option value="24">24</Option>
                        <Option value="23">23</Option>
                        <Option value="22">22</Option>
                        <Option value="21">21</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

              </Col>

              <Col span="24" className="my-1 py-2 border-top">
                <Button type="default" size="large" className="me-1 px-2">
                  Cancel
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="me-1 btn-success px-4"
                  htmlType="submit"
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default AddIPAddress;
