import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Breadcrumb,
  Table,
  Button,
  Select,
  notification,
  Tag,
  Form,
  /* Modal,
  Tag,
  notification, */
} from 'antd';

import { IoAdd, IoPencil, IoTrash } from 'react-icons/io5';

// Custom imports
import './customers.scss';
import Modal from 'antd/lib/modal/Modal';
import AppContext from '../../context/appContext';
import { BREADCRUMB, EDIT_FORM_DATA } from '../../context/types';
import LoadPage from '../../common/loadPage';
import { DELETE, GET } from '../../common/crud';

const ListCustomers = () => {
  const [data, setData] = useState(null);
  const [deleteData, setDeleteData] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [allCustomers, setAllCustomers] = useState(null);
  const [customersOptions, setCustomersOptions] = useState(null);

  const [state, dispatch] = useContext(AppContext);

  const { Option } = Select;

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>

      <Breadcrumb.Item>All Customers</Breadcrumb.Item>
    </Breadcrumb>
  );

  //  Customer Filter
  const CustomerFilter = (value) => {
    console.log('Customer filter value - ', value);
    if (value !== 'all') {
      const filterData = allCustomers.filter((itm) => itm.display_name === value);
      console.log('Filtered data - ', filterData);
      setData(filterData);
    } else {
      setData(allCustomers);
    }
  };

  //  Session Storing customer id
  const goToCustomerDetail = (record) => {
    sessionStorage.setItem('customerId', JSON.stringify(record._id));
    LoadPage('customerDetail', dispatch);
  };

  //  Updat Customer
  const updateRowData = (record) => {
    console.log('Update customer record = ', record);

    dispatch({
      type: EDIT_FORM_DATA,
      payload: {
        editFormData: record,
        editPageName: 'addCustomer',
      },
    });
    LoadPage('addCustomer', dispatch);
  };

  //  Updat Customer
  const deleteRowData = (record) => {
    console.log('Delete customer record = ', record);
    setDeleteModal(true);
    setDeleteData(record);
  };

  //  Customers Table Cols
  const customersCols = [
    {
      title: 'Type',
      dataIndex: 'customer_type',
      key: 'customer_type',
      width: 130,
    },
    {
      title: 'Display Name',
      dataIndex: 'display_name',
      key: 'display_name',
      width: 100,
      render: (_, record) => (
        <>
          <Button type="link" className="p-0" onClick={() => goToCustomerDetail(record)}>
            {record.display_name}
          </Button>
        </>

      ),
    },
    {
      title: 'Mobile',
      dataIndex: 'phone',
      key: 'phone',
      className: 'text-truncate mobile-col',
      render: (_, record) => (
        <>{record.phone ? record.phone : 'N/A'}</>

      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      className: 'text-truncate email-col',
    },
    {
      title: 'GST ',
      dataIndex: 'gst_treatment',
      key: 'gst_treatment',
      width: 130,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (text) => (
        <>
          {text && text.toLowerCase() === 'active' ? (
            <Tag color="green">{text}</Tag>
          ) : (
            <>
              <Tag color="red">{text}</Tag>
            </>
          )}
        </>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      width: 130,
      render: (id, record) => (
        <>
          <Button
            type="default"
            className="me-1 btn-outline-secondary"
            onClick={() => updateRowData(record)}
            icon={<IoPencil />}
          />
          <Button
            type="default"
            className="me-1 btn-outline-danger"
            onClick={() => deleteRowData(record)}
            icon={<IoTrash />}
          />
        </>
      ),
    },
    /* {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      width: 120,
      render: (id, record) => (
        <>
          <Button
            type="default"
            className="me-1 btn-outline-secondary"
            onClick={() => updateRowData(record)}
            icon={<IoPencil />}
          />
          <Button
            type="default"
            className="me-1 btn-outline-danger"
            onClick={() => deleteRowData(id, record)}
            icon={<IoTrash />}
          />
        </>
      ),
    }, */
  ];

  //  Get Customer List
  const customersList = () => {
    GET('customers', state.token).then((res) => {
      console.log('Get Customers res - ', res);
      if (res.success === true) {
        const custOptionArray = [];
        res.data.map((item) => {
          const eachOption = (
            <Option key={item._id} value={item.display_name}>
              {item.display_name}
            </Option>
          );
          return custOptionArray.push(eachOption);
        });
        setCustomersOptions(custOptionArray);
        setData(res.data);
        setAllCustomers(res.data);
      } else {
        notification.error({
          message: 'Something went wrong!',
          description: `Something went wrong, status code = ${res.status}`,
        });
      }
    });
  };

  //  Cancel Modal
  const cancelModal = () => {
    setDeleteModal(false);
    setDeleteData(null);
  };

  //  Delete Customer
  const deleteRow = () => {
    setDeleteModal(false);
    DELETE('customers', deleteData._id, state.token).then((res) => {
      if (res.success === true) {
        setDeleteData(null);
        notification.success({
          message: 'Deleted Successfully!',
          description: 'Data is Successfully Deleted!',
        });
        customersList();
      } else if (res.success === false) {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  useEffect(() => {
    setData('');
    customersList();

    // Bredcrumb
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              All Customers
            </Col>
            <Col span="10" flex="row" align="end" className="text-end">
              <Button
                type="primary"
                onClick={() => LoadPage('addDiscount', dispatch)}
                disabled
              >
                <IoAdd />
                {' '}
                Add Customer
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span={24} className="mb-2">
          <Row gutter={30}>
            <Col>
              <Form.Item label="Display Name: ">
                <Select
                  placeholder="Select Location"
                  optionFilterProp="children"
                  onChange={CustomerFilter}
                  style={{ width: '360px' }}
                  defaultValue="all"
                >
                  <Option value="all">All</Option>
                  {customersOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span="24">
          <Table columns={customersCols} dataSource={data} />
        </Col>
      </Row>

      {/* Delete confirmation */}
      <Modal
        title="Confirm if you really want to delete?"
        visible={deleteModal}
        onCancel={cancelModal}
        okText="Delete"
        okButtonProps={{ className: 'btn-danger' }}
        onOk={deleteRow}
      >
        <Row>
          <Col span="24">
            <p>
              <span style={{ fontSize: '20px', fontWeight: 500, color: 'var(--bs-danger)' }}>
                {deleteData ? deleteData.display_name : ''}
              </span>
            </p>
            <p>
              Do you really want to
              delete above
              ?
            </p>
            <p />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ListCustomers;
