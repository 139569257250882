import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Breadcrumb,
  Form,
  Select,
  Switch,
  Table,
  Modal,
  notification,
  Tag,
  Input,
} from 'antd';

import { IoPencil, IoAdd, IoTrash } from 'react-icons/io5';

// Custom imports
import AppContext from '../../../context/appContext';
import { BREADCRUMB } from '../../../context/types';
import LoadPage from '../../../common/loadPage';
import { DELETE, GET, PUT } from '../../../common/crud';

const AllRAMs = () => {
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [ramEccValue, setRamEccValue] = useState(false);
  //  const [ramSizeValue, setRamSizeValue] = useState(updateData.size);

  const [state, dispatch] = useContext(AppContext);

  const { Option } = Select;

  const [UpdateForm] = Form.useForm();

  const handleCancel = () => {
    setUpdateModal(false);
    UpdateForm.resetFields();
    //  setRamEccValue(false);
  };

  // Confirm Modal Delete
  const deleteItem = (id) => {
    console.log('id - ', id);
    setDeleteId(id);
    setDeleteModal(true);
  };

  // Cancel Delete Modal
  const cancelModal = () => {
    setDeleteModal(false);
  };

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>

      <Breadcrumb.Item>All RAMs</Breadcrumb.Item>
    </Breadcrumb>
  );

  //  Update Form
  const updateForm = async (id, record) => {
    console.log('Update form id - ', id, record);
    /* let updateItem;
    await data.map((item) => {
      if (item.key === id) {
        updateItem = item;
      }
      console.log('Update item - ', updateItem);
      return setUpdateData(updateItem);
    });
    setRamSizeValue(updateItem.size);
    setRamEccValue(updateItem.ecc === 'true'); */
    setRamEccValue(record.ecc);
    UpdateForm.setFieldsValue(record);

    setUpdateData(record);
    setUpdateModal(true);
  };

  // Supplier Table Columns
  const currencyTableColumns = [
    {
      title: 'Size (GB)',
      dataIndex: 'size',
      key: 'size',
      width: 180,
    },
    {
      title: 'Speed',
      dataIndex: 'speed',
      key: 'speed',
      width: 120,
    },
    {
      title: 'Technology',
      dataIndex: 'technology',
      key: 'technology',
    },
    {
      title: 'Type',
      dataIndex: 'ram_type',
      key: 'ram_type',
    },
    {
      title: 'ECC',
      dataIndex: 'ecc',
      key: 'ecc',
      width: 100,
      render: (text) => (
        <>
          {text === true ? (
            <Tag color="green">True</Tag>
          ) : (
            <Tag color="red">False</Tag>
          )}
        </>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      render: (id, record) => (
        <div className="text-center">
          <Button
            type="default"
            icon={<IoPencil color="#777" />}
            onClick={() => updateForm(id, record)}
            className="me-1"
          />
          <Button
            type="default"
            className="me-1 btn-outline-danger"
            onClick={() => deleteItem(record)}
            icon={<IoTrash />}
          />
        </div>
      ),
    },
  ];

  //  All RAMs Table Data
  const tableData = () => {
    GET('masterdata/rams', state.token).then((res) => {
      //  setLoading(false);
      console.log('Get res - ', res);
      //  const tableDataArray = [];
      if (res.success === true) {
        /* res.data.rams.map((mapData) => {
          const newData = {
            key: mapData._id,
            size: mapData.size,
            speed: mapData.speed,
            technology: mapData.technology,
            ramType: mapData.ram_type,
            ecc: mapData.ecc ? 'true' : 'false',
            actions: mapData._id,
          };
          return tableDataArray.push(newData);
        }); */
        setData(res.data.rams);
      } else if (res.success === false) {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  };

  //  Ram Size onChange
  const ramSizeOnChange = (value) => {
    //  setRamSizeValue(value);
    console.log('RamSize change - ', value);
  };

  // Currency onChange
  const currencyOnChange = (value) => {
    console.log('Currency selected on change - ', value);
  };

  //  Ram ECC onChange
  const ramEccOnChange = (value) => {
    setRamEccValue(value);
    console.log('Ram ECC on Change - ', value);
  };

  //  Update RAM Data
  const updateRAMSuccess = (values) => {
    const updateFormBody = {
      size: Number(values.size),
      speed: values.speed,
      technology: values.technology,
      ram_type: values.ram_type,
      ecc: values.ecc,
    };
    UpdateForm.resetFields();

    PUT('masterdata/rams', updateData._id, state.token, updateFormBody).then((res) => {
      //  setRamEccValue('');
      setUpdateModal(false);
      if (res.success === true) {
        setUpdateData([]);
        setDeleteId(null);
        notification.success({
          message: 'Successfully Updated!',
          description: 'Data is Successfully Updated!',
        });
        tableData();
      } else {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  const DeleteSuccess = () => {
    setDeleteModal(false);
    DELETE('masterdata/rams', deleteId._id, state.token).then((res) => {
      if (res.success === true) {
        setUpdateData([]);
        setDeleteId(null);
        notification.success({
          message: 'Deleted Successfully!',
          description: 'Data is Successfully Deleted!',
        });
        tableData();
      } else {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  useEffect(() => {
    tableData();

    console.log('RAM ECC Value - ', ramEccValue);

    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, [ramEccValue]);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              All RAMs
            </Col>
            <Col span="10" flex="row" align="end" className="text-end">
              <Button
                type="primary"
                size="large"
                onClick={() => LoadPage('addRam', dispatch)}
              >
                <IoAdd />
                {' '}
                Add RAM
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24" className="">
          <Table
            pagination={false}
            columns={currencyTableColumns}
            dataSource={data}
          />
        </Col>
      </Row>

      <Modal
        width={800}
        footer={false}
        title="Update RAM"
        visible={updateModal}
        onCancel={handleCancel}
      >
        <Form
          form={UpdateForm}
          name="basic"
          layout="vertical"
          onFinish={updateRAMSuccess}
          autoComplete="off"
        >
          <Row gutter="30" className="flex-direction-column">
            <Col span="12">
              <Form.Item
                label="RAM Make"
                name="make"
                rules={[
                  { required: true, message: 'Enter RAM make' },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select disk make"
                  optionLabelProp="children"
                >
                  <Option value="Micron">Micron</Option>
                  <Option value="Corsair">Corsair</Option>
                  <Option value="Kingston">Kingston</Option>
                  <Option value="Samsung">Samsung</Option>
                  <Option value="Crucial">Crucial</Option>
                  <Option value="Transcend">Transcend</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="RAM Model"
                name="model"
              >
                <Input placeholder="Enter RAM model" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Row>
                <Col span="21">
                  <Form.Item
                    label="RAM Size"
                    name="size"
                    rules={[
                      {
                        required: true,
                        message: 'Please select RAM Size',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select RAM Size"
                      optionFilterProp="children"
                      onChange={ramSizeOnChange}
                    >
                      <Option value="4">4</Option>
                      <Option value="8">8</Option>
                      <Option value="16">16</Option>
                      <Option value="32">32</Option>
                      <Option value="64">64</Option>
                      <Option value="128">128</Option>
                      <Option value="256">256</Option>
                      <Option value="512">512</Option>
                    </Select>

                  </Form.Item>
                </Col>
                <Col
                  span="3"
                  className="d-inline-flex bg-light border align-items-center justify-content-center"
                  style={{ height: '32px', marginTop: '30px' }}
                >
                  GB
                </Col>
              </Row>

            </Col>
            <Col span="12">
              <Form.Item
                label="RAM Speed"
                name="speed"
                rules={[
                  {
                    required: true,
                    message: 'Enter RAM Speed',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select RAM Speed"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value="800 MHz">800 MHz</Option>
                  <Option value="1333 MHz">1333 MHz</Option>
                  <Option value="1600 MHz">1600 MHz</Option>
                  <Option value="2400 MHz">2400 MHz</Option>
                  <Option value="2666 MHz">2666 MHz</Option>
                  <Option value="2933 MHz">2933 MHz</Option>
                  <Option value="3000 MHz">3000 MHz</Option>
                  <Option value="3200 MHz">3200 MHz</Option>
                  <Option value="3600 MHz">3600 MHz</Option>
                  <Option value="4000 MHz">4000 MHz</Option>
                  <Option value="4400 MHz">4400 MHz</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="RAM Technology"
                name="technology"
                rules={[
                  {
                    required: true,
                    message: 'Select RAM Technology',
                  },
                ]}
              >
                <Select
                  placeholder="Select RAM Technology"
                  optionFilterProp="children"
                  onChange={currencyOnChange}
                  filterOption={(input, option) => option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value="DDR2">DDR2</Option>
                  <Option value="DDR3">DDR3</Option>
                  <Option value="DDR4">DDR4</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="RAM Type"
                name="ram_type"
                rules={[
                  {
                    required: true,
                    message: 'Enter RAM Type',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select RAM Type"
                  optionFilterProp="children"
                  onChange={currencyOnChange}
                  filterOption={(input, option) => option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0}
                >
                  <Option value="UDIMM">UDIMM</Option>
                  <Option value="RDIMM">RDIMM</Option>
                  997
                  <Option value="LRDIMM">LRDIMM</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span="12">
              <Form.Item
                label="RAM ECC"
                name="ecc"
                rules={[
                  {
                    required: true,
                    message: 'Enter RAM ECC',
                  },
                ]}
              >
                <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={ramEccOnChange} checked={ramEccValue} />
              </Form.Item>
            </Col>

            <Col span="24" className="my-1 py-2 border-top">
              <Button type="default" size="large" className="me-1 px-2" onClick={() => setUpdateModal(false)}>
                Cancel
              </Button>
              <Button
                type="primary"
                size="large"
                className="me-1 btn-success px-4"
                htmlType="submit"
              >
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* Delete confirmation */}
      <Modal
        title="Confirm if you really want to delete?"
        visible={deleteModal}
        onCancel={cancelModal}
        okText="Delete"
        okButtonProps={{ className: 'btn-danger' }}
        onOk={DeleteSuccess}
      >
        <Row>
          <Col span="24">
            <p>
              <span style={{ fontSize: '20px', fontWeight: 500, color: 'var(--bs-danger)' }}>{deleteId ? `${deleteId.size}/${deleteId.speed}/${deleteId.technology}` : ''}</span>
            </p>
            <p>
              Do you really want to
              delete above RAM?
            </p>
            <p />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default AllRAMs;
