import { LOAD_PAGE } from '../context/types';

const LoadPage = (name, dispatch) => {
  document.getElementById('main').scrollTo(0, 0, 'smooth');
  dispatch({
    type: LOAD_PAGE,
    payload: {
      page: name,
    },
  });
};

export default LoadPage;
