import React, { useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Input,
  Button,
  Breadcrumb,
  Form,
  Select,
  notification,
} from 'antd';

// Custom imports
import AppContext from '../../../context/appContext';
import { BREADCRUMB } from '../../../context/types';
import LoadPage from '../../../common/loadPage';
import { POST } from '../../../common/crud';

const AddProcessor = () => {
  const [state, dispatch] = useContext(AppContext);

  const { Option } = Select;

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('allProcessors', dispatch)}
        >
          All Processors
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Add Processor</Breadcrumb.Item>
    </Breadcrumb>
  );

  // Create Location SUCCESS
  const createNewProcessorSuccess = (values) => {
    console.log('New Processor values - ', values);

    const processorFormBody = {
      make: values.processorMake,
      model: values.processorModel,
      name: values.processorName,
      cores: Number(values.processorCores),
      base_frequency: values.processorBaseFrequency,
      turbo_frequency: values.processorTurboFrequency,
      tdp: Number(values.processorTdp),
    };

    POST('masterdata/processors', state.token, processorFormBody).then((res) => {
      if (res.success === true) {
        LoadPage('allProcessors', dispatch);
        notification.success({
          message: 'Successfully Added!',
          description: 'Data is Successfully Added!',
        });
      } else {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  // Currency onChange
  const currencyOnChange = (value) => {
    console.log('Currency selected on change - ', value);
  };

  useEffect(() => {
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Add Processor
            </Col>
            <Col span="10" flex="row" align="end" />
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24">
          <Form
            name="basic"
            layout="vertical"
            onFinish={createNewProcessorSuccess}
            autoComplete="off"
            initialValues={{
              processorMake: '',
              processorModel: '',
              processorName: '',
              processorCores: '',
              processorBaseFrequency: '',
              processorTurboFrequency: '',
              processorTdp: '',
            }}
          >
            <Row gutter="30" className="flex-direction-column">
              <Col span="12">
                <Form.Item
                  label="Processor Make"
                  name="processorMake"
                  rules={[
                    {
                      required: true,
                      message: 'Select Processor Make',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Processor Make"
                    optionFilterProp="children"
                    onChange={currencyOnChange}
                    filterOption={(input, option) => option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value="Intel">Intel</Option>
                    <Option value="AMD">AMD</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Processor Model"
                  name="processorModel"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Processor Model',
                    },
                  ]}
                >
                  <Input placeholder="Enter Processor Model like (E5-2430v3)..." />
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Processor Name"
                  name="processorName"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Processor Name',
                    },
                  ]}
                >
                  <Input placeholder="Enter Processor Name like (Intel Xeon E5-2430v3)..." />
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Processor Cores"
                  name="processorCores"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Processor Cores',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Processor Cores"
                    optionFilterProp="children"
                    onChange={currencyOnChange}
                    filterOption={(input, option) => option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value="2">2</Option>
                    <Option value="4">4</Option>
                    <Option value="6">6</Option>
                    <Option value="8">8</Option>
                    <Option value="10">10</Option>
                    <Option value="12">12</Option>
                    <Option value="16">16</Option>
                    <Option value="18">18</Option>
                    <Option value="32">32</Option>
                    <Option value="64">64</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Processor Base Frequency"
                  name="processorBaseFrequency"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Processor Base Frequency',
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Processor Base Frequency..."
                    addonAfter="GHz"
                  />
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Processor Turbo Frequency"
                  name="processorTurboFrequency"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Processor Turbo Frequency',
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Processor Turbo Frequency..."
                    addonAfter="GHz"
                  />
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Processor TDP"
                  name="processorTdp"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Processor TDP',
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Processor TDP in Watts..."
                    addonAfter="Watts"
                  />
                </Form.Item>
              </Col>

              <Col span="24" className="my-1 py-2 border-top">
                <Button type="default" size="large" className="me-1 px-2">
                  Cancel
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="me-1 btn-success px-4"
                  htmlType="submit"
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default AddProcessor;
