import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Breadcrumb,
  Select,
  Table,
  Modal,
  notification,
  Tag,
  Form,
} from 'antd';

import { IoPencil, IoAdd, IoTrash } from 'react-icons/io5';

// Custom imports
import AppContext from '../../context/appContext';
import { BREADCRUMB, EDIT_FORM_DATA } from '../../context/types';
import LoadPage from '../../common/loadPage';
import { DELETE, GET } from '../../common/crud';

const AllSuppliers = () => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState([]);
  const [namesList, setNamesList] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [locationsList, setLocationsList] = useState(null);
  const [supplierAll, setSupplierAll] = useState(null);
  const [allNamesList, setAllNamesList] = useState(null);

  const [state, dispatch] = useContext(AppContext);

  const { Option } = Select;

  // Search suppliers onChange
  const searchSuppliersOnChange = async (value) => {
    console.log('Supplier filter value - ', value);

    if (value === 'all') {
      setData(namesList);
    } else {
      const filTableArray = [];
      const filterData = await namesList.filter((sup) => sup.display_name === value);
      filterData.map((mapData) => {
        const email = mapData.contacts.map((em) => (em.contact_type === 'Primary' ? em.email : ''));
        const phone = mapData.contacts.map((em) => (em.contact_type === 'Primary' ? em.phone : ''));

        const newDataContent = {
          ...mapData,
          email,
          phone,
        };
        return filTableArray.push(newDataContent);
      });
      setData(filTableArray);
    }
  };

  // Go to Supplier Statmenet Page
  const goToSupplierStatement = (id) => {
    console.log('Go to supplier statement id - ', id);
    //  LoadPage('supplierStatement', dispatch);
  };

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>

      <Breadcrumb.Item>Add Supplier</Breadcrumb.Item>
    </Breadcrumb>
  );

  //  Update Row Data
  const updateRowData = async (id, record) => {
    console.log('Update id, record data - ', id, record);
    /* const upData = await namesList.filter((supl) => supl._id === id);
    console.log('Update data row record - ', id, upData);

    upData.map((res) => {
      dispatch({
        type: EDIT_FORM_DATA,
        payload: {
          editFormData: res,
          editPageName: 'addSupplier',
        },
      });
      LoadPage('addSupplier', dispatch);
      return true;
    }); */

    dispatch({
      type: EDIT_FORM_DATA,
      payload: {
        editFormData: record,
        editPageName: 'addSupplier',
      },
    });

    LoadPage('addSupplier', dispatch);
  };

  //  Delete Row Data
  const deleteRowData = (record) => {
    console.log('Delete data row id - ', record);
    setDeleteModal(true);
    setDeleteId(record);
  };

  // Supplier Table Columns
  const supplierTableColumns = [
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      width: 150,
    },
    {
      title: 'Type',
      dataIndex: 'supplier_type',
      key: 'supplier_type',
      width: 125,
    },
    {
      title: 'Display Name',
      dataIndex: 'display_name',
      key: 'display_name',
      width: 120,
      className: 'text-truncate',
      render: (text, record) => (
        <Button type="link" onClick={() => goToSupplierStatement(record)}>
          {text}
        </Button>
      ),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: 150,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 150,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (text) => (
        <>
          {text === 'active' ? (
            <Tag color="green">{text}</Tag>
          ) : (
            <Tag color="red">{text}</Tag>
          )}
        </>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 150,
      render: (id, record) => (
        <>
          <Button
            type="default"
            className="me-1 btn-outline-secondary"
            onClick={() => updateRowData(id, record)}
            icon={<IoPencil />}
          />
          <Button
            type="default"
            className="me-1 btn-outline-danger"
            onClick={() => deleteRowData(record)}
            icon={<IoTrash />}
          />
        </>
      ),
    },
  ];

  //  All Locations List
  const tableData = () => {
    GET('suppliers', state.token).then((res) => {
      //  setLoading(false);
      console.log('Get Suppliers res - ', res);
      const tableDataArray = [];

      if (res.success === true) {
        if (res.data.length > 0) {
          res.data.map((mapData) => {
            const email = mapData.contacts.map((em) => (em.contact_type === 'Primary' ? em.email : ''));
            const phone = mapData.contacts.map((em) => (em.contact_type === 'Primary' ? em.phone : ''));

            const newDataContent = {
              ...mapData,
              email: email || '',
              phone: phone || '',
              /* email,
              phone, */
            };
            return tableDataArray.push(newDataContent);
          });
        }
        setNamesList(tableDataArray);
        setAllNamesList(tableDataArray);
        setData(tableDataArray);
        setSupplierAll(tableDataArray);
      } else if (res.success === false) {
        notification.error({
          message: 'Something went wrong!',
          description: 'Something went wrong',
        });
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  };

  // Delete Row Data
  const deleteData = () => {
    setDeleteModal(false);
    DELETE('suppliers', deleteId._id, state.token).then((res) => {
      if (res.success === true) {
        setDeleteId(null);
        notification.success({
          message: 'Deleted Successfully!',
          description: 'Data is Successfully Deleted!',
        });
        tableData();
      } else {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  //  Get Locations
  const getLocations = async () => {
    GET('masterdata/locations', state.token).then(async (res) => {
      //  setLoading(false);
      console.log('Get res - ', res);
      if (res.success === true) {
        console.log('Location list - ', res.data.locations);
        const locationListArray = (await res.data.locations.map((locItm) => (<Option key={locItm._id} value={`${locItm.name}`}>{`${locItm.name}, ${locItm.code}`}</Option>)));
        console.log('Location list after adding - ', locationListArray);
        setLocationsList(locationListArray);
      } else if (res.success === false) {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  };

  //  Location on change
  const locationOnChange = (value) => {
    console.log('Location on change - ', value);
    const tableDataArrayChange = [];
    if (value === 'all') {
      setData(supplierAll);
      setNamesList(allNamesList);
    } else {
      GET(`suppliers?location=${value}`, state.token).then((res) => {
        console.log('Supplier location - ', res);

        if (res.success === true && res.data.length > 0) {
          res.data.map((mapDatach) => {
            const email = mapDatach.contacts.map((em) => (em.contact_type === 'Primary' ? em.email : ''));
            const phone = mapDatach.contacts.map((em) => (em.contact_type === 'Primary' ? em.phone : ''));

            const newDataContentChange = {
              ...mapDatach,
              email,
              phone,
            };

            console.log('On change data - ', newDataContentChange);

            return tableDataArrayChange.push(newDataContentChange);
          });
          setNamesList(tableDataArrayChange);
          setData(tableDataArrayChange);
        } else {
          setData([]);
          setNamesList([]);
        }
      });
    }
  };

  // Cancel Delete Modal
  const cancelModal = () => {
    setDeleteModal(false);
  };

  useEffect(() => {
    tableData();
    getLocations();

    //  Breadcrumb
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Add Supplier
            </Col>
            <Col span="10" flex="row" align="end" className="text-end">
              <Button
                type="primary"
                size="large"
                onClick={() => LoadPage('addSupplier', dispatch)}
              >
                <IoAdd />
                {' '}
                Add Supplier
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">

        <Col span="24">
          <Row gutter="30">
            <Col span="12">
              <Form.Item label="Location:">
                <Select
                  showSearch
                  className="w-100"
                  placeholder="Select a person"
                  optionFilterProp="children"
                  onChange={locationOnChange}
                  defaultValue="all"
                >
                  <Option value="all">All</Option>
                  {locationsList}
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item label="DisplayName:">
                <Select
                  showSearch
                  className="w-100"
                  placeholder="Select a person"
                  optionFilterProp="children"
                  onChange={searchSuppliersOnChange}
                  defaultValue="all"
                >
                  <Option value="all">All</Option>
                  {
             namesList.length > 0 ? namesList.map((sup) => (
               <Option key={sup._id} value={sup.display_name}>{sup.display_name}</Option>
             )) : ''
           }
                </Select>
              </Form.Item>
            </Col>
          </Row>

        </Col>
        <Col span="24" className="mt-2">
          <Table
            columns={supplierTableColumns}
            dataSource={data}
            filter
          />
        </Col>
      </Row>

      <Modal
        title="Confirm if you really want to delete?"
        visible={deleteModal}
        onCancel={cancelModal}
        okText="Delete"
        okButtonProps={{ className: 'btn-danger' }}
        onOk={deleteData}
      >
        <Row>
          <Col span="24">
            <p>
              <span style={{ fontSize: '20px', fontWeight: 500, color: 'var(--bs-danger)' }}>
                {deleteId ? `${deleteId.display_name}` : ''}
              </span>
            </p>
            <p>
              Do you really want to
              delete above Supplier?
            </p>
            <p />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default AllSuppliers;
