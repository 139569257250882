import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Input,
  Button,
  Breadcrumb,
  Form,
  Select,
  Table,
  Modal,
  notification,
} from 'antd';

import { IoPencil, IoAdd, IoTrash } from 'react-icons/io5';

// Custom imports
import AppContext from '../../../context/appContext';
import { BREADCRUMB } from '../../../context/types';
import LoadPage from '../../../common/loadPage';
import { GET, DELETE, PUT } from '../../../common/crud';

const AllIPAddress = () => {
  const [data, setData] = useState();
  const [updateData, setUpdateData] = useState();
  const [updateId, setUpdateId] = useState();
  const [deleteId, setDeleteId] = useState();
  //  const [ipVersionValue, setIpVersionValue] = useState();
  //  const [ipCompanyNameValue, setIpCompanyNameValue] = useState();
  //  const [ipPoolNumberValue, setIpPoolNumberValue] = useState();
  //  const [ipAddrValue, setIpAddrValue] = useState();

  const [state, dispatch] = useContext(AppContext);
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [UpdateForm] = Form.useForm();

  const { Option } = Select;

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>All IP Addresses</Breadcrumb.Item>
    </Breadcrumb>
  );

  // On Press OK
  const handleOk = () => {
    setUpdateModal(false);
  };

  // Show Modal
  const handleCancel = () => {
    setUpdateModal(false);
    UpdateForm.resetFields();
  };

  //  Update Form
  const updateForm = async (id, record) => {
    console.log('Update form id - ', id, record);
    /* const updateItem = {
      _id: id,
      company_name: record.company_name,
      ip_version: record.ip_version,
      ip_addr: record.ip_addr,
      pool: record.pool,
    };
    console.log('Update id - ', id);
    setIpVersionValue(record.ip_version);
    setIpCompanyNameValue(record.company_name);
    setIpPoolNumberValue(record.pool);
    setIpAddrValue(record.ip_addr);
    setUpdateData(updateItem); */
    setUpdateId(record._id);
    UpdateForm.setFieldsValue(record);
    setUpdateModal(true);
  };

  //  Delete Form
  const deleteItem = (record) => {
    console.log('Delete item record - ', record);
    setDeleteId(record);
    setDeleteModal(true);
  };

  // Customers Table Columns
  const customersTableColumns = [
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
      width: 300,
    },
    {
      title: 'IP Version',
      dataIndex: 'ip_version',
      key: 'ip_version',
      width: 150,
    },
    {
      title: 'IP Address',
      dataIndex: 'ip_addr',
      key: 'ip_addr',
      width: 200,
    },
    {
      title: 'PoolNumber',
      dataIndex: 'pool',
      key: 'pool',
      width: 150,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      render: (id, record) => (
        <div className="text-center">
          <Button
            type="default"
            icon={<IoPencil color="#777" />}
            onClick={() => updateForm(id, record)}
            className="me-1"
          />
          <Button
            type="default"
            className="me-1 btn-outline-danger"
            onClick={() => deleteItem(record)}
            icon={<IoTrash />}
          />
        </div>
      ),
    },
  ];

  //  All RAMs Table Data
  const tableData = () => {
    GET('masterdata/ip_addresses', state.token).then((res) => {
      //  setLoading(false);
      console.log('Get IP Addresses - ', res);
      //  const tableDataArray = [];
      if (res.success === true) {
        /* res.data.ip_addresses.map((mapData) => {
          const newData = {
            key: mapData._id,
            company_name: mapData.company_name,
            ip_version: mapData.ip_version,
            ip_addr: mapData.ip_addr,
            pool: mapData.pool,
            actions: mapData._id,
          };
          return tableDataArray.push(newData);
        });
        setData(tableDataArray); */
        setData(res.data.ip_addresses);
      } else if (res.success === false) {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  };

  //  Update IP Address Success
  const updateIPAddressSuccess = (values) => {
    console.log('Update IP Address values - ', values);
    setUpdateModal(false);
    const updateIpAddressBody = {
      _id: updateId,
      company_name: values.company_name,
      ip_version: values.ip_version,
      ip_addr: values.ip_addr,
      pool: Number(values.pool),
    };
    PUT('masterdata/ip_addresses', updateId, state.token, updateIpAddressBody).then((res) => {
      if (res.success === true) {
        setUpdateData([]);
        setDeleteId(null);
        notification.success({
          message: 'Successfully Updated!',
          description: 'Data is Successfully Updated!',
        });
        tableData();
      } else {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  // Cancel Delete Modal
  const cancelModal = () => {
    setDeleteModal(false);
  };

  //  Delete IP Address Delete
  const deleteRowData = () => {
    setDeleteModal(false);
    DELETE('masterdata/ip_addresses', deleteId._id, state.token).then((res) => {
      if (res.success === true) {
        setUpdateData([]);
        setDeleteId(null);
        notification.success({
          message: 'Deleted Successfully!',
          description: 'Data is Successfully Deleted!',
        });
        tableData();
      } else {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  useEffect(() => {
    tableData();

    console.log('Update data - ', updateData);

    //  Breadcrum
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, [updateData]);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              All IP Addresses
            </Col>
            <Col span="10" flex="row" align="end" className="text-end">
              <Button
                type="primary"
                size="large"
                onClick={() => LoadPage('addIpAddress', dispatch)}
              >
                <IoAdd />
                {' '}
                Add IP Address
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-2">
        <Col span="24" className="">
          <Table
            pagination={false}
            columns={customersTableColumns}
            dataSource={data}
          />
        </Col>
      </Row>

      <Modal
        title="Update IP Address"
        visible={updateModal}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        footer={false}
      >
        <Form
          form={UpdateForm}
          name="basic"
          layout="vertical"
          onFinish={updateIPAddressSuccess}
          autoComplete="off"
        >
          <Row gutter="30">
            <Col span="12">
              <Form.Item
                label="Company Name"
                name="company_name"
                rules={[
                  {
                    required: true,
                    message: 'Select Company name',
                  },
                ]}
              >
                <Select
                  placeholder="Select Company Name"
                >
                  <Option value="IBEE India">IBEE India</Option>
                  <Option value="IBEE USA">IBEE USA</Option>
                  <Option value="Vajrasila Infrastructure">Vajrasila Infrastructure</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter="30" className="flex-direction-column">
            <Col span="6">
              <Form.Item
                label="IP Version"
                name="ip_version"
                rules={[
                  {
                    required: true,
                    message: 'Select IP Version',
                  },
                ]}
              >
                <Select
                  placeholder="Select IP Version"
                >
                  <Option value="IPV4">IPV4</Option>
                  <Option value="IPV6">IPV6</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter="30">
            <Col span="18">
              <Row align="middle" className="w-100">
                <Col span="17">
                  <Form.Item
                    label="IP Address"
                    name="ip_addr"
                    rules={[
                      {
                        required: true,
                        message: 'Enter IP Address',
                      },
                    ]}
                  >
                    <Input className="w-100" placeholder="10.203.0..." />
                  </Form.Item>
                </Col>
                <Col span="1" className="text-center align-self-center">
                  .
                </Col>
                <Col span="6">
                  <Form.Item
                    label="Pool Number"
                    name="pool"
                    rules={[
                      {
                        required: true,
                        message: 'Select Pool Number',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Pool Number"
                    >
                      <Option value="48">48</Option>
                      <Option value="24">24</Option>
                      <Option value="23">23</Option>
                      <Option value="22">22</Option>
                      <Option value="21">21</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

            </Col>

            <Col span="24" className="my-1 py-2 border-top">
              <Button type="default" size="large" className="me-1 px-2" onClick={() => setUpdateModal(false)}>
                Cancel
              </Button>
              <Button
                type="primary"
                size="large"
                className="me-1 btn-success px-4"
                htmlType="submit"
              >
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      {/* Delete confirmation */}
      <Modal
        title="Confirm if you really want to delete?"
        visible={deleteModal}
        onCancel={cancelModal}
        okText="Delete"
        okButtonProps={{ className: 'btn-danger' }}
        onOk={deleteRowData}
      >
        <Row>
          <Col span="24">
            <p>
              <span style={{ fontSize: '20px', fontWeight: 500, color: 'var(--bs-danger)' }}>
                {deleteId ? `${deleteId.company_name} / ${deleteId.ip_addr}.${deleteId.pool}` : ''}
              </span>
            </p>
            <p>
              Do you really want to
              delete above RAM?
            </p>
            <p />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default AllIPAddress;
