import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  BREADCRUMB,
  LOAD_PAGE,
  MASTER_DATA,
  EDIT_FORM_DATA,
} from './types';

export const initialState = {
  apiUrl: 'https://dev-api.ibee.ai',
  token: null,
  userId: null,
  page: 'dashboard',
  breadcrumb: null,
  mData: null,
  editFormData: null,
  editPageName: null,
  user_info: null,
  user_name: null,
};

//  const scrl = document.getElementById('main');

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      sessionStorage.setItem(
        'token',
        action.payload.token ? action.payload.token : 'undefined',
      );
      sessionStorage.setItem(
        'userId',
        action.payload.userId ? action.payload.userId : 'undefined',
      );
      sessionStorage.setItem(
        'user_info',
        JSON.stringify(action.payload.user_info ? action.payload.user_info : 'Guest'),
      );
      return {
        ...state,
        token: action.payload.token,
        userId: action.payload.userId,
        user_info: action.payload.user_info,
        user_name: action.payload.user_info
          ? `${action.payload.user_info.first_name} ${action.payload.user_info.last_name}`
          : '',
      };

    case MASTER_DATA:
      return {
        ...state,
        mData: action.payload.mData,
      };

    case LOGOUT_SUCCESS:
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('userId');
      sessionStorage.removeItem('user_name');
      sessionStorage.removeItem('user_info');
      return {
        ...state,
        token: null,
        userId: null,
        mData: null,
        user_info: null,
        user_name: null,
      };

    case LOAD_PAGE:
      /* if (scrl) {
        scrl.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      } */
      return {
        ...state,
        page: action.payload.page,
      };

    case BREADCRUMB:
      return {
        ...state,
        breadcrumb: action.payload.breadcrumb,
      };

    case EDIT_FORM_DATA:
      return {
        ...state,
        editFormData: action.payload.editFormData,
        editPageName: action.payload.editPageName,
      };

    default:
      return state;
  }
};

export default reducer;
