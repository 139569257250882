import React, { useContext, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Input,
  Button,
  Breadcrumb,
  Form,
  Select,
  DatePicker,
  notification,
  Radio,
} from 'antd';

// Custom imports
import AppContext from '../../context/appContext';
import { BREADCRUMB } from '../../context/types';
import LoadPage from '../../common/loadPage';
import { GET, POST } from '../../common/crud';
//  import { dateFormat } from '../../common/common';
const dateFormat = 'DD-MM-YYYY';

const AddDiscount = () => {
  const [startDateValue, setStartDateValue] = useState();
  const [endDateValue, setEndDateValue] = useState();
  const [discountTypeValue, setDiscountTypeValue] = useState('Percentage');
  const [locationList, setLocationList] = useState();
  const [currency, setCurrency] = useState();

  const [state, dispatch] = useContext(AppContext);

  const { Option } = Select;

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('allDiscounts', dispatch)}
        >
          All Discounts
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Add Discount</Breadcrumb.Item>
    </Breadcrumb>
  );

  //  Discount type on change
  const discountTypeOnChange = (e) => {
    console.log('Discount type on change - ', e.target.value);
    setDiscountTypeValue(e.target.value);
  };

  //  Set Starting Date
  const startDateOnChange = (value) => {
    setStartDateValue(value.toISOString(true));
  };

  //  Set End Date
  const endDateOnChange = (value) => {
    setEndDateValue(value.toISOString(true));
  };

  //  Location on Change
  const locationOnChange = (value) => {
    //  const locationBaseCurrency = value.split('-')[1];
    setCurrency(value);
  };

  // Add New Discount Success
  const createNewDiscountSuccess = (values) => {
    console.log('Create discount values - ', values);
    // LoadPage('allDiscounts', dispatch);
    const newDiscountBody = {
      location: values.location.split('-')[0],
      currency: currency.split('-')[2],
      code: values.code,
      type: values.type,
      discount: values.discount,
      no_of_uses: values.no_of_uses,
      applicable_on: values.applicable_on,
      valid_for: values.valid_for,
      start_date: startDateValue,
      end_date: endDateValue,
    };
    console.log('Create Discount Body - ', newDiscountBody);
    POST('discounts', state.token, newDiscountBody).then((res) => {
      if (res.success === true) {
        LoadPage('allDiscounts', dispatch);
        notification.success({
          message: 'Deleted Successfully!',
          description: 'Data is Successfully Deleted!',
        });
      } else {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  //  Get Locations
  const getLocations = async () => {
    GET('masterdata/locations', state.token).then(async (res) => {
      //  setLoading(false);
      console.log('Get Locations res - ', res);
      if (res.success === true) {
        const locationListArray = (
          await res.data.locations.map((locationRes) => (
            <Option key={locationRes._id} value={`${locationRes.name}-${locationRes.code}-${locationRes.base_currency}-${locationRes.base_currency_symbol}`}>{`${locationRes.name}, ${locationRes.code}`}</Option>
          ))
        );
        console.log('Location list after adding - ', locationListArray);
        setLocationList(locationListArray);
      } else if (res.success === false) {
        notification.error({
          message: 'Something Went Wrong!',
          description: 'Sorry we did not got Locations list from master data!!!',
        });
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  };

  useEffect(() => {
    getLocations();

    //  Breadcrumb
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Add Discount
            </Col>
            <Col span="10" flex="row" align="end" />
          </Row>
        </Col>
      </Row>

      <Row gutter={[30, 30]} className="m-2">
        <Col span="24">
          <Form
            name="basic"
            layout="vertical"
            onFinish={createNewDiscountSuccess}
            autoComplete="off"
            initialValues={{
              start_date: '',
              end_date: '',
            }}
          >
            <Row gutter="30">
              <Col span="12">
                <Form.Item
                  label="Location"
                  name="location"
                  rules={[
                    {
                      required: true,
                      message: 'Please select Location',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Location"
                    optionFilterProp="children"
                    onChange={locationOnChange}
                  >
                    {locationList}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Code"
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Coupon Code',
                    },
                  ]}
                >
                  <Input placeholder="Enter Coupon Code" />
                </Form.Item>
              </Col>

              <Col span="12">
                <Row>
                  <Col span="12">
                    <Form.Item
                      label="Discount type"
                      name="type"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Select Discount type',
                        },
                      ]}
                      initialValue={discountTypeValue}
                    >
                      <Radio.Group onChange={discountTypeOnChange}>
                        <Radio value="Percentage">Percentage</Radio>
                        <Radio value="Amount">Amount</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span="12">
                    {discountTypeValue && discountTypeValue === 'Percentage' ? (
                      <Form.Item
                        label="Discount"
                        name="discount"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter Discount Price',
                          },
                        ]}
                      >
                        <Input addonAfter="%" placeholder="Enter Discount Price" />
                      </Form.Item>
                    ) : (
                      <Form.Item
                        label="Discount"
                        name="discount"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter Discount Price',
                          },
                        ]}
                      >
                        <Input addonBefore={currency ? currency.split('-')[3] : ''} placeholder="Enter Discount Price" />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Number of Users"
                  name="no_of_uses"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Number of users > 0',
                    },
                  ]}
                >
                  <Input placeholder="Enter Number of users > 0" />
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Applicable on"
                  name="applicable_on"
                  rules={[
                    {
                      required: true,
                      message: 'Please select Applicable type',
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select Applicable on"
                    optionFilterProp="children"
                  >
                    <Option value="baremetal">Baremetal</Option>
                    <Option value="cloud">Cloud</Option>
                    <Option value="storage">Storage</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Valid for"
                  name="valid_for"
                  rules={[
                    {
                      required: true,
                      message: 'Please select valid for',
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select valid for "
                    optionFilterProp="children"
                  >
                    <Option value="new">New Users</Option>
                    <Option value="old">Old Users</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Start Date"
                  name="start_date"
                  rules={[
                    {
                      required: true,
                      message: 'Please select Code starting date',
                    },
                  ]}
                >
                  <DatePicker onChange={startDateOnChange} className="w-100" format={dateFormat} />
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="End Date"
                  name="end_date"
                  rules={[
                    {
                      required: true,
                      message: 'Please select Code ending date',
                    },
                  ]}
                >
                  <DatePicker onChange={endDateOnChange} className="w-100" format={dateFormat} />
                </Form.Item>
              </Col>
              <Col span="24" className="my-1 py-2 border-top">
                <Button type="default" size="large" className="me-1 px-2">
                  Cancel
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="me-1 btn-success px-4"
                  htmlType="submit"
                >
                  Add Discount
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default AddDiscount;
