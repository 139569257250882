import React, { useContext, useEffect, useState } from 'react';
import {
  Form,
  Select,
  Row,
  Col,
  Input,
  Button,
  notification,
  Switch,
  Radio,
  Alert,
} from 'antd';

import AppContext from '../../../context/appContext';
import Breadcrumbs from '../../../common/breadcrumbs';
import { BREADCRUMB } from '../../../context/types';
import { POST } from '../../../common/crud';
import LoadPage from '../../../common/loadPage';

const AddGPU = () => {
  const [state, dispatch] = useContext(AppContext);
  const [errors, setErrors] = useState();

  const [form] = Form.useForm();

  const { Option } = Select;

  const breadcrumb = <Breadcrumbs title="Add GPU" />;

  const initialValues = {
    make: '',
    model: '',
    size: '',
    technology: '',
    cores: '',
    tdp: '',
    virtualisation_support: 'No',
    tags: [],
    pcie_version: '',
    pcie_lanes: '',
  };

  useEffect(() => {
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, [dispatch]);

  const submitForm = (values) => {
    /* values.pcie_version = Number(values.pcie_version);
    values.cores = Number(values.cores); */
    const GpuBody = {
      ...values,
      pcie_version: Number(values.pcie_version),
      cores: Number(values.cores),
      size: Number(values.size),
    };
    POST('masterdata/gpus', state.token, GpuBody).then((res) => {
      if (res.success) {
        notification.success({
          message: 'Success',
          description: 'GPU added successfully',
        });
        form.resetFields();
        LoadPage('allGpu', dispatch);
      } else {
        console.log('Errors - ', res.errors);
        const errorsList = (
          <ul className="mb-0">
            {
              res.errors.detail.map((resErr) => (
                <li>{resErr.msg}</li>
              ))
            }
          </ul>
        );
        setErrors(errorsList);
        notification.error({
          message: 'Something went wrong',
          description: `Got error with status code ${res.errors}`,
        });
      }
    }).catch((err) => {
      console.error('Add GPU err:', err);
    });
  };

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Add GPU
            </Col>
            <Col span="10" flex="row" align="end" />
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        {
         errors ? (
           <Alert
             description={errors}
             type="error"
             className="mb-2"
           />
         ) : ''
       }
        <Col span="24">
          <Form
            layout="vertical"
            form={form}
            onFinish={submitForm}
            initialValues={initialValues}
          >
            <Row gutter="30" className="flex-direction-column">
              <Col span="12">
                <Form.Item
                  label="Make"
                  name="make"
                  rules={[
                    { required: true, message: 'Enter make' },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select make"
                    optionLabelProp="children"
                  >
                    <Option value="NVIDIA">NVIDIA</Option>
                    <Option value="AMD">AMD</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Model"
                  name="model"
                  rules={[
                    { required: true, message: 'Enter model' },
                  ]}
                >
                  <Input placeholder="Enter model" />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Size"
                  name="size"
                  rules={[
                    { required: true, message: 'Enter size' },
                  ]}
                >
                  <Input placeholder="Enter size" addonAfter="GB" />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Technology"
                  name="technology"
                  rules={[
                    { required: true, message: 'Enter technology' },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select technology"
                    optionLabelProp="children"
                  >
                    <Option value="GDDR4">GDDR4</Option>
                    <Option value="GDDR5">GDDR5</Option>
                    <Option value="GDDR6">GDDR6</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Number of cores"
                  name="cores"
                  rules={[
                    { required: true, message: 'Enter number of cores' },
                  ]}
                >
                  <Input placeholder="Number of cores" />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="TDP"
                  name="tdp"
                  rules={[
                    { required: true, message: 'Enter TDP' },
                  ]}
                >
                  <Input placeholder="Enter TDP" addonAfter="Watts" />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Virtualization Supported?"
                  name="virtualisation_support"
                  rules={[
                    { required: true, message: 'Select value' },
                  ]}
                >
                  <Switch checkedChildren="Yes" unCheckedChildren="No" />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Tags"
                  name="tags"
                >
                  <Select
                    showSearch
                    placeholder="Select tags"
                    optionLabelProp="children"
                    mode="tags"
                  >
                    <Option value="ai/ml">AI/ML</Option>
                    <Option value="transcoding">Transcoding</Option>
                    <Option value="gaming">Gaming</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="PCIe Version"
                  name="pcie_version"
                  rules={[
                    { required: true, message: 'Select value' },
                  ]}
                >
                  <Radio.Group>
                    <Radio value="3">3</Radio>
                    <Radio value="4">4</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="PCIe Lanes"
                  name="pcie_lanes"
                  rules={[
                    { required: true, message: 'Select value' },
                  ]}
                >
                  <Radio.Group>
                    <Radio value="X8">x8</Radio>
                    <Radio value="X16">x16</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span="24" className="my-1 py-2 border-top">
                <Button type="default" size="large" className="me-1 px-2">
                  Cancel
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="me-1 btn-success px-4"
                  htmlType="submit"
                >
                  Add GPU
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default AddGPU;
