import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Input,
  Button,
  Breadcrumb,
  Form,
  Select,
  notification,
  Tag,
} from 'antd';

// Custom imports
import './addProduct.scss';
import AppContext from '../../context/appContext';
import { BREADCRUMB, EDIT_FORM_DATA } from '../../context/types';
import LoadPage from '../../common/loadPage';
import { GET, POST, PUT } from '../../common/crud';
//  import Currencies from '../../assets/json/country-currencies.json';

const AddProduct = () => {
  const [location, setLocation] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [category, setCategory] = useState(null);
  const [baremetalData, setBaremetalData] = useState(null);
  const [ramData, setRamData] = useState(null);
  const [hddData, setHddData] = useState(null);
  const [locationAll, setLocationAll] = useState(null);
  const [currencyArray, setCurrencyArray] = useState([]);
  //  const [baseCurrencySymbol, setBaseCurrencySymbol] = useState();

  const [chasisSKU, setChasisSKU] = useState('');
  const [ramSKU, setRamSKU] = useState(null);
  const [hddSKU, setHddSKU] = useState(null);
  const [locationList, setLocationList] = useState();
  //  const [currencyList, setCurrencyList] = useState();
  const [ramsArray, setRamsArray] = useState();
  const [ramSlotsValues, setRamSlotsValues] = useState();
  const [hddSlotsValues, setHddSlotsValues] = useState();
  const [hddsArray, setHddsArray] = useState();
  const [portspeedList, setPortspeedList] = useState(null);
  const [productsAll, setProductsAll] = useState(null);

  const [state, dispatch] = useContext(AppContext);

  const { TextArea } = Input;

  const { Option } = Select;

  const [BaremetalForm] = Form.useForm();

  const [CloudForm] = Form.useForm();

  const [StorageForm] = Form.useForm();

  const [locationCurrencyForm] = Form.useForm();

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('allProducts', dispatch)}
        >
          All Products
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Add Product</Breadcrumb.Item>
    </Breadcrumb>
  );

  // Location ON change
  const locationOnChange = (value) => {
    console.log('Location on change - ', value);
    const locFilter = locationAll.filter((loc) => loc._id === value.split('-')[3])[0];
    console.log('Filtered locations - ', locFilter.currency);
    setCurrencyArray(locFilter.currency);
    setLocation(value);
    locationCurrencyForm.setFieldsValue({
      currency: locFilter.currency,
    });
  };

  //  Currency on Change
  /* const currencyOnChange = (value) => {
    setCurrency(value);
  }; */

  // Category On Change
  const catOnChange = (value) => {
    setCategory(value);
  };

  // Chasis SKU on change
  const chasisSKUOnChange = (value) => {
    console.log('Chasis value change - ', value);
    setRamSKU(null);
    setRamsArray([]);

    setHddSKU(null);
    setHddsArray([]);

    const selectedBMSkuId = value.split('-')[0];

    console.log('Chasis SKU on change Baremeetal Data - ', baremetalData);

    const bmData = baremetalData.filter((bm) => bm.sku_id === selectedBMSkuId);
    console.log('BM Data - ', bmData);

    bmData.map((res) => {
      setRamSlotsValues(null);
      console.log('BMDataMAP - ', res.ram.max_slots);
      setRamSlotsValues(Number(res.ram.max_slots));
      setHddSlotsValues(Number(res.hdd.max_slots));
      return true;
    });
    setChasisSKU(bmData);
    BaremetalForm.setFieldsValue({
      ram: '',
      rams_inserted: '',
      hdd: '',
      hdds_inserted: '',
    });
  };

  // RAM SKU onChange
  const ramSKUOnChange = (value) => {
    console.log('RAM SKU - ', value);

    console.log(
      'Generated Array = ',
      Array(ramSlotsValues || 0)
        .fill(null)
        .map((val, ind) => ({ _id: ind.toString() })),
    );

    const ramOptionsrray = Array(ramSlotsValues || 0)
      .fill(null)
      .map((val, ind) => ({ _id: ind.toString() }))
      .map((v, i) => (
        <Option value={`${i + 1} x ${value.split('/')[1]}GB`} key={v._id}>
          {`${i + 1} x ${value.split('/')[1]}GB`}
        </Option>
      ));

    //  const ramSKUInfo = ramData.filter((bm) => bm._id === value);
    setRamSKU(value);
    console.log('Ram Options - ', ramOptionsrray);

    setRamsArray(ramOptionsrray);
  };

  // HDD SKu onChange
  const hddSKUOnChange = (value) => {
    console.log('HDD SKU - ', value);

    console.log(
      'Generated Array = ',
      Array(hddSlotsValues || 0)
        .fill(null)
        .map((val, ind) => ({ _id: ind.toString() })),
    );

    const hddOptionsrray = Array(hddSlotsValues || 0)
      .fill(null)
      .map((val, ind) => ({ _id: ind.toString() }))
      .map((v, i) => (
        <Option value={`${i + 1} x ${value.split('/')[4]}GB`} key={v._id}>
          {`${i + 1} x ${value.split('/')[4]}GB`}
        </Option>
      ));

    console.log('Ram Options - ', hddOptionsrray);

    setHddsArray(hddOptionsrray);
    setHddSKU(value);
  };

  //  Create Baremetal Success
  const createBaremetalSuccess = (values) => {
    console.log('Create Baremetal Success values - ', values);

    const selectedRamSku = ramData.filter((ramFil) => ramFil.ram === ramSKU);
    const ramFullInfo = selectedRamSku[Object.keys(selectedRamSku)[0]];

    const selectedHddSku = hddData.filter((hddFil) => hddFil.hdd === hddSKU);

    const hddFullInfo = selectedHddSku[Object.keys(selectedHddSku)[0]];

    console.log('RAM full info - ', selectedRamSku, ramFullInfo);
    console.log('HDD full info - ', selectedHddSku, hddFullInfo);

    const prodBaremetalBody = {
      category,
      location: location.split('-')[0],
      currency: currencyArray,
      description: values.description,
      title: values.title,
      product_id: values.product_id,
      price: Number(values.price),
      port_speed: Number(values.port_speed),
      bandwidth: Number(values.bandwidth),
      ip_included: Number(values.ip_included),
      ram: {
        //  ...ramFullInfo,
        slots: Number(values.rams_inserted.split(' x ')[0]),
        size: Number(values.rams_inserted.split(' x ')[1].split('GB')[0]),
        ram: values.ram,
        master_id: ramFullInfo.master_id,
        inventory_id: ramFullInfo._id,
      },
      hdd: {
        //  ...hddFullInfo,
        slots: Number(values.hdds_inserted.split(' x ')[0]),
        size: Number(values.hdds_inserted.split(' x ')[1].split('GB')[0]),
        hdd: values.hdd,
        master_id: hddFullInfo.master_id,
        inventory_id: hddFullInfo._id,
      },
      chasis: values.chasis,
      chasis_info: {
        ...chasisSKU[Object.keys(chasisSKU)[0]],
        chasis_id: chasisSKU[Object.keys(chasisSKU)[0]]._id,
      },
      status: true,
      similar_products: values.similar_products,
    };
    console.log('Product Baremetal POST Body - ', prodBaremetalBody);

    if (state.editPageName === 'addProduct') {
      PUT(
        'products/baremetal',
        state.editFormData._id,
        state.token,
        prodBaremetalBody,
      ).then((res) => {
        if (res.success === true) {
          notification.success({
            message: 'Successfully Updated!',
            description: 'Successfully data is Updated!',
          });
          LoadPage('allProducts', dispatch);
        } else {
          notification.error({
            message: 'Something went wrong!',
            description: 'Something went wrong, please retry again!',
          });
        }
      });
    } else {
      POST('products/baremetal', state.token, prodBaremetalBody).then((res) => {
        if (res.success === true) {
          LoadPage('allProducts', dispatch);
          notification.success({
            message: 'Successfully Added!',
            description: 'Supplier successfully added!',
          });
        } else {
          notification.error({
            message: 'Something Went Wrong!',
            description: 'Something went wrong! Please try again!',
          });
        }
      });
    }
  };

  //  Create Cloud Success
  const createCloudSuccess = (values) => {
    console.log('Create Cloud Success values - ', values);

    const prodCloudBody = {
      category,
      location: location.split('-')[0],
      currency,
      description: values.description,
      title: values.title,
      product_id: values.product_id,
      price: Number(values.price),
      ram: values.ram,
      diskcapacity: values.diskcapacity,
      noofcores: values.noofcores,
      status: true,
    };

    console.log('Product Cloud POST Body - ', prodCloudBody);
    POST('products/cloud', state.token, prodCloudBody).then((res) => {
      console.log('Product Cloud res - ', res);

      if (res.success === true) {
        LoadPage('allProducts', dispatch);
        notification.success({
          message: 'Successfully Added!',
          description: 'Cloud is Successfully Added!',
        });
      } else {
        console.log('Product Cloud err - ', res.errors);
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  //  Create Storage Success
  const createStorageSuccess = (values) => {
    console.log('Create Storage Success values - ', values);

    const prodStorageBody = {
      category: 'storage',
      location: location.split('-')[0],
      currency,
      title: values.title,
      product_id: values.product_id,
      price: values.price,
      status: true,
    };
    console.log('Product Storage POST Body - ', prodStorageBody);
    POST('products/storage', state.token, prodStorageBody).then((res) => {
      console.log('Processor storage res - ', res);
      StorageForm.resetFields();
      if (res.success === true) {
        LoadPage('allProducts', dispatch);
        notification.success({
          message: 'Successfully Added!',
          description: 'Storage is Successfully Added!',
        });
      } else {
        console.log('Product Storage err - ', res.errors);
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  //  Switch Addon Form
  let AddProductForm;
  switch (category) {
    case 'baremetal':
      AddProductForm = (
        <Form
          form={BaremetalForm}
          name="basic"
          layout="vertical"
          onFinish={createBaremetalSuccess}
          autoComplete="off"
          //  initialValues={baremetalFormInitialValues}
        >
          <Row gutter="30">
            <Col span="12">
              <Form.Item
                label="Product Id"
                name="product_id"
                rules={[
                  {
                    required: true,
                    message: 'Enter Product ID',
                  },
                ]}
              >
                <Input placeholder="Enter Product Id" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Product Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: 'Enter Product Title',
                  },
                ]}
              >
                <Input placeholder="Enter Product Title" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Product Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'Enter Product Desc',
                  },
                ]}
              >
                <TextArea rows="4" placeholder="Enter Product description..." />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Product Price"
                name="price"
                rules={[
                  {
                    required: true,
                    message: 'Enter Product Price',
                  },
                ]}
              >
                <Input
                  placeholder="Enter Product Price"
                  addonBefore={location ? location.split('-')[2] : ''}
                />
              </Form.Item>
            </Col>
            <Col span="24">
              <Row gutter="30" className="mb-1">
                <Col span="8">
                  <Form.Item
                    label="Bandwidth"
                    name="bandwidth"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Bandwidth',
                      },
                    ]}
                    initialValue={state && state.editPageName === 'addProduct' ? state.editFormData.bandwidth : ''}
                  >
                    <Input placeholder="Enter Bandwidth Number" addonAfter="GB" />
                  </Form.Item>
                </Col>
                <Col span="8">
                  <Form.Item
                    label="IP Included"
                    name="ip_included"
                    rules={[
                      {
                        required: true,
                        message: 'Enter number of IP Included',
                      },
                    ]}
                    initialValue={state && state.editPageName === 'addProduct' ? state.editFormData.ip_included : '1'}
                  >
                    <Input placeholder="Enter number of IP Included" />
                  </Form.Item>
                </Col>
                <Col span="8">
                  <Form.Item
                    label="Port Speed"
                    name="port_speed"
                    initialValue={state && state.editPageName === 'addProduct' ? state.editFormData.port_speed : '10'}
                  >
                    <Select
                      showSearch
                      placeholder="Select Chasis SKU"
                      optionFilterProp="children"
                      className="w-100"

                    >
                      {portspeedList
                        ? portspeedList.map((ps) => (
                          <Option key={ps._id} value={`${ps.port_speed}`}>
                            {`${ps.port_speed}`}
                          </Option>
                        ))
                        : ''}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter="30">
                <Col span="8">
                  <Row gutter="0">
                    <Col span="24">
                      <Form.Item
                        label="Chasis Information"
                        name="chasis"
                        rules={[
                          {
                            required: true,
                            message: 'Select Chasis SKU',
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Select Chasis SKU"
                          optionFilterProp="children"
                          onChange={chasisSKUOnChange}
                          className="w-100"
                        >
                          {baremetalData
                            ? baremetalData.map((bm) => (
                              <Option key={bm._id} value={`${bm.sku_id}`}>
                                {`${bm.sku_id}`}
                              </Option>
                            ))
                            : ''}
                        </Select>
                      </Form.Item>
                    </Col>
                    {chasisSKU && chasisSKU.length > 0 ? (
                      <Col span="24">
                        <table className="product-table w-100">
                          <tbody>
                            {chasisSKU.map((bm) => (
                              <>
                                <tr>
                                  <th colSpan="2">
                                    <strong>{bm ? bm.name : ''}</strong>
                                  </th>
                                </tr>
                                <tr>
                                  <th width="150px">Model:</th>
                                  <td>{bm.model_no}</td>
                                </tr>
                                {/* <tr>
                                  <th>Ownership Type:</th>
                                  <td>{bm.batches.ownership_type}</td>
                                </tr> */}
                                <tr>
                                  <th>Quantity:</th>
                                  <td>{bm.qty}</td>
                                </tr>
                                <tr>
                                  <th>Max. RAM Slots:</th>
                                  <td>{bm.ram.max_slots}</td>
                                </tr>
                                <tr>
                                  <th>Max. HDD Slots:</th>
                                  <td>{bm.hdd.max_slots}</td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </Col>
                    ) : null}
                  </Row>
                </Col>
                <Col span="8">
                  <Col span="24">
                    <Form.Item
                      label="RAM"
                      name="ram"
                      rules={[
                        {
                          required: true,
                          message: 'Select RAM',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select RAM"
                        optionFilterProp="children"
                        onChange={ramSKUOnChange}
                        className="w-100"
                      >
                        {ramData
                          ? ramData.map((ram) => (
                            <Option
                              key={ram._id}
                              value={`${ram.ram}`}
                            >
                              {`${ram.ram}`}

                            </Option>
                          ))
                          : ''}
                      </Select>
                    </Form.Item>
                  </Col>
                  {ramSKU ? (
                    <Col span="24">
                      <table className="product-table w-100">
                        <tr>
                          <th colSpan="2">
                            <strong>{ramSKU || ''}</strong>
                          </th>
                        </tr>
                        <tr>
                          <th width="100px"># of rams:</th>
                          <td>
                            <Form.Item
                              label=""
                              name="rams_inserted"
                              rules={[
                                {
                                  required: true,
                                  message: 'Select Number of RAMs inserted',
                                },
                              ]}
                              className="m-0"
                            >
                              <Select
                                placeholder="RAMs Inserted"
                                optionFilterProp="children"
                                style={{ width: '120px' }}
                              >
                                {ramsArray || ''}
                                {/* <Option value="1">1 x 16GB</Option>
                              <Option value="2">2 x 16GB</Option>
                              <Option value="3">3 x 16GB</Option>
                              <Option value="4">4 x 16GB</Option> */}
                              </Select>
                            </Form.Item>
                          </td>
                        </tr>
                      </table>
                    </Col>
                  ) : null}
                </Col>
                <Col span="8">
                  <Col span="24">
                    <Form.Item
                      label="HDD"
                      name="hdd"
                      rules={[
                        {
                          required: true,
                          message: 'Select HDD SKU',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select HDD SKU"
                        optionFilterProp="children"
                        onChange={hddSKUOnChange}
                        filterOption={(input, option) => option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0}
                        className="w-100"
                      >
                        {hddData
                          ? hddData.map((hdd) => (
                            <Option
                              key={hdd._id}
                              value={`${hdd.hdd}`}
                            >
                              {`${hdd.hdd}`}

                            </Option>
                          ))
                          : ''}
                      </Select>
                    </Form.Item>
                  </Col>
                  {hddSKU ? (
                    <Col span="24">
                      <table className="product-table w-100">
                        <tr>
                          <th colSpan="2">
                            <strong>{hddSKU || ''}</strong>
                          </th>
                        </tr>
                        <tr>
                          <th width="100px"># of hdds:</th>
                          <td>
                            <Form.Item
                              label=""
                              name="hdds_inserted"
                              rules={[
                                {
                                  required: true,
                                  message: 'Select Number of HDDs inserted',
                                },
                              ]}
                              className="mb-0"
                            >
                              <Select
                                placeholder="HDDs Inserted"
                                optionFilterProp="children"
                                style={{ width: '120px' }}
                              >
                                {hddsArray || ''}
                              </Select>
                            </Form.Item>
                          </td>
                        </tr>
                      </table>
                    </Col>
                  ) : null}
                </Col>
                <Col span="24" className="mt-2">
                  <Form.Item
                    label="Select Similar Products"
                    name="similar_products"
                  >
                    <Select
                      showSearch
                      mode="multiple"
                      placeholder="Search and select similar products"
                      optionFilterProp="children"
                    >
                      {
                        productsAll ? productsAll.map((res) => (
                          res.category === 'baremetal' ? (
                            <Option key={res._id} value={res._id}>{res.product_id}</Option>
                          ) : ''
                        )) : ''
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col span="24" className="mt-2 pt-2 border-top">
                  {state.editPageName === 'addProduct' ? (
                    <Button
                      type="default"
                      htmlType="button"
                      className="me-2"
                      size="large"
                      onClick={() => LoadPage('allProducts', dispatch)}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      type="default"
                      htmlType="reset"
                      className="me-2"
                      size="large"
                    >
                      Reset
                    </Button>
                  )}
                  <Button
                    type="primary"
                    size="large"
                    className="me-1 btn-success px-4"
                    htmlType="submit"
                  >
                    {state.editPageName === 'addProduct' ? 'Update' : 'Add'}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      );
      break;

    case 'cloud':
      AddProductForm = (
        <Form
          form={CloudForm}
          name="basic"
          layout="vertical"
          onFinish={createCloudSuccess}
          autoComplete="off"
        >
          <Row gutter="30">
            <Col span="12">
              <Form.Item
                label="Product Id"
                name="product_id"
                rules={[
                  {
                    required: true,
                    message: 'Enter Product ID',
                  },
                ]}
              >
                <Input placeholder="Enter Product Id" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Product Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: 'Enter  Product Title',
                  },
                ]}
              >
                <Input placeholder="Enter  Product Title" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Product Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'Enter  Product Desc',
                  },
                ]}
              >
                <TextArea rows="3" placeholder="Enter Product description..." />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Number of Cores"
                name="noofcores"
                rules={[
                  {
                    required: true,
                    message: 'Enter # of Cores',
                  },
                ]}
              >
                <Input placeholder="Enter # of Cores" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="RAM"
                name="ram"
                rules={[
                  {
                    required: true,
                    message: 'Enter RAM',
                  },
                ]}
              >
                <Input placeholder="Enter RAM" addonAfter="GB" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Disk Capacity"
                name="diskcapacity"
                rules={[
                  {
                    required: true,
                    message: 'Enter Disk Capacity',
                  },
                ]}
              >
                <Input placeholder="Enter Disk Capacity" addonAfter="GB" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Product Price"
                name="price"
                rules={[
                  {
                    required: true,
                    message: 'Enter Product Price',
                  },
                ]}
              >
                <Input
                  placeholder="Enter Product Price"
                  addonBefore={location ? location.split('-')[2] : ''}
                />
              </Form.Item>
            </Col>
            <Col span="24" className="mt-2">
              <Button
                type="default"
                htmlType="reset"
                className="me-2"
                size="large"
              >
                Reset
              </Button>
              <Button
                type="primary"
                size="large"
                className="me-1 btn-success px-4"
                htmlType="submit"
              >
                Add
              </Button>
            </Col>
          </Row>
        </Form>
      );
      break;

    case 'storage':
      AddProductForm = (
        <Form
          form={StorageForm}
          name="basic"
          layout="vertical"
          onFinish={createStorageSuccess}
          autoComplete="off"
        >
          <Row gutter="30">
            <Col span="12">
              <Form.Item
                label="Product ID"
                name="product_id"
                rules={[
                  {
                    required: true,
                    message: 'Enter Product ID',
                  },
                ]}
              >
                <Input placeholder="Enter Product ID" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Product Name"
                name="title"
                rules={[
                  {
                    required: true,
                    message: 'Enter Product Name',
                  },
                ]}
              >
                <Input placeholder="Enter Product Name" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Object Storage Price"
                name="price"
                rules={[
                  {
                    required: true,
                    message: 'Enter Storage Price',
                  },
                ]}
              >
                <Input
                  placeholder="Enter Storge Price"
                  addonBefore={location ? location.split('-')[2] : ''}
                  addonAfter="/GB"
                />
              </Form.Item>
            </Col>
            <Col span="24" className="mt-2">
              <Button type="default" htmlType="reset" className="mr-1">
                Reset
              </Button>
              <Button
                type="primary"
                size="large"
                className="me-1 btn-success px-4"
                htmlType="submit"
              >
                Add
              </Button>
            </Col>
          </Row>
        </Form>
      );
      break;

    case 'firewall':
      AddProductForm = 'Firewall Form Coming soon!!!';
      break;

    default:
      break;
  }

  //  Baremetal Data
  const getBMStockList = () => {
    GET('inventory/baremetal', state.token).then((res) => {
      if (res.success === true) {
        console.log('BM Data - ', res.data);
        setBaremetalData(res.data);
      }
    });

    /*  const getBMData = GET('inventory/baremetal', state.token).then((res) => res.data);
    setBaremetalData(getBMData); */
  };

  //  RAMS Data
  const getRamStockList = async () => {
    await GET('inventory/inv_rams', state.token).then((res) => {
      if (res.success === true) {
        console.log('RAMs Data - ', res.data);
        setRamData(res.data);
      }
    });
  };

  const getHDDStockList = async () => {
    //  HDD Data
    await GET('inventory/hdds', state.token).then((res) => {
      if (res.success === true) {
        console.log('HDDs Data - ', res.data);
        setHddData(res.data);
      }
    });
  };

  //  Get Locations
  const getLocations = async () => {
    GET('masterdata/locations', state.token)
      .then(async (res) => {
        //  setLoading(false);
        console.log('Get Locations res - ', res);
        if (res.success === true) {
          setLocationAll(res.data.locations);
          const locationListArray = await res.data.locations.map(
            (locationRes) => (
              <Option
                key={locationRes._id}
                value={`${locationRes.name}-${locationRes.base_currency}-${locationRes.base_currency_symbol}-${locationRes._id}`}
              >
                {`${locationRes.name}, ${locationRes.code}`}

              </Option>
            ),
          );
          /* const currencyListArray = (await res.data.locations.map((locItm) => (
            <Option key={locItm._id} value={`${locItm.base_currency}`}>
              {`${locItm.base_currency_symbol} - ${locItm.base_currency} - ${locItm._id}`}
            </Option>
          )));
          setCurrencyList(currencyListArray); */
          console.log('Location list after adding - ', locationListArray);
          setLocationList(locationListArray);
        } else if (res.success === false) {
          notification.error({
            message: 'Something Went Wrong!',
            description:
              'Sorry we did not got Locations list from master data!!!',
          });
        }
      })
      .catch((err) => {
        //  setLoading(false);
        console.log('Get err - ', err);
      });
  };

  //  Get Port Speed Addons
  const getPortSpeed = () => {
    GET('add-ons/portspeed', state.token).then((res) => {
      if (res.success === true) {
        console.log('Get Port Speed res - ', res.data.addons);
        setPortspeedList(res.data.addons);
      } else {
        notification.error({
          message: 'Something Went Wrong!',
          description:
              'Sorry we did not got Locations list from master data!!!',
        });
      }
    });
  };

  //  Get All Products
  const getAllProducts = () => {
    GET('products', state.token).then((res) => {
      if (res.success === true) {
        console.log('All Products res - ', res.data, productsAll);
        setProductsAll(res.data.products);
      }
    });
  };

  useEffect(() => {
    getLocations();
    getBMStockList();
    getRamStockList();
    getHDDStockList();
    getPortSpeed();
    getAllProducts();

    console.log('Add Product state - ', state);

    //  Breadcrumb
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      BaremetalForm.resetFields();
      setCategory(null);
      setCurrency(null);
      setLocation(null);

      dispatch({
        type: EDIT_FORM_DATA,
        payload: {
          editFormData: null,
          editPageName: null,
        },
      });

      //  Breadcrumb Value empty
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  useEffect(() => {
    if (state.editPageName === 'addProduct' && baremetalData) {
      //  Filter products
      if (productsAll) {
        const filteredProducts = productsAll.filter((item) => item._id !== state.editFormData._id);
        setProductsAll(filteredProducts);
      }

      console.log('Got Edit Form details - ', state.editFormData);
      setLocation(
        `${state.editFormData.location}-${state.editFormData.base_currency}-${state.editFormData.base_currency_symbol}`,
      );
      setCurrency(state.editFormData.currency);
      setCategory(state.editFormData.category);
      setCurrencyArray(state.editFormData.currency);

      //  Chasis SKU Prefilled
      chasisSKUOnChange(`${state.editFormData.chasis}`);

      //  RAM SKU Prefilled
      ramSKUOnChange(state.editFormData.ram.ram);

      //  HDD SKu Prefilled
      hddSKUOnChange(state.editFormData.hdd.hdd);

      //  ${i + 1} x ${value.split('/')[1]}GB`
      const ramsInserted = state.editFormData && state.editFormData.ram
        ? `${state.editFormData.ram.slots} x ${state.editFormData.ram.size}GB`.toString()
        : '';

      const hddsInserted = state.editFormData && state.editFormData.hdd
        ? `${state.editFormData.hdd.slots} x ${state.editFormData.hdd.size}GB`.toString()
        : '';

      BaremetalForm.setFieldsValue({
        ...state.editFormData,
        ram: state.editFormData.ram.ram,
        hdd: state.editFormData.hdd.hdd,
        chasis: `${state.editFormData.chasis}`,
        rams_inserted: ramsInserted,
        hdds_inserted: hddsInserted,
        similar_products: state.editFormData.similar_products,
      });
    }
    return () => {
      /*  setLocation(null);
      setCurrency(null);
      setCategory(null); */
    };
  }, [baremetalData, ramSlotsValues, hddSlotsValues]);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Add Product
            </Col>
            <Col span="10" flex="row" align="end" />
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        {/* <Form form={locationCurrencyForm} className="w-100"> */}
        <Col span="24">
          <Row gutter="30" className="flex-direction-column">
            <Col span="12">
              <Form.Item
                label="Location"
                name="location"
                rules={[
                  {
                    required: true,
                    message: 'Select Location',
                  },
                ]}
              >
                <Select
                  placeholder="Select Location"
                  defaultValue={
                    state.editPageName === 'addProduct'
                      ? state.editFormData.location
                      : ''
                  }
                  onChange={locationOnChange}
                  style={{ width: '100%' }}
                  disabled={state.editPageName === 'addProduct'}
                >
                  {locationList}
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Currency"
                name="currency"
                rules={[
                  {
                    required: true,
                    message: 'Select Currency ',
                  },
                ]}
                initialValue={currencyArray}
              >
                <div className="currency-block">
                  {
                 currencyArray.length > 0 ? (
                   currencyArray.map((item) => (
                     <Tag style={{ fontSize: '14px' }} key={item}>{item}</Tag>
                   ))
                 ) : ''
               }
                </div>
              </Form.Item>
            </Col>

            <Col span="24">
              <Form.Item
                label="Category"
                name="categoryProduct"
                className="w-100"
                rules={[
                  {
                    required: true,
                    message: 'Select Product',
                  },
                ]}
              >
                <Select
                  placeholder="Select Product"
                  optionFilterProp="children"
                  onChange={catOnChange}
                  size="large"
                  disabled={state.editPageName === 'addProduct'}
                  defaultValue={
                    state.editPageName === 'addProduct'
                      ? state.editFormData.category
                      : ''
                  }
                >
                  <Option value="baremetal">BareMetal</Option>
                  <Option value="cloud">Cloud</Option>
                  <Option value="storage">Object Storage</Option>
                  <Option value="firewall">Firewall</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span="24">{AddProductForm}</Col>
          </Row>
        </Col>
        {/* </Form> */}
      </Row>
    </>
  );
};

export default AddProduct;
