import React, { useContext, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Input,
  Button,
  Breadcrumb,
  Radio,
  Form,
  Select,
  notification,
} from 'antd';

// Custom imports
import AppContext from '../../../context/appContext';
import { BREADCRUMB } from '../../../context/types';
import LoadPage from '../../../common/loadPage';
import { POST } from '../../../common/crud';

const AddLANCard = () => {
  const [state, dispatch] = useContext(AppContext);
  const [speedValue, setSpeedValue] = useState('');
  const [makeValue, setMakeValue] = useState('');

  const { Option } = Select;

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Master Data</Breadcrumb.Item>
      <Breadcrumb.Item>Add LAN Card</Breadcrumb.Item>
    </Breadcrumb>
  );

  // Create Location SUCCESS
  const createNewLocationSuccess = (values) => {
    console.log('Create Location values - ', values);
    const newCardBody = {
      make: values.make,
      model: values.model,
      ports: Number(values.ports),
      speed: speedValue,
      connector: values.connector,
      pcie_version: Number(values.pcie_version),
      pcie_lanes: values.pcie_lanes,
    };

    POST('masterdata/lan_cards', state.token, newCardBody).then((res) => {
      if (res.success === true) {
        LoadPage('allLanCards', dispatch);
        notification.success({
          message: 'Successfully Added!',
          description: 'Data is Successfully Added!',
        });
      } else if (res.success === false) {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  //  Card Speed onChang
  const speedOnChange = (value) => {
    setSpeedValue(value);
  };

  //  Card Make onChange
  const makeOnChange = (value) => {
    setMakeValue(value);
  };

  useEffect(() => {
    //  Breadcrumb
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Add LAN Cards
            </Col>
            <Col span="10" flex="row" align="end" />
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24">
          <Form
            name="basic"
            layout="vertical"
            onFinish={createNewLocationSuccess}
            autoComplete="off"
            initialValues={{
              make: makeValue,
              model: '',
              ports: '',
              speed: speedValue,
              connector: '',
              pcie_version: '',
              pcie_lanes: '',
            }}
          >
            <Row gutter="30" className="flex-direction-column">
              <Col span="12">
                <Form.Item
                  label="LAN Card Make"
                  name="make"
                  rules={[
                    {
                      required: true,
                      message: 'Enter LAN Card Make',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select LAN Card Make"
                    onChange={makeOnChange}
                  >
                    <Option value="Mellanxo">Mellanxo</Option>
                    <Option value="Chelsio">Chelsio</Option>
                    <Option value="Intel">Intel</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="LAN Card Model"
                  name="model"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Lan Card Model',
                    },
                  ]}
                >
                  <Input placeholder="Enter LAN Card Model..." />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="LAN Card Ports"
                  name="ports"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Lan Card Ports',
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value="1">1</Radio>
                    <Radio value="2">2</Radio>
                    <Radio value="4">4</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="LAN Card Speed"
                  name="speed"
                  rules={[
                    {
                      required: true,
                      message: 'Enter LAN Card Speed',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Processor Speed"
                    onChange={speedOnChange}
                  >
                    <Option value="1G">1G</Option>
                    <Option value="10G">10G</Option>
                    <Option value="25G">25G</Option>
                    <Option value="40G">40G</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="LAN Card Connector"
                  name="connector"
                  rules={[
                    {
                      required: true,
                      message: 'Enter LAN Card Connector',
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value="RJ-45">RJ-45</Radio>
                    <Radio value="SFP">SFP</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="LAN Card PCIe Version"
                  name="pcie_version"
                  rules={[
                    {
                      required: true,
                      message: 'Select LAN Card PCIe Version',
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value="3">3</Radio>
                    <Radio value="4">4</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="LAN Card PCIe Lanes"
                  name="pcie_lanes"
                  rules={[
                    {
                      required: true,
                      message: 'Select LAN Card PCIe Lanes',
                    },
                  ]}
                >
                  <Radio.Group value="">
                    <Radio value="X8">X8</Radio>
                    <Radio value="X16">X16</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col span="24" className="my-1 py-2 border-top">
                <Button type="default" size="large" className="me-1 px-2">
                  Cancel
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="me-1 btn-success px-4"
                  htmlType="submit"
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default AddLANCard;
