import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Table,
  notification,
  Breadcrumb,
  Button,
  Tag,
} from 'antd';
import moment from 'moment';

//  Custom imports
import AppContext from '../../context/appContext';
import { BREADCRUMB } from '../../context/types';
import { GET } from '../../common/crud';
import LoadPage from '../../common/loadPage';
import { dateFormat } from '../../common/common';

const AllInvoices = () => {
  const [data, setData] = useState();
  const [customers, setCustomers] = useState([]);

  const [state, dispatch] = useContext(AppContext);

  //  Go to Invoice Detail
  const goToInvoiceDetail = (id) => {
    sessionStorage.setItem('invoiceId', JSON.stringify(id));
    LoadPage('invoiceDetail', dispatch);
  };

  //  Go to Customer Detail
  const goToCustomer = (id) => {
    sessionStorage.setItem('customerId', JSON.stringify(id));
    LoadPage('customerDetail', dispatch);
  };

  //  Payment Status
  const paymentStatus = (value) => {
    switch (value) {
      case 'paid':
        return (
          <Tag color="green" style={{ fontSize: '15px' }}>
            {value}
          </Tag>
        );

      case 'pending':
        return (
          <Tag color="red" style={{ fontSize: '15px' }}>
            {value}
          </Tag>
        );

      case 'partial':
        return (
          <Tag color="orange" style={{ fontSize: '15px' }}>
            {value}
          </Tag>
        );

      case 'cancelled':
        return (
          <Tag color="magenta" style={{ fontSize: '15px' }}>
            {value}
          </Tag>
        );

      default:
        return (
          <Tag>
            {value}
          </Tag>
        );
    }
  };

  const invoiceCols = [
    {
      title: 'Inv. Date',
      dataIndex: 'invoice_date',
      key: 'invoice_date',
      width: 130,
      render: (_, record) => (
        <>
          {moment(record.invoice_date).format(dateFormat)}
        </>
      ),
    },
    {
      title: '# Inv.',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      width: 120,
      render: (_, record) => (
        <>
          <Button type="link" className="p-0" onClick={() => goToInvoiceDetail(record._id)}>
            {record._id.substr(record._id.length - 5)}
          </Button>
        </>
      ),
    },
    {
      title: 'Bill. Period',
      dataIndex: 'billingPeriod',
      key: 'billingPeriod',
      width: 144,
      render: (_, record) => (
        <>
          {
           `${moment(record.invoice_date).add(-30, 'd').format(dateFormat)} / ${moment(record.invoice_date).format(dateFormat)}`
          }
        </>
      ),
    },

    {
      title: 'Company',
      dataIndex: 'companyName',
      key: 'companyName',
      width: 200,
      render: (_, record) => (
        <>
          {
            customers ? (
              <Button type="link" className="p-0" onClick={() => goToCustomer(record.customer_id)}>
                {
                  customers.map((res) => (
                    res._id === record.customer_id ? res.display_name : ''
                  ))
                }
              </Button>
            )
              : ''
          }
        </>
      ),
    },
    {
      title: 'Pay Status',
      dataIndex: 'payment_status',
      key: 'payment_status',
      width: 150,
      render: (_, record) => (
        <>
          {paymentStatus(record.payment_status)}
        </>
      ),
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      width: 130,
      render: (_, record) => (
        <>
          {`${moment(record.invoice_date).add(30, 'd').format(dateFormat)}`}
        </>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: 120,
      className: 'text-end',
      render: (_, record) => (
        <>
          {(record.amount / 100).toFixed(2)}
        </>
      ),
    },
    {
      title: 'Balance Due',
      dataIndex: 'amount_due',
      key: 'amount_due',
      width: 120,
      className: 'text-end',
      render: (_, record) => (
        <>
          {(record.amount_due / 100).toFixed(2)}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
    },
  ];

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>

      <Breadcrumb.Item>All Location</Breadcrumb.Item>
    </Breadcrumb>
  );

  //  Get All Invoices
  const getAllInvoices = () => {
    GET('invoices', state.token).then((res) => {
      console.log('All Invoices res - ', res);

      if (res.success === true) {
        setData(res.data);
        console.log('Csutomers - ', customers);
      } else {
        notification.error({
          message: 'Something went wrong!!!',
          description: 'Something went wrong, did not got data!!',
        });
      }
    });
  };

  //  Customer details
  const getCustomers = () => {
    GET('customers', state.token).then((res) => {
      if (res.success === true) {
        setCustomers(res.data);
      } else {
        notification.error({
          message: 'Something went wrong!',
          description: 'Something went wrong, did not get data!',
        });
      }
    });
    setCustomers();
  };

  useEffect(() => {
    getCustomers();

    // Bredcrumb
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    getAllInvoices();

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Manage Orders
            </Col>
            {/* <Col span="10" flex="row" align="end" className="text-end">
              <Button
                type="primary"
                onClick={() => LoadPage('addLocation', dispatch)}
              >
                <IoAdd />
                {' '}
                Add Location
              </Button>
            </Col> */}
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24">
          <Table columns={invoiceCols} dataSource={data} />
        </Col>
      </Row>
    </>
  );
};

export default AllInvoices;
