import React, { useReducer, useEffect, useState } from 'react';
import {
  Layout, Row, Col, Form, Input, Button, Alert, message,
} from 'antd';
import 'antd/dist/antd.css';

// Custom imports
import logo from './assets/images/ibee-hosting-logo.png';
import AppContext from './context/appContext';
import reducer, { initialState } from './context/reducer';
import AdminModule from './AdminModule';
import './App.scss';
import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from './context/types';
import ResetPassword from './components/Users/resetPassword';
//  import MasterData from './common/masterData';

function App() {
  const [signedIn, setSignedIn] = useState(false);
  const [formToggle, setFormToggle] = useState('showLogin');
  const [loading, setLoading] = useState(false);
  const [loginErr, setLoginErr] = useState(null);

  const [form] = Form.useForm();

  const [state, dispatch] = useReducer(reducer, initialState);

  //  On Login Form Failed
  const LoginFormFailed = () => {};

  //  On Login Form Success
  const LoginFormSuccess = (values) => {
    console.log('Login values', values);
    setLoginErr(null);
    setLoading(true);

    const bodyData = {
      email: values.loginEmail,
      password: values.loginPassword,
      client_id: 'admin_portal',
    };

    let formBody = [];
    Object.keys(bodyData).map((key) => {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(bodyData[key]);
      return formBody.push(`${encodedKey}=${encodedValue}`);
    });
    formBody = formBody.join('&');

    console.log('Form Body - ', formBody);

    fetch(`${state.apiUrl}/oauth2/token`, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: formBody,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log('Login res - ', data);
        setLoading(false);
        if (data.detail) {
          setLoginErr(data.detail);
        } else {
          fetch(`${state.apiUrl}/users/${data.id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'appliction/json',
            },
          })
            .then((res) => res.json())
            .then((resData) => {
              console.log();
              console.log('user details - ', resData.admin_user, resData.admin_user.first_name, resData.admin_user.last_name);
              sessionStorage.setItem('user_info', `${resData.admin_user}`);
              dispatch({
                type: LOGIN_SUCCESS,
                payload: {
                  token: data.access_token,
                  userId: data.id,
                  user_info: resData.admin_user,
                },
              });
              //  MasterData(data.access_token, dispatch);
              setSignedIn(true);
              console.log('State - ', state);
            });
        }
      })
      .catch((err) => {
        console.log('Login err - ', err);
      });
  };

  //  Forgot Password
  const forgotPassword = () => {
    setFormToggle('showForgot');
  };

  //  Forgot Password Success
  const ForgotPasswordFormSuccess = (values) => {
    console.log('forgot form values - ', values);

    fetch(`${state.apiUrl}/users/forgot-password?email=${values.forgotEmail}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: '',
    }).then((res) => {
      if (res.status === 200) {
        message.success('Reset password Email Sent successfully!');
        setFormToggle('showLogin');
      } else if (res.status === 404) {
        message.error('Invalid credentials!!!');
      }
    });
  };

  //  Login Forgot Password Forms Block
  let LoginForgotForm;

  switch (formToggle) {
    case 'showLogin':
      LoginForgotForm = (
        <>
          {/* Login Starts */}
          <div className="full-width login-block px-3 ">
            <div className="full-width text-start branding-logo pb-2 login-signup-header mb-2">
              <Row>
                <Col span="12">
                  <img
                    src={logo}
                    className="logo d-inline-block"
                    alt="IBEE Hosting"
                  />
                </Col>
                <Col span="12" className="">
                  <div className="login-signup-main-title full-width text-end">
                    Login Now
                  </div>
                  <div className="login-signup-short-info full-width text-end">
                    Only for private use.
                  </div>
                </Col>
              </Row>
            </div>

            <div className="full-width login-form-block">
              {loginErr ? (
                <div className="full-width mb-2">
                  <Alert message={loginErr} type="error" />
                </div>
              ) : null}
              <div className="full-width">
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={LoginFormSuccess}
                  onFinishFailed={LoginFormFailed}
                >
                  <Form.Item
                    label="Email Address"
                    name="loginEmail"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your Email Id!',
                      },
                    ]}
                  >
                    <Input className="form-control fs-5" size="large" />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    name="loginPassword"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your Password!',
                      },
                    ]}
                  >
                    <Input.Password
                      className="form-control fs-5"
                      size="large"
                      name="password"
                    />
                  </Form.Item>
                  <div className="text-end">
                    <Button
                      type="link"
                      htmlType="button"
                      style={{
                        alignSelf: 'flex-end',
                        display: 'inline-flex',
                        justifySelf: 'flex-end',
                        position: 'relative',
                        zIndex: 4,
                      }}
                      className="px-0"
                      onClick={() => forgotPassword()}
                    >
                      Forgot password?
                    </Button>
                  </div>
                  <Form.Item className="flex" style={{ marginTop: '-30px' }}>
                    <div className="d-flex align-items-center justify-content-start">
                      <Button
                        htmlType="submit"
                        disabled={loading}
                        className="loginBtn align-self-center btn-primary"
                        type="primary"
                      >
                        Login
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
          {/* Login Starts */}
        </>
      );
      break;

    case 'showForgot':
      LoginForgotForm = (
        <>
          {/* Forgot Password Starts */}
          <div className="full-width login-block px-3 ">
            <div className="full-width text-start branding-logo pb-2 login-signup-header mb-2">
              <Row>
                <Col span="12">
                  <img
                    src={logo}
                    className="logo d-inline-block"
                    alt="IBEE Hosting"
                  />
                </Col>
                <Col span="12" className="">
                  <div className="login-signup-main-title full-width text-end">
                    Forgot Password
                  </div>
                  <div className="login-signup-short-info full-width text-end">
                    To reset your password, please enter your user email.
                  </div>
                </Col>
              </Row>
            </div>
            <div className="full-width signup-block">
              <div className="full-width login-form-block">
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={ForgotPasswordFormSuccess}
                >
                  <Form.Item
                    label="Email Address"
                    name="forgotEmail"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Registered Email ID',
                      },
                    ]}
                  >
                    <Input className="form-control" type="email" />
                  </Form.Item>

                  <Form.Item className="flex">
                    <div className="d-flex align-items-center justify-content-start">
                      <Button
                        htmlType="submit"
                        className="loginBtn align-self-center btn-primary"
                        type="primary"
                      >
                        Send
                      </Button>
                      <span className="signup-block">
                        or
                        {' '}
                        <Button
                          type="link"
                          className="joinNowLink"
                          onClick={() => setFormToggle('showLogin')}
                        >
                          Back to Login
                          {' '}
                        </Button>
                      </span>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>

          {/* Forgot Password Ends */}
        </>
      );
      break;

    case 'resetPassword':
      LoginForgotForm = <ResetPassword />;
      break;

    default:
      break;
  }

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    const userId = sessionStorage.getItem('userId');
    const userInfo = sessionStorage.getItem('user_info');

    const urlParam = window.location.pathname;
    console.log('url path = ', urlParam.split('/'));
    if (
      urlParam
      && (urlParam.split('/')[2] === 'invite'
        || urlParam.split('/')[2] === 'forgot')
    ) {
      console.log('Show forgot passwrod fomr');
      sessionStorage.setItem('passTok', urlParam.split('/')[3]);
      dispatch({
        type: LOGOUT_SUCCESS,
      });
      setFormToggle('resetPassword');
    }

    console.log('user_info - ', userInfo);

    console.log('State - ', state);
    if (token && userId && userInfo && token !== undefined && userId !== undefined) {
      // setToken(token)
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          token,
          userId,
          user_info: JSON.parse(userInfo),
        },
      });
      setSignedIn(true);
    }
  }, []);

  if (signedIn) {
    return (
      <>
        <AppContext.Provider value={[state, dispatch]}>
          <AdminModule />
        </AppContext.Provider>
      </>
    );
  }
  return (
    <>
      <Layout
        align="center"
        style={{ minHeight: '100vh' }}
        className="d-flex align-items-center justify-content-center full-width"
      >
        <Row className="w-100">
          <div
            className="w-100"
            style={{ maxWidth: '650px', margin: '0 auto' }}
          >
            <Col
              span="24"
              className="bg-white shadow rounded-3"
              style={{ overflow: 'hidden' }}
            >
              <Row align-items="stretch" className="my-2">
                <Col>{LoginForgotForm}</Col>
              </Row>
            </Col>
          </div>
        </Row>
      </Layout>
    </>
  );
}

export default App;
