import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Breadcrumb,
  Table,
  notification,
  Modal,
  Form,
  Select,
  Input,
  Switch,
  Radio,
} from 'antd';

import { IoAdd, IoPencil, IoTrash } from 'react-icons/io5';

import AppContext from '../../../context/appContext';
import { BREADCRUMB } from '../../../context/types';
import LoadPage from '../../../common/loadPage';
import { DELETE, GET, PUT } from '../../../common/crud';

const AllGPUs = () => {
  const [state, dispatch] = useContext(AppContext);
  const [data, setData] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateData, setUpdateData] = useState([]);
  const [deleteData, setDeleteData] = useState([]);
  const [virtualSupport, setVirtualSupport] = useState(false);

  const [UpdateForm] = Form.useForm();

  const { Option } = Select;

  const breadcrumb = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>All GPUs</Breadcrumb.Item>
    </Breadcrumb>
  );

  //  Update HDD
  const updateRowData = (record) => {
    console.log('Update record - ', record);
    setUpdateModal(true);
    setUpdateData(record);
    UpdateForm.setFieldsValue({
      ...record,
      pcie_version: record.pcie_version.toString(),
    });
    setVirtualSupport(record.virtualisation_support);
  };

  //  Delete HDD
  const deleteRowData = (record) => {
    console.log('Delete record - ', record);
    setDeleteModal(true);
    setDeleteData(record);
  };

  const tableColumns = [
    {
      title: 'Make',
      dataIndex: 'make',
      key: 'make',
      width: 300,
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
      width: 300,
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      width: 300,
    },
    {
      title: 'Cores',
      dataIndex: 'cores',
      key: 'cores',
      width: 300,
    },
    {
      title: 'Virtualisation Supported',
      dataIndex: 'virtualisation_support',
      key: 'virtualisation_support',
      render: (val) => (val ? 'Yes' : 'No'),
      width: 300,
    },
    {
      title: 'PCIe Version & Lanes',
      render: (_, record) => `v${record.pcie_version}, ${record.pcie_lanes}`,
      width: 300,
    },
    {
      title: 'Actions',
      width: 300,
      render: (_, record) => (
        <>
          <Button
            type="default"
            className="me-1 btn-outline-secondary"
            onClick={() => updateRowData(record)}
            icon={<IoPencil />}
          />
          <Button
            type="default"
            className="me-1 btn-outline-danger"
            onClick={() => deleteRowData(record)}
            icon={<IoTrash />}
          />
        </>
      ),
    },
  ];

  const fetchData = () => {
    GET('masterdata/gpus', state.token).then((res) => {
      console.log('Gpus res - ', res.data.gpus);
      if (res.success) {
        setData(res.data.gpus);
      } else {
        notification.error({
          message: 'Something went wrong!',
          description: `Got Error with status code ${res.status}`,
        });
      }
    });
  };

  //  Update Submit
  const submitForm = (values) => {
    console.log('Submit Form values - ', values);
    setUpdateModal(false);
    const submitValues = {
      ...values,
      pcie_version: Number(values.pcie_version),
    };
    console.log('PUT Body - ', submitValues);
    PUT('masterdata/gpus', updateData._id, state.token, submitValues).then((res) => {
      if (res.success === true) {
        UpdateForm.resetFields();
        setUpdateData(null);
        notification.success({
          message: 'Successfully Updated!',
          description: 'Data is Successfully Updated!',
        });
        fetchData();
      } else {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  //  Delete Cancel Modal
  const deleteModalCancel = () => {
    setDeleteData(null);
    setDeleteModal(false);
  };

  //  Virutal Support On Change
  const virtualSupportOnChange = (value) => {
    setVirtualSupport(value);
  };

  //  Update Cancel Modal
  const updateCancelModal = () => {
    UpdateForm.resetFields();
    setUpdateData(null);
    setUpdateModal(false);
  };

  //  Delete on Succes
  const deleteSuccess = () => {
    setDeleteModal(false);
    setUpdateModal(false);
    UpdateForm.resetFields();
    DELETE('masterdata/gpus', deleteData._id, state.token).then((res) => {
      if (res.success === true) {
        setUpdateData(null);
        setDeleteData(null);
        notification.success({
          message: 'Deleted Successfully!',
          description: 'Data is Successfully Deleted!',
        });
        fetchData();
      } else {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    });
  };

  useEffect(() => {
    fetchData();
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, [dispatch]);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              All GPUs
            </Col>
            <Col span="10" flex="row" align="end">
              <Button
                type="primary"
                size="large"
                onClick={() => LoadPage('addGpu', dispatch)}
              >
                <IoAdd />
                {' '}
                Add GPU
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter="20" className="m-2">
        <Col span="24">
          <Table
            pagination={false}
            columns={tableColumns}
            dataSource={data}
          />
        </Col>
      </Row>
      <Modal
        width={1100}
        footer={false}
        title="Update GPU"
        visible={updateModal}
        onCancel={updateCancelModal}
      >
        <Form
          layout="vertical"
          form={UpdateForm}
          onFinish={submitForm}
        >
          <Row gutter="30" className="flex-direction-column">
            <Col span="12">
              <Form.Item
                label="Make"
                name="make"
                rules={[
                  { required: true, message: 'Enter make' },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select make"
                  optionLabelProp="children"
                >
                  <Option value="NVIDIA">NVIDIA</Option>
                  <Option value="AMD">AMD</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Model"
                name="model"
                rules={[
                  { required: true, message: 'Enter model' },
                ]}
              >
                <Input placeholder="Enter model" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Size"
                name="size"
                rules={[
                  { required: true, message: 'Enter size' },
                ]}
              >
                <Input placeholder="Enter size" addonAfter="GB" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Technology"
                name="technology"
                rules={[
                  { required: true, message: 'Enter technology' },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select technology"
                  optionLabelProp="children"
                >
                  <Option value="GDDR4">GDDR4</Option>
                  <Option value="GDDR5">GDDR5</Option>
                  <Option value="GDDR6">GDDR6</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Number of cores"
                name="cores"
                rules={[
                  { required: true, message: 'Enter number of cores' },
                ]}
              >
                <Input placeholder="Number of cores" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="TDP"
                name="tdp"
                rules={[
                  { required: true, message: 'Enter TDP' },
                ]}
              >
                <Input placeholder="Enter TDP" addonAfter="Watts" />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Virtualization Supported?"
                name="virtualisation_support"
                rules={[
                  { required: true, message: 'Select value' },
                ]}
              >
                <Switch checked={virtualSupport} checkedChildren="Yes" unCheckedChildren="No" onChange={virtualSupportOnChange} />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Tags"
                name="tags"
              >
                <Select
                  showSearch
                  placeholder="Select tags"
                  optionLabelProp="children"
                  mode="tags"
                >
                  <Option value="ai/ml">AI/ML</Option>
                  <Option value="transcoding">Transcoding</Option>
                  <Option value="gaming">Gaming</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="PCIe Version"
                name="pcie_version"
                rules={[
                  { required: true, message: 'Select value' },
                ]}
              >
                <Radio.Group>
                  <Radio value="3">3</Radio>
                  <Radio value="4">4</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="PCIe Lanes"
                name="pcie_lanes"
                rules={[
                  { required: true, message: 'Select value' },
                ]}
              >
                <Radio.Group>
                  <Radio value="X8">x8</Radio>
                  <Radio value="X16">x16</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span="24" className="my-1 py-2 border-top">
              <Button type="default" size="large" className="me-1 px-2" onClick={() => setUpdateModal(false)}>
                Cancel
              </Button>
              <Button
                type="primary"
                size="large"
                className="me-1 btn-success px-4"
                htmlType="submit"
              >
                Update GPU
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Confirm if you really want to delete?"
        visible={deleteModal}
        onCancel={deleteModalCancel}
        okText="Delete"
        okButtonProps={{ className: 'btn-danger' }}
        onOk={deleteSuccess}
      >
        <Row>
          <Col span="24">
            <p>
              <span style={{ fontSize: '20px', fontWeight: 500, color: 'var(--bs-danger)' }}>
                {deleteData ? `${deleteData.make} / ${deleteData.model}` : ''}
              </span>
            </p>
            <p>
              Do you really want to
              delete above RAM?
            </p>
            <p />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default AllGPUs;
