import React, {
  useRef, useContext, useEffect,
} from 'react';
import {
  Layout, Row, Col, BackTop, Result,
} from 'antd';
import { IoArrowUp } from 'react-icons/io5';

//  Custom Imports
import AppContext from './context/appContext';
import TopHeader from './components/Header';
import SideNavigation from './components/SideNavigation';
import Dashboard from './components/Dashboard';
import ListLocations from './components/MasterData/Locations/listLocations';
import AddLocation from './components/MasterData/Locations/addLocation';
import AddCurrency from './components/MasterData/Currencies/addCurrency';
import AllCurrencies from './components/MasterData/Currencies/listCurrencies';
import AddRAM from './components/MasterData/RAM/addRAM';
import AllRAMs from './components/MasterData/RAM/listRams';
import AddProcessor from './components/MasterData/Processors/addProcessor';
import AllProcessors from './components/MasterData/Processors/allProcessors';
import AllLanCards from './components/MasterData/LANCards/listLanCards';
import AddLANCard from './components/MasterData/LANCards/addLanCard';
import AddUser from './components/Users/addUser';
import AllUsers from './components/Users/listUsers';
import AddProduct from './components/Products/addProduct';
import AllAddons from './components/Addons/listAddons';
import AddAddons from './components/Addons/addAddons';
import AddStock from './components/Inventory/addStock';
import AddSupplier from './components/Suppliers/addSupplier';
import AllSuppliers from './components/Suppliers/listSuppliers';
import AllInventory from './components/Inventory/listInventory';
import AddIPAddress from './components/MasterData/IPAddress/addIPAddress';
import AllIPAddress from './components/MasterData/IPAddress/listIPAddresses';
import AddDiscount from './components/Discounts/addDiscount';
import AllDiscounts from './components/Discounts/listDiscounts';
import AddDisk from './components/MasterData/Disks/addDisk';
import AllDisks from './components/MasterData/Disks/allDisks';
import AddRAIDCard from './components/MasterData/RAID/addRaidCard';
import AllRAIDCards from './components/MasterData/RAID/listRaidCards';
import AllGPUs from './components/MasterData/GPU/listGPU';
import AddGPU from './components/MasterData/GPU/addGPU';
import AllProducts from './components/Products/listProducts';
import CurrencyExchangeRates from './components/CurrencyExchangeRates/listCurrencyExchange';
import ListCustomers from './components/Customers/listCustomers';
import CustomerDetail from './components/Customers/customerDetail';
import RecurringOrders from './components/ManageOrders/recurringOrders';
import AllInvoices from './components/Billing/listInvoices';
import OrderDetail from './components/ManageOrders/orderDetail';
import AddCustomer from './components/Customers/addCustomer';

const AdminModule = () => {
  const scrollRef = useRef();
  const [state] = useContext(AppContext);

  const page = {
    dashboard: <Dashboard />,
    allLocations: <ListLocations />,
    addLocation: <AddLocation />,
    allCurrencies: <AllCurrencies />,
    addCurrency: <AddCurrency />,
    allRams: <AllRAMs />,
    addRam: <AddRAM />,
    allProcessors: <AllProcessors />,
    addProcessor: <AddProcessor />,
    allLanCards: <AllLanCards />,
    addLanCard: <AddLANCard />,
    allUsers: <AllUsers />,
    addUser: <AddUser />,
    allProducts: <AllProducts />,
    addProduct: <AddProduct />,
    allAddons: <AllAddons />,
    addAddons: <AddAddons />,
    allInventory: <AllInventory />,
    addStock: <AddStock />,
    allSuppliers: <AllSuppliers />,
    addSupplier: <AddSupplier />,
    allIpAddresses: <AllIPAddress />,
    addIpAddress: <AddIPAddress />,
    allDiscounts: <AllDiscounts />,
    addDiscount: <AddDiscount />,
    allDisks: <AllDisks />,
    addDisk: <AddDisk />,
    allRaidCards: <AllRAIDCards />,
    addRaidCard: <AddRAIDCard />,
    allGpu: <AllGPUs />,
    addGpu: <AddGPU />,
    currencyExchangeRates: <CurrencyExchangeRates />,
    addCustomer: <AddCustomer />,
    allCustomers: <ListCustomers />,
    customerDetail: <CustomerDetail />,
    recurringOrders: <RecurringOrders />,
    allInvoices: <AllInvoices />,
    orderDetail: <OrderDetail />,
  };

  useEffect(() => {
    window.scrollTo(0, 0, 'smooth');
  }, []);

  return (
    <Layout className="full-width main">
      <TopHeader />

      <Layout className="full-width">
        <Row
          align="stretch"
          className="full-width"
          style={{ overflowY: 'auto' }}
          id="main"
          ref={scrollRef}
        >
          <Col span="4" style={{ backgroundColor: '#fff' }}>
            <SideNavigation />
          </Col>
          <Col span="20" className="p-2">
            <Row>
              <Col span="24" className="bg-white">
                {page[state.page] || (
                <Result
                  status="warning"
                  title="You don't have permissions to view page"
                  extra=""
                />
                )}
              </Col>
            </Row>

            <BackTop>
              <div style={{
                height: 40,
                width: 40,
                lineHeight: '40px',
                borderRadius: 4,
                backgroundColor: '#1088e9',
                color: '#fff',
                textAlign: 'center',
                fontSize: 14,
              }}
              >
                <IoArrowUp />
              </div>
            </BackTop>
          </Col>
        </Row>
      </Layout>
      {/* <Footer /> */}
    </Layout>
  );
};

export default AdminModule;
